import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  mounted: function mounted() {
    console.log('AAAA', this.params);
  }
});