import { defineComponent } from 'vue';
import jsoneditor from 'jsoneditor';
export default defineComponent({
  props: ['value', 'title', 'readonly'],
  data: function data() {
    return {
      jsonEditor: null
    };
  },
  watch: {
    value: function value() {
      this.jsonEditor.set(this.value);
    }
  },
  mounted: function mounted() {
    var jsonEditorHost = this.$refs.jsonEditorHost;
    this.jsonEditor = new jsoneditor(jsonEditorHost, {
      mode: this.readonly ? 'view' : 'form'
    }, this.value);
  }
});