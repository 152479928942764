import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.index-of.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c(VAutocomplete, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "300px"
    },
    style: {
      "z-index": 30
    },
    attrs: {
      "items": _vm.cids,
      "dense": ""
    },
    model: {
      value: _vm.activeCid,
      callback: function callback($$v) {
        _vm.activeCid = $$v;
      },
      expression: "activeCid"
    }
  }), _vm.activeCid && !_vm.r4cForCurrentClientIdConnected ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Kein r4c verbunden")]) : _vm._e()], 1), _c('div', {
    staticClass: "mainWrap"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h3', [_vm._v("Tables")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.tableFilter,
      expression: "tableFilter"
    }],
    attrs: {
      "placeholder": "Filter"
    },
    domProps: {
      "value": _vm.tableFilter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.tableFilter = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "tableList"
  }, _vm._l(_vm.tablesFiltered, function (table) {
    return _c('div', {
      key: table,
      staticClass: "tableItem",
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.showTable(table);
        }
      }
    }, [_vm._v(_vm._s(table))]);
  }), 0)]), _c('div', {
    staticClass: "d-flex flex-column flex-grow-1 px-2"
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c('h3', {
    staticClass: "mr-2"
  }, [_vm._v("SQL:")]), _c(VTextField, {
    staticClass: "mr-2",
    attrs: {
      "placeholder": "SELECT * FROM ...",
      "dense": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loadSqlInput.apply(null, arguments);
      }
    },
    model: {
      value: _vm.sql,
      callback: function callback($$v) {
        _vm.sql = $$v;
      },
      expression: "sql"
    }
  }), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.loadSqlInput
    }
  }, [_vm._v("Run")])], 1), _c('div', {
    staticClass: "sqlResultTableWrap"
  }, [_vm.loading ? _c('span', [_vm._v("Lädt..")]) : _vm._e(), _vm.errorText ? _c('pre', [_vm._v(_vm._s(_vm.errorText))]) : _vm._e(), _vm.sqlResult ? _c('div', {
    staticClass: "d-flex"
  }, [_c('span', [_vm._v(_vm._s(_vm.sqlResult.length) + " rows")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rowFilter,
      expression: "rowFilter"
    }],
    staticClass: "ml-4",
    attrs: {
      "placeholder": "Filter"
    },
    domProps: {
      "value": _vm.rowFilter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.rowFilter = $event.target.value;
      }
    }
  }), _vm.rowFilter ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.filteredRows.length) + " filtered rows")]) : _vm._e()]) : _vm._e(), _c('table', {
    staticClass: "sqlResultTable"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableColumns, function (col, i) {
    return _c('th', {
      key: i
    }, [_vm._v(_vm._s(col))]);
  }), 0)]), _c('tbody', _vm._l(_vm.filteredRows, function (row, i) {
    return _c('tr', {
      key: i
    }, _vm._l(_vm.tableColumns, function (col, j) {
      return _c('td', {
        key: j
      }, [row[col] === null ? _c('div', {
        staticClass: "null-value"
      }, [_vm._v("NULL")]) : _c('div', {
        staticClass: "not-null-value"
      }, [_vm._v(_vm._s(row[col]))])]);
    }), 0);
  }), 0)])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };