import { RoseApi } from './apiTypes';
import { createBotApi, createConnectorCheckApi } from './collector/botApi';
import { createCollectorAdminApi } from './collector/collectorAdminApi';
import { createImportApi } from './collector/importApi';
import { createCollectorTriggerApi } from './collector/triggerApi';

export function createCollectorApi(roseApi: RoseApi) {
  return {
    bot: createBotApi(roseApi),
    newClient: createConnectorCheckApi(roseApi),
    trigger: createCollectorTriggerApi(roseApi),
    import: createImportApi(roseApi),
    admin: createCollectorAdminApi(roseApi),
  };
}
