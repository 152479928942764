import { defineComponent } from 'vue';
import jsoneditor from 'jsoneditor';
export default defineComponent({
  props: ['value', 'title', 'readonly'],
  data: function data() {
    return {
      jsonEditor: null,
      editJsonDialog: false
    };
  },
  updated: function updated() {
    if (this.editJsonDialog) {
      var jsonEditorHost = this.$refs.jsonEditorHost;
      this.jsonEditor = new jsoneditor(jsonEditorHost, {
        mode: this.readonly ? 'view' : 'form'
      }, this.value);
    }
  },
  methods: {
    closeJsonDialog: function closeJsonDialog(saveSettings) {
      console.log('close dialog', saveSettings);
      if (saveSettings) {
        this.$emit('saveJsonChange', this.jsonEditor.get());
      }
      this.editJsonDialog = false;
      this.jsonEditor.destroy();
    },
    openJsonDialog: function openJsonDialog() {
      this.editJsonDialog = true;
    }
  }
});