import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "import-info"
  }, [_vm.incError ? _c(VChip, {
    staticClass: "pl-2 pr-2 mr-1",
    attrs: {
      "id": "i",
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "warning",
      "title": "Update-Import-Problem"
    },
    on: {
      "click": _vm.goToImport
    }
  }, [_vm._v("i")]) : _vm._e(), _vm.fullError ? _c(VChip, {
    staticClass: "pl-2 pr-2 mr-1",
    attrs: {
      "id": "f",
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "error",
      "title": "Komplett-Import-Problem"
    },
    on: {
      "click": _vm.goToImport
    }
  }, [_vm._v("f")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };