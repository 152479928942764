import { ValueFormatterParams, IRowNode } from 'ag-grid-community';
import { chain, get, join, size, some } from 'lodash';
import {
  ITerminDetails,
  TeamTypes,
  TerminStatusType,
  Units,
  IBehandlerService,
  ITerminInfoContainer,
  GeschlechtType,
  ITeam,
  BehandlerType,
  mfaTypeToString,
} from '../../types';
import { dauerFilter } from './filters';
import { numeral } from './numeral';
import { roseDayjs, createUTC } from '../../base';
import { IRoseAGGridColumn } from './types/agGrid';

export function getValueFromGrid<T = any>(row: T, columnDef: IRoseAGGridColumn<T>) {
  // use field if unit was given
  let val = columnDef.field && get(row, columnDef.field);
  const vf = columnDef.valueFormatter as (x: any) => any;
  // use formatter otherwise
  if (!getUnitFromGrid(columnDef) || !!columnDef.useFormatterForExport) {
    if (columnDef.excelValueFormatter) {
      val = columnDef.excelValueFormatter({ value: val, data: row });
    } else if (vf) {
      val = vf({ value: val, data: row });
    }
  }
  return val ?? '';
}

export function getUnitFromGrid(columnDef: IRoseAGGridColumn<any> | { exportUnit: Units }) {
  return columnDef.exportUnit || Units.NONE;
}

export function dateRawFormatter(params: ValueFormatterParams<any, any>) {
  // TODO fix dayjs types
  return params.value ? (roseDayjs as any).tz(params.value, 'GMT').toDate() : '';
}

export function dateFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).format('DD.MM.YY') : '';
}

export function dateTimeFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).format('DD.MM.YYYY HH:mm') : '';
}

export function dateLongYearFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).format('DD.MM.YYYY') : '';
}

export function timeFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).format('HH:mm') : '';
}

export function fromNowFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).fromNow() : '';
}

export function mfaInfoFormatter(params: ValueFormatterParams<any, any>) {
  const { mfatype, mfaenforced } = params.data;
  return `${mfaTypeToString(mfatype) || ''} ${mfaenforced ? '🔒' : ''}`;
}

export function fromNowWoSuffixFormatter(params: ValueFormatterParams<any, any>) {
  return params.value ? roseDayjs(params.value).fromNow(true) : '';
}

export function durationFormatter(params: ValueFormatterParams<any, number>) {
  return params.value ? roseDayjs.duration(params.value, 's').format('H:mm:ss') : '';
}

export function versionFormatter(params: ValueFormatterParams<any, any>) {
  const version = roseDayjs(params.value, 'DD-MM-YYYY.HH.mm', true);
  if (!version.isValid()) {
    return params?.value;
  }
  return params?.value && version.format('DD.MM.YYYY HH:mm');
}

export function hostnameFormatter(params: ValueFormatterParams<any, Array<string> | undefined>) {
  if (!params?.value) {
    return '-';
  }
  if (params.value.includes('staging')) {
    return 'staging';
  }
  return chain(params.value).split('.').first().value() || '-';
}

export function sexFormatter(params: ValueFormatterParams<any, GeschlechtType>) {
  return params.value === GeschlechtType.MALE ? 'Herr' : params.value === GeschlechtType.FEMALE ? 'Frau' : '-';
}

// TODO use numeral instead
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function dauerFromSecondsFormatter(params: ValueFormatterParams<any, number>) {
  return params.value ? dauerFilter.filters.dauer(params.value) : '-';
}

export function dauerFromMinutesFormatter(params: ValueFormatterParams<any, number>) {
  return params.value ? dauerFilter.filters.dauer(params.value, 'm') : '-';
}

export function arrayFormatter(params: ValueFormatterParams<any, Array<any>>) {
  return params.value ? join(params.value, ', ') : '-';
}

export function binarySizeFormatter(params: ValueFormatterParams<any, number>) {
  if (!params?.value || params?.value < 0) {
    return '-';
  }
  return params.value ? formatBytes(params.value, 1) : '-';
}

export function lastArrayFormatter(params: ValueFormatterParams<any, Array<any>>) {
  if (!params?.value || params?.value.length === 0) {
    return '-';
  }
  return params?.value && roseDayjs(params.value.pop().lastActivity).format('DD.MM.YYYY HH:mm');
}

export function decimalFormatter(params: ValueFormatterParams<any, number>) {
  if (params.value || params.value === 0) {
    return numeral(params.value, { fractionDigits: 0 });
  }
  if (params.value === undefined) {
    return '-';
  }
  return numeral(0, { fractionDigits: 0 });
}

export function euroFormatter(params: { value: number }) {
  if (params.value || params.value === 0) {
    if (params.value > 0 && params.value < 1) {
      return numeral(params.value, { fractionDigits: 1, style: 'currency', currency: 'EUR' });
    }
    return numeral(params.value, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
  }
  if (params.value === undefined) {
    return '-';
  }
  return numeral(0, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
}

export function euroFormatterEmptyUndefined(params: ValueFormatterParams<any, number>) {
  if (params.value || params.value === 0) {
    return numeral(params.value, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
  }
  return '';
}

export function euroAndCentFormatter(params: ValueFormatterParams<any, number>) {
  if (params.value || params.value === 0) {
    return numeral(params.value, { fractionDigits: 2, style: 'currency', currency: 'EUR' });
  }
  return '';
}

export function percentFormatter(params: ValueFormatterParams<any, number>) {
  if (params.value || params.value === 0) {
    return `${numeral(params.value, { style: 'decimal', fractionDigits: 0 })} %`;
  }
  return '';
}

export function percent100Formatter(params: ValueFormatterParams<any, number>) {
  if (params.value || params.value === 0) {
    return `${numeral(params.value * 100, { style: 'decimal', fractionDigits: 0 })} %`;
  }
  return '';
}

export function booleanJaNeinFormatter(params: ValueFormatterParams<any, boolean>) {
  if (!params) {
    return '';
  }
  if (params.value) {
    return 'ja';
  }
  if (!params.value) {
    return 'nein';
  }
  return '';
}

export function folgeTermineRawDateFormatter(params: ValueFormatterParams<any, ITerminDetails[]>) {
  return folgeTermineBaseFormatter(params, true) as Date;
}

export function folgeTermineFormatter(params: ValueFormatterParams<any, ITerminDetails[]>) {
  return folgeTermineBaseFormatter(params, false) as string;
}

function folgeTermineBaseFormatter(params: ValueFormatterParams<any, ITerminDetails[]>, asDate: boolean, tz = 'GMT') {
  const folgeTermine = params.value;
  if (folgeTermine) {
    const nextTermin = chain(folgeTermine)
      .filter(t => t.status !== TerminStatusType.OFFEN && roseDayjs().isBefore(t.tag))
      .sortBy(t => t.tag)
      .first()
      .value();
    if (nextTermin) {
      // TODO fix dayjs types
      return asDate
        ? (roseDayjs as any).tz(nextTermin.tag, tz).toDate()
        : roseDayjs(nextTermin.tag).format('YYYY-MM-DD');
    }
  }
  const hatOffenenTermin = some(folgeTermine, t => t.status === TerminStatusType.OFFEN);
  return hatOffenenTermin ? 'OT' : '';
}

export interface IPatientInfo {
  name?: string;
  vorname?: string;
  patid?: string;
}

export function patientDisplayText(p?: IPatientInfo) {
  if (!p) {
    return '-';
  }
  if (!p.name && !p.vorname) {
    return `Name konnte nicht aufgelöst werden (${p.patid})`;
  }
  return `${p.name}, ${p.vorname} (${p.patid})`;
}

export function patientComparer(x: any, y: any, a: IRowNode<IPatientInfo>, b: IRowNode<IPatientInfo>) {
  return patientDisplayText(a.data).localeCompare(patientDisplayText(b.data));
}

export function behandlerFormatter(behandlerService: IBehandlerService) {
  return (params: { value: string }) => params && params.value && behandlerService.getProperty(params.value);
}

export function behandlerMitIdFormatter(behandlerService: IBehandlerService) {
  return (params: { value: string }) => {
    if (params && params.value) {
      const id = params.value;
      return `${behandlerService.getProperty(id)} (${id})`;
    }
  };
}

export function behandlerArrayFormatter(behandlerService: IBehandlerService, getBehandlerId = (v: any) => v) {
  return (params: { value: string }) =>
    chain(params?.value)
      .map(v => behandlerService.getProperty(getBehandlerId(v)))
      .join(', ')
      .value();
}

export function behandlerMitIdArrayFormatter(behandlerService: IBehandlerService, getBehandlerId = (v: any) => v) {
  return (params: { value: string }) =>
    chain(params?.value)
      .map(v => {
        const id: string = getBehandlerId(v);
        return `${behandlerService.getProperty(id)} (${id})`;
      })
      .join(', ')
      .value();
}

export function folgeTermineComparer(
  x: unknown,
  y: unknown,
  a: IRowNode<ITerminInfoContainer>,
  b: IRowNode<ITerminInfoContainer>,
) {
  const af = a.data?.folgetermine;
  const bf = b.data?.folgetermine;
  if (!af || !bf) {
    return 0;
  }
  const afs = size(af.filter(t => t.status !== TerminStatusType.OFFEN));
  const bfs = size(bf.filter(t => t.status !== TerminStatusType.OFFEN));
  const aos = size(af.filter(t => t.status === TerminStatusType.OFFEN));
  const bos = size(bf.filter(t => t.status === TerminStatusType.OFFEN));
  return bfs * 100 + bos - (afs * 100 + aos);
}

export function stringDateComparer() {
  return (a: string, b: string) => {
    if (a && b) {
      return roseDayjs(a).valueOf() - roseDayjs(b).valueOf();
    }
    return a ? 1 : b ? -1 : 0;
  };
}

export function momentComparer() {
  return (a: Date, b: Date) => {
    if (a && b) {
      return a.valueOf() - b.valueOf();
    }
    return a ? 1 : b ? -1 : 0;
  };
}

export function teamIconClass(team: ITeam) {
  switch (team.typ) {
    case TeamTypes.MITARBEITER:
      return 'user';
    case TeamTypes.SINGLE:
      return team.singleTyp === BehandlerType.PZR ? 'user-nurse' : 'user-md';
    case TeamTypes.BUNDLE:
      return 'user-md';
    case TeamTypes.PRAXIS:
      return 'hospital';
    case TeamTypes.INAKTIV:
      return 'user-times';
    case TeamTypes.TERMINGRUPPE:
      return 'calendar-alt';
    case TeamTypes.TEAM:
    case TeamTypes.AUTOTEAM:
    case TeamTypes.ALLE:
    case TeamTypes.UNUSED:
      return 'users';
    default:
      return 'question-circle';
  }
}

export function classZeroValue(params: ValueFormatterParams<any, number>) {
  if ((params?.value && params.value < 0.005 && params.value > -0.005) || !params.value) {
    return 'cell-zero-value';
  }
}

export function classHoursAlarmCss(cell: any, hourThreshold: number, className: string, lessThen?: boolean) {
  if (hourThreshold && cell) {
    const cellMoment = roseDayjs(cell);
    // TODO fix dayjs types
    const hoursDiff = roseDayjs.duration(createUTC().diff(cellMoment)).asHours();
    if (lessThen) {
      if (hoursDiff < hourThreshold && className) {
        return className;
      }
    } else if (hoursDiff > hourThreshold && className) {
      return className;
    }
  }
  return 'none';
}

export const paramMomentFormat = 'YYYY-MM-DD';

const baseCol = (
  field: string,
  headerName: string,
  columnGroup: string,
  width: number,
  columnGroupShow?: boolean,
): IRoseAGGridColumn<any> => ({
  field,
  width,
  headerName,
  headerTooltip: headerName,
  columnGroupShow: columnGroupShow === true ? 'closed' : 'open',
  columnGroup,
  sortable: true,
  resizable: true,
});

export const euroCol = (
  field: string,
  headerName: string,
  columnGroup = 'default',
  width = 80,
  columnGroupShow?: boolean,
): IRoseAGGridColumn<any> => ({
  ...baseCol(field, headerName, columnGroup, width, columnGroupShow),
  exportUnit: Units.EURO,
  valueFormatter: euroFormatter,
  cellStyle: { 'text-align': 'right' },
  cellClass: (params: any) => classZeroValue(params),
});

export const percentCol = (
  field: string,
  headerName: string,
  columnGroup = 'default',
  width = 80,
  columnGroupShow?: boolean,
  useBy100Formatter = false,
): IRoseAGGridColumn<any> => ({
  ...baseCol(field, headerName, columnGroup, width, columnGroupShow),

  exportUnit: Units.PROZENTE,
  valueFormatter: useBy100Formatter ? percent100Formatter : percentFormatter,
  cellStyle: { 'text-align': 'right' },
  cellClass: (params: any) => classZeroValue(params),
});

export const euroPercentColPair = (
  field: string,
  headerName: string,
  columnGroup = 'default',
  width = 80,
  columnGroupShow?: boolean,
  useBy100Formatter = false,
): IRoseAGGridColumn<any> => ({
  headerName: headerName,
  headerTooltip: headerName,
  columnGroupShow: 'open',
  children: [
    euroCol(field, `€`, columnGroup, width, columnGroupShow),
    percentCol(`${field}anteil`, `%`, columnGroup, width, columnGroupShow, useBy100Formatter),
  ],
});

export const dateCol = (
  field: string,
  headerName: string,
  columnGroup = 'default',
  width = 80,
  columnGroupShow?: boolean,
): IRoseAGGridColumn<any> => ({
  ...baseCol(field, headerName, columnGroup, width, columnGroupShow),
  exportUnit: Units.DATUM,
  excelValueFormatter: dateRawFormatter,
  useFormatterForExport: true,
});

export const txtCol = (
  field: string,
  headerName: string,
  columnGroup = 'default',
  width = 80,
  columnGroupShow?: boolean,
): IRoseAGGridColumn<any> => baseCol(field, headerName, columnGroup, width, columnGroupShow);

export const todoCol = (headerName: string, columnGroup = 'default', width = 180, columnGroupShow?: boolean) =>
  txtCol('todo', headerName, columnGroup, width, columnGroupShow);
