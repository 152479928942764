import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VForm, {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c(VTextField, {
    staticClass: "mb-2",
    attrs: {
      "label": "E-Mail",
      "rules": [_vm.requiredRule],
      "dense": ""
    },
    model: {
      value: _vm.user.email,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }), _c(VTextField, {
    staticClass: "mb-2",
    attrs: {
      "label": "Vorname",
      "rules": [_vm.requiredRule, _vm.onlyTextRule],
      "dense": ""
    },
    model: {
      value: _vm.user.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "firstName", $$v);
      },
      expression: "user.firstName"
    }
  }), _c(VTextField, {
    staticClass: "mb-2",
    attrs: {
      "label": "Nachname",
      "rules": [_vm.requiredRule, _vm.onlyTextRule],
      "dense": ""
    },
    model: {
      value: _vm.user.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "lastName", $$v);
      },
      expression: "user.lastName"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Benutzertyp",
      "items": _vm.userAdminLevelOptions,
      "rules": [_vm.requiredRule],
      "dense": ""
    },
    model: {
      value: _vm.user.adminLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "adminLevel", $$v);
      },
      expression: "user.adminLevel"
    }
  }), !_vm.hideClientIdInput ? _c(VAutocomplete, {
    staticClass: "mb-2",
    attrs: {
      "items": _vm.clients,
      "label": "Haupt-Kunde",
      "rules": [_vm.requiredRule],
      "item-text": "cid",
      "item-value": "cid",
      "eager": "",
      "dense": "",
      "filter": _vm.filterClients
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "profile-selection"
        }, [_c('div', {
          staticClass: "profile-name"
        }, [_vm._v(_vm._s(data.item.praxisName))]), _c('div', {
          staticClass: "profile-id"
        }, [_vm._v(_vm._s(data.item.cid))])])];
      }
    }], null, false, 3200130014),
    model: {
      value: _vm.selectedClient,
      callback: function callback($$v) {
        _vm.selectedClient = $$v;
      },
      expression: "selectedClient"
    }
  }) : _vm._e(), _c(VAutocomplete, {
    staticClass: "mb-2",
    attrs: {
      "items": _vm.clients,
      "label": "Zusätzliche Kunden",
      "item-text": "cid",
      "item-value": "cid",
      "chips": "",
      "clearable": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "eager": "",
      "dense": "",
      "filter": _vm.filterClients
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "profile-selection"
        }, [_c('div', {
          staticClass: "profile-name"
        }, [_vm._v(_vm._s(data.item.praxisName))]), _c('div', {
          staticClass: "profile-id"
        }, [_vm._v(_vm._s(data.item.cid))])])];
      }
    }]),
    model: {
      value: _vm.additionalClients,
      callback: function callback($$v) {
        _vm.additionalClients = $$v;
      },
      expression: "additionalClients"
    }
  }), _c('div', {
    staticClass: "tags"
  }, [_c('span', [_vm._v("Kunden mit Tag wählen:")]), _vm._l(_vm.tags2clients, function (clients, tag) {
    return _c('div', {
      staticClass: "tag",
      on: {
        "click": function click($event) {
          return _vm.addTagClients(clients);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  })], 2), _c(VCombobox, {
    staticClass: "mb-2",
    attrs: {
      "items": _vm.viewOptions,
      "label": "Views",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "view-name"
        }, [_vm._v(_vm._s(_vm.listViews[data.item].name) + ",")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c(VCheckbox, {
          attrs: {
            "label": _vm.listViews[data.item].name,
            "input-value": _vm.isViewSelected(data.item),
            "value": ""
          }
        })];
      }
    }]),
    model: {
      value: _vm.user.views,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "views", $$v);
      },
      expression: "user.views"
    }
  }), _c(VCombobox, {
    staticClass: "mb-2",
    attrs: {
      "label": "Externe Benutzer",
      "multiple": ""
    },
    model: {
      value: _vm.user.extids,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "extids", $$v);
      },
      expression: "user.extids"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };