import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "hostElm",
    staticClass: "date-picker-wrap",
    "class": {
      "single-date-picker": _vm.singleDatePicker,
      prefixed: _vm.prefixed,
      suffixed: _vm.suffixed
    }
  }, [_c('date-range-picker', {
    ref: "picker",
    staticClass: "datePicker",
    "class": {
      enabled: _vm.dateEnabled,
      disabled: _vm.disabled || _vm.readonly
    },
    attrs: {
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "opens": _vm.opensSmart,
      "locale-data": _vm.locale,
      "singleDatePicker": _vm.singleDatePicker,
      "timePicker": _vm.timePicker,
      "timePicker24Hour": _vm.timePicker24Hour,
      "showWeekNumbers": _vm.showWeekNumbers,
      "showDropdowns": _vm.showDropdowns,
      "autoApply": _vm.autoApply,
      "dateRange": _vm.dateRange,
      "ranges": _vm.ranges,
      "linkedCalendars": _vm.linkedCalendars,
      "dateFormat": _vm.dateFormat,
      "alwaysShowCalendars": _vm.alwaysShowCalendars,
      "append-to-body": _vm.appendToBody,
      "closeOnEsc": _vm.closeOnEsc
    },
    on: {
      "update": _vm.dateUpdate,
      "toggle": _vm.dateOpen
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn(picker) {
        return [!_vm.singleDatePicker ? _c('span', [_vm._v(_vm._s(_vm._f("date")(picker.startDate)) + " - " + _vm._s(_vm._f("date")(picker.endDate)))]) : _c('span', [_vm._v(_vm._s(_vm._f("date")(picker.startDate)))])];
      }
    }])
  }), !_vm.hideCalendarIcon ? _c(VBtn, {
    staticClass: "open-button",
    attrs: {
      "color": "primary",
      "small": "",
      "disabled": _vm.disabled || _vm.readonly
    },
    on: {
      "click": function click($event) {
        return _vm.openPicker($event);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fa-calendar")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };