import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.editJsonDialog,
      callback: function callback($$v) {
        _vm.editJsonDialog = $$v;
      },
      expression: "editJsonDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(_vm.title))]), _vm.value ? _c(VCardText, [_c('div', {
    ref: "jsonEditorHost"
  })]) : _vm._e(), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeJsonDialog(false);
      }
    }
  }, [_vm._v("Close")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeJsonDialog(true);
      }
    }
  }, [_vm._v("Save")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };