import { VChip } from 'vuetify/lib/components/VChip';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_vm._v("lade...")]) : _c('div', {
    staticClass: "imports"
  }, [_c(VTabs, {
    directives: [{
      name: "model-bind-view-state",
      rawName: "v-model-bind-view-state.number",
      value: "tab",
      expression: "\"tab\"",
      modifiers: {
        "number": true
      }
    }],
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, [_c('span', [_vm._v("Status")]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl Import-Kunden"
    }
  }, [_vm._v(_vm._s(_vm.status.count))]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl verspäteter Komplett-Importe",
      "color": "red",
      "text-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.status.late))]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl laufender Komplett-Importe",
      "color": "orange",
      "text-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.status.running))])], 1), _c(VTab, [_vm._v("Fehler")]), _c(VTab, [_vm._v("Fehler-Gruppen")]), _c(VTab, [_c('span', [_vm._v("Worker")]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl Worker"
    }
  }, [_vm._v(_vm._s(_vm.workerStatus.count))]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl arbeitender Worker",
      "color": "green",
      "text-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.workerStatus.running))]), _c(VChip, {
    staticClass: "ma-1",
    attrs: {
      "small": "",
      "title": "Anzahl idle Worker",
      "color": "orange",
      "text-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.workerStatus.idle))])], 1), _c(VTabsItems, {
    staticClass: "tabs",
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, [_c('div', {
    staticClass: "current-status"
  }, [_c('div', {
    staticClass: "imports-header"
  }, [_c('ImportChartComponent', {
    staticClass: "mt-2 mb-2",
    attrs: {
      "importInfos": _vm.importStatesRaw
    }
  })], 1), _c('ImportInfoCardList', {
    staticClass: "inc-imports",
    attrs: {
      "imports": _vm.incremental,
      "full": false,
      "typename": "update",
      "agethreshold": 300
    },
    on: {
      "openTab": _vm.openTab
    }
  }), _c('ImportInfoCardList', {
    staticClass: "full-imports",
    attrs: {
      "imports": _vm.full,
      "full": true,
      "typename": "komplett",
      "agethreshold": 108000
    },
    on: {
      "openTab": _vm.openTab
    }
  })], 1)]), _c(VTabItem, {
    staticClass: "errors-tab"
  }, [_c('div', {
    staticClass: "errors"
  }, [_c('ag-grid-vue', {
    staticClass: "error-grid ag-theme-balham",
    attrs: {
      "rowData": _vm.errors,
      "columnDefs": _vm.importErrorsColumns
    }
  })], 1)]), _c(VTabItem, {
    staticClass: "errors-groups-tab"
  }, [_c('div', {
    staticClass: "error-groups"
  }, [_c('ImportErrorGroups', {
    attrs: {
      "errors": _vm.errors
    }
  })], 1)]), _c(VTabItem, {
    staticClass: "workers-tab"
  }, [_c('div', {
    staticClass: "workers"
  }, [_c('ag-grid-vue', {
    staticClass: "worker-grid ag-theme-balham",
    attrs: {
      "rowData": _vm.workers,
      "columnDefs": _vm.importWorkersColumns
    }
  })], 1)])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };