import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.newResulatDialog,
      callback: function callback($$v) {
        _vm.newResulatDialog = $$v;
      },
      expression: "newResulatDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(_vm.eventTrigger))]), _vm.rawResponse && _vm.rawResponse.statusText ? _c('h4', {
    staticClass: "succes",
    "class": {
      succes: _vm.status,
      failure: !_vm.status
    }
  }, [_vm._v("Status: " + _vm._s(_vm.rawResponse.statusText) + " (" + _vm._s(_vm.rawResponse.status) + ")")]) : _c('div', [_vm.status ? _c('h4', {
    staticClass: "succes"
  }, [_vm._v("Status: success")]) : _c('h4', {
    staticClass: "failure"
  }, [_vm._v("Status: failure")])]), _c(VCardText, [_vm.rawResponse && _vm.rawResponse.config ? _c('div', [_vm._v(_vm._s(_vm._f("uppercase")(_vm.rawResponse.config.method)) + " " + _vm._s(_vm.rawResponse.config.url))]) : _c('div', [_vm._v(_vm._s(_vm.path))]), _vm.data ? _c('div', {
    staticClass: "mt-3"
  }, [_c('h5', [_vm._v("Response:")]), _c('div', {
    staticClass: "verbatim-data"
  }, [_vm._v(_vm._s(_vm.responseDialogData))])]) : _c('p', {
    staticClass: "failure"
  }, [_vm._v("Keine Antwort")])]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.newResulatDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };