var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "htmlToPdf"
  }, [_c('div', {
    staticClass: "options"
  }, [_c('span', [_vm._v("r4chost:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.r4chost,
      expression: "r4chost"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.r4chost
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.r4chost = $event.target.value;
      }
    }
  })]), _c('MonacoEditor', {
    staticClass: "htmlString",
    attrs: {
      "language": "xml",
      "options": {
        renderWhitespace: "all",
        ignoreTrimWhitespace: false,
        automaticLayout: true
      }
    },
    model: {
      value: _vm.htmlString,
      callback: function callback($$v) {
        _vm.htmlString = $$v;
      },
      expression: "htmlString"
    }
  }), _c('div', {
    staticClass: "preview",
    "class": {
      loading: _vm.loading
    }
  }, [_vm.error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _vm.pdfUrl ? _c('embed', {
    attrs: {
      "src": _vm.pdfUrl,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  }) : _vm._e()]), _c('MonacoEditor', {
    staticClass: "renderOptions",
    attrs: {
      "language": "json",
      "options": {
        renderWhitespace: "all",
        ignoreTrimWhitespace: false,
        automaticLayout: true
      }
    },
    model: {
      value: _vm.renderOptions,
      callback: function callback($$v) {
        _vm.renderOptions = $$v;
      },
      expression: "renderOptions"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };