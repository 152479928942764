import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bool-icon"
  }, [_vm.isTrue === true ? _c(VIcon, {
    attrs: {
      "color": "green"
    }
  }, [_vm._v("fas fa-circle-check")]) : _vm.isTrue === false ? _c(VIcon, {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("fas fa-circle-xmark")]) : _c('span', [_vm._v("-")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };