import { omit } from 'lodash';

const USER_LOCAL_STORAGE_KEY = 'user';

export function saveUserObject(user: any) {
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(omit(user, 'tokens', 'clients')));
}

export function clearUserObject() {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}

export function readUserObject() {
  const userSerialized = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  if (!userSerialized) {
    return null;
  }

  try {
    return JSON.parse(userSerialized);
  } catch {
    return null;
  }
}
