import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { defineComponent } from 'vue';
import EditProfileGroupDialogue from '@/components/dialogues/EditProfileGroupDialogue.vue';
import { find, chain, toNumber } from 'lodash';
export default defineComponent({
  name: 'GroupAutocomplete',
  components: {
    EditProfileGroupDialogue: EditProfileGroupDialogue
  },
  props: {
    availableGroups: {
      type: [],
      required: true
    },
    value: {
      type: [String, Number],
      required: false,
      "default": null
    }
  },
  data: function data() {
    return {
      editProfileGroup: {},
      showEditGroupModal: false
    };
  },
  computed: {
    selectedGroupId: {
      get: function get() {
        return this.value ? toNumber(this.value) : null;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    selectedGroup: function selectedGroup() {
      var _this = this;
      return find(this.availableGroups, function (group) {
        return group.id === _this.selectedGroupId;
      }) || null;
    },
    availableClusters: function availableClusters() {
      return chain(this.availableGroups).map(function (group) {
        return group.dwh.clusters;
      }).flatten().uniq().value();
    }
  },
  methods: {
    openEditGroupModal: function openEditGroupModal(profileGroup) {
      this.editProfileGroup = profileGroup;
      this.showEditGroupModal = true;
    },
    loadGroups: function loadGroups() {
      this.$emit('loadGroups');
    },
    change: function change() {
      this.$emit('change', this.selectedGroupId);
    }
  }
});