import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "rtfDebuggerWrap"
  }, [_c(VCard, {
    staticClass: "fileList"
  }, [_c(VCardTitle, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Client")]), _c(VAutocomplete, {
    staticStyle: {
      "width": "10px"
    },
    attrs: {
      "items": _vm.clientIds,
      "flat": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "full-width": "",
      "append-icon": null
    },
    on: {
      "input": _vm.clientIdChanged
    },
    model: {
      value: _vm.cid,
      callback: function callback($$v) {
        _vm.cid = $$v;
      },
      expression: "cid"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.templateSearch,
      expression: "templateSearch"
    }],
    staticClass: "search ml-2 mr-3 mb-1",
    attrs: {
      "type": "text",
      "placeholder": "suche"
    },
    domProps: {
      "value": _vm.templateSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.templateSearch = $event.target.value;
      }
    }
  }), _vm.cid && !_vm.currentClientR4cHost ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Kein r4cHost gesetzt")]) : _vm._e(), _vm.cid && !_vm.r4cForCurrentClientIdConnected ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Kein r4c verbunden")]) : _vm._e(), _vm.charlyTemplates && _vm.charlyTemplates.length === 0 ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Keine charly templates")]) : _vm._e(), _c('div', {
    staticClass: "editor-list"
  }, [_vm._l(_vm.charlyTemplatesFiltered, function (group) {
    return [_c('div', {
      staticClass: "list-sub-header"
    }, [_vm._v(_vm._s(group.group))]), _vm._l(group.entries, function (entry) {
      return [_c('div', {
        staticClass: "list-item text-right",
        "class": {
          active: _vm.selectedGroup === group.group && _vm.selectedEntry === entry
        },
        on: {
          "click": function click($event) {
            return _vm.selectTemplate(group.group, entry);
          }
        }
      }, [_vm._v(_vm._s(entry))])];
    })];
  })], 2)], 1), _c('div', {
    staticClass: "mainWrap"
  }, [_c('div', {
    staticClass: "viewToogles"
  }, [_c('span', {
    staticClass: "ml-3 mr-4"
  }, [_vm._v("Views")]), _c(VSwitch, {
    staticClass: "mr-6",
    attrs: {
      "dense": "",
      "label": "Rtf"
    },
    model: {
      value: _vm.showRtf,
      callback: function callback($$v) {
        _vm.showRtf = $$v;
      },
      expression: "showRtf"
    }
  }), _c(VSwitch, {
    staticClass: "mr-6",
    attrs: {
      "dense": "",
      "label": "fixed Rtf"
    },
    model: {
      value: _vm.showFixedRtf,
      callback: function callback($$v) {
        _vm.showFixedRtf = $$v;
      },
      expression: "showFixedRtf"
    }
  }), _c(VSwitch, {
    staticClass: "mr-6",
    attrs: {
      "dense": "",
      "label": "doc settings"
    },
    model: {
      value: _vm.showDocSettings,
      callback: function callback($$v) {
        _vm.showDocSettings = $$v;
      },
      expression: "showDocSettings"
    }
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.renderRtf();
      }
    }
  }, [_vm._v("render rtf")]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "x-small": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.renderFixedRtf();
      }
    }
  }, [_vm._v("render fixed rtf")])], 1), _c('div', {
    staticClass: "viewsWrap"
  }, [_vm.showRtf ? _c('div', {
    staticClass: "rtfEditor"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("RTF-Editor")]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.selectTemplate(_vm.selectedGroup, _vm.selectedEntry);
      }
    }
  }, [_vm._v("reset")]), _vm.documentUrls ? [_c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.diffEditor = !_vm.diffEditor;
      }
    }
  }, [_c('span', [_vm._v("toogle diff")])]), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.overrideCharly();
      }
    }
  }, [_c('span', [_vm._v("override charly")])]), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.restoreCharlyTemplate();
      }
    }
  }, [_c('span', [_vm._v("restore charly")])])] : _vm._e(), _vm.rtfTemplateText ? _c(VCard, {
    staticClass: "mt-2 canvasWrap"
  }, [_c('MonacoEditor', {
    key: "editor_" + _vm.diffEditor,
    staticStyle: {
      "width": "700px",
      "height": "800px"
    },
    attrs: {
      "language": "xml",
      "diffEditor": _vm.diffEditor,
      "original": _vm.originalRtfTemplateText,
      "options": {
        renderWhitespace: "all",
        ignoreTrimWhitespace: false,
        automaticLayout: true
      }
    },
    model: {
      value: _vm.rtfTemplateText,
      callback: function callback($$v) {
        _vm.rtfTemplateText = $$v;
      },
      expression: "rtfTemplateText"
    }
  })], 1) : _vm._e()], 2) : _vm._e(), _vm.showFixedRtf && _vm.fixedRtfContent ? _c('div', {
    staticClass: "fixedRtfEditor"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("fixed RTF-Editor")]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.diffFixedRtf = !_vm.diffFixedRtf;
      }
    }
  }, [_vm._v("diff with rtf")]), _c(VCard, {
    staticClass: "mt-2"
  }, [_c('MonacoEditor', {
    key: "editor_fixed_" + _vm.diffFixedRtf,
    staticStyle: {
      "width": "800px",
      "height": "800px"
    },
    attrs: {
      "language": "xml",
      "diffEditor": _vm.diffFixedRtf,
      "original": _vm.rtfTemplateText,
      "options": {
        renderWhitespace: "all",
        ignoreTrimWhitespace: false
      }
    },
    model: {
      value: _vm.fixedRtfContent,
      callback: function callback($$v) {
        _vm.fixedRtfContent = $$v;
      },
      expression: "fixedRtfContent"
    }
  })], 1)], 1) : _vm._e(), _vm.showDocSettings && _vm.documentSettings ? _c('div', {
    staticClass: "docSettings"
  }, [_c(VSwitch, {
    attrs: {
      "dense": "",
      "label": "override doc settings"
    },
    model: {
      value: _vm.overrideDocumentsSettings,
      callback: function callback($$v) {
        _vm.overrideDocumentsSettings = $$v;
      },
      expression: "overrideDocumentsSettings"
    }
  }), _c(VCard, [_c('MonacoEditor', {
    staticStyle: {
      "width": "400px",
      "height": "250px"
    },
    attrs: {
      "language": "json"
    },
    model: {
      value: _vm.documentSettingsOverriden,
      callback: function callback($$v) {
        _vm.documentSettingsOverriden = $$v;
      },
      expression: "documentSettingsOverriden"
    }
  })], 1), _c(VSwitch, {
    attrs: {
      "dense": "",
      "label": "override lineSpacingLUT"
    },
    model: {
      value: _vm.overrideLineSpacingLUT,
      callback: function callback($$v) {
        _vm.overrideLineSpacingLUT = $$v;
      },
      expression: "overrideLineSpacingLUT"
    }
  }), _c(VCard, [_c('MonacoEditor', {
    staticStyle: {
      "width": "400px",
      "height": "500px"
    },
    attrs: {
      "language": "json",
      "original": _vm.lineSpacingLUT
    },
    model: {
      value: _vm.lineSpacingLUTOverriden,
      callback: function callback($$v) {
        _vm.lineSpacingLUTOverriden = $$v;
      },
      expression: "lineSpacingLUTOverriden"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    ref: "editorContainer",
    staticClass: "imageDisplay"
  }, [_vm.showOutput ? [_vm.documentUrls ? [_c(VBtn, {
    staticClass: "white--text mr-1",
    attrs: {
      "href": _vm.documentUrls.rtfUrl,
      "x-small": "",
      "color": "blue-grey"
    }
  }, [_c('span', [_vm._v("rtf")])]), _c(VBtn, {
    staticClass: "white--text mr-1",
    attrs: {
      "href": _vm.documentUrls.pdfUrl,
      "x-small": "",
      "target": "_blank",
      "color": "blue-grey"
    }
  }, [_c('span', [_vm._v("pdf")])]), _c(VBtn, {
    staticClass: "white--text",
    attrs: {
      "href": _vm.documentUrls.imageUrl,
      "x-small": "",
      "target": "_blank",
      "color": "blue-grey"
    }
  }, [_c('span', [_vm._v("img")])]), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.usePdfEmbed = !_vm.usePdfEmbed;
      }
    }
  }, [_c('span', [_vm._v("switch view")])])] : _vm._e(), _vm.usePdfEmbed && _vm.documentUrls ? _c('embed', {
    attrs: {
      "src": _vm.documentUrls.pdfUrl,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  }) : _vm._e(), _c(VCard, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.usePdfEmbed,
      expression: "!usePdfEmbed"
    }],
    staticClass: "canvasWrap"
  }, [_c('v-stage', {
    ref: "stage",
    attrs: {
      "config": _vm.stageSize
    }
  }, [_c('v-layer', {
    ref: "layer"
  }, [!_vm.imageConfig.loading ? _c('v-image', {
    attrs: {
      "config": _vm.imageConfig
    }
  }) : _vm._e()], 1)], 1)], 1)] : _vm._e()], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };