import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "import-type-cell d-flex justify-end"
  }, [_c('div', {
    staticClass: "chip-group"
  }, [_vm.params.value === true ? _c(VChip, {
    attrs: {
      "color": "teal lighten-2",
      "text-color": "white",
      "x-small": "",
      "label": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("fas fa-arrow-trend-up")]), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("inc")])], 1) : _vm.params.value === false ? _c(VChip, {
    attrs: {
      "color": "deep-purple lighten-2",
      "text-color": "white",
      "x-small": "",
      "label": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("fas fa-database")]), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("full")])], 1) : _c('span', {
    staticClass: "text--disabled"
  }, [_vm._v("Not Available")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };