import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.showDialogue,
      callback: function callback($$v) {
        _vm.showDialogue = $$v;
      },
      expression: "showDialogue"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Abo Testzeitraum verlängern")]), _c(VCardText, _vm._l(_vm.subscriptions, function (sub) {
    return _c('div', {
      key: sub.id,
      staticClass: "d-flex align-center justify-space-between"
    }, [_c('div', {
      staticClass: "subscription-info d-flex flex-column"
    }, [_c('div', {
      staticClass: "sub-items"
    }, [_vm._v("Pakete")]), _c('div', {
      staticClass: "sub-id"
    }, [_vm._v("Link")]), _c('div', {
      staticClass: "sub-trialstart"
    }, [_vm._v("Test-Anfang")]), _c('div', {
      staticClass: "sub-trialende"
    }, [_vm._v("Test-Ende")])]), _c('div', {
      staticClass: "subscription-info d-flex flex-column"
    }, [_c('div', {
      staticClass: "sub-items"
    }, _vm._l(sub.items, function (item) {
      return _c('span', {
        key: item.id
      }, [_vm._v(_vm._s(item.priceNickname) + ",")]);
    }), 0), _c('div', {
      staticClass: "sub-id"
    }, [_c('a', {
      attrs: {
        "href": "https://dashboard.stripe.com/subscriptions/" + sub.id,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(sub.id))])]), sub.trialstart ? _c('div', {
      staticClass: "sub-trialstart"
    }, [_vm._v(_vm._s(_vm._f("moment")(sub.trialstart, 'DD.MM.YYYY')))]) : _c('div', {
      staticClass: "sub-trialstart"
    }, [_vm._v("-")]), _c('div', {
      staticClass: "sub-trialende"
    }, [_c(VTextField, {
      attrs: {
        "type": "text",
        "outlined": "",
        "hide-details": "",
        "dense": "",
        "placeholder": "Datum"
      },
      model: {
        value: sub.trialende,
        callback: function callback($$v) {
          _vm.$set(sub, "trialende", $$v);
        },
        expression: "sub.trialende"
      }
    })], 1)]), _c(VBtn, {
      attrs: {
        "color": "primary",
        "dark": "",
        "small": "",
        "fab": "",
        "depressed": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.saveSubscriptionTrialEnd(sub);
        }
      }
    }, [_c(VIcon, {
      attrs: {
        "small": ""
      }
    }, [_vm._v("fa-clock-o")])], 1)], 1);
  }), 0), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeShowProlongTestperiodDialog();
      }
    }
  }, [_vm._v("Fertig")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };