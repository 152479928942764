import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.isSolutioTeam ? _c(VCard, {
    staticClass: "helpdesk-infos",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('span', {
    staticClass: "card-title"
  }, [_vm._v("helpdesk")]), !_vm.hasError ? _c('div', {
    staticClass: "d-flex actions-section"
  }, [_vm.helpdeskid ? _c(VChip, {
    attrs: {
      "color": "#00A37B",
      "small": "",
      "label": "",
      "dark": ""
    },
    on: {
      "click": _vm.showHelpdeskIdModalDialog
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.helpdeskid))]), _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-pencil-alt")])], 1) : _vm._e(), _vm.helpdeskid ? _c(VChip, {
    staticClass: "ml-2",
    attrs: {
      "color": "#00A37B",
      "small": "",
      "label": "",
      "dark": "",
      "disabled": !_vm.canSync
    },
    on: {
      "click": _vm.sync
    }
  }, [_c('span', [_vm._v("Sync Name")]), _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-sync")])], 1) : _vm._e(), _vm.company ? _c(VChip, {
    staticClass: "ml-2",
    attrs: {
      "href": _vm.helpdeskLink,
      "target": "_blank",
      "text": "",
      "color": "#00A37B",
      "small": "",
      "label": "",
      "dark": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.company.name))]), _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-external-link-alt")])], 1) : _vm._e(), _vm.helpdeskid ? _c(VChip, {
    staticClass: "ml-2",
    attrs: {
      "text": "",
      "color": "error",
      "small": "",
      "label": "",
      "dark": ""
    },
    on: {
      "click": _vm.deleteHelpdeskId
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-link-slash")])], 1) : _vm._e()], 1) : _vm._e()]), _c(VCardText, [_vm.isLoading ? _c('div', {
    staticClass: "loader"
  }, [_c(VOverlay, {
    attrs: {
      "value": _vm.isLoading,
      "absolute": ""
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1) : _vm.hasError ? _c('div', {
    staticClass: "fetch-error"
  }, [_vm.hasError ? _c(VAlert, {
    staticClass: "mb-0",
    attrs: {
      "prominent": "",
      "text": "",
      "type": "error",
      "icon": "fas fa-exclamation-triangle",
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Fehler beim Laden der Daten")]), _vm.errorText ? _c('span', {
    staticClass: "pl-1"
  }, [_vm._v(_vm._s(_vm.errorText))]) : _vm._e()]) : _vm._e()], 1) : _c('div', {
    staticClass: "helpdesk-data"
  }, [!_vm.helpdeskid ? _c('div', {
    staticClass: "no-helpdesk mt-2"
  }, [_c(VAlert, {
    attrs: {
      "prominent": "",
      "text": "",
      "color": "#00A37B",
      "icon": "fas fa-ticket"
    }
  }, [_c(VRow, {
    staticClass: "no-gutters align-center"
  }, [_c(VCol, {
    staticClass: "grow"
  }, [_c('h3', [_vm._v("Kein Helpdesk-Kunde")])]), _c(VCol, {
    staticClass: "shrink mr-2"
  }), _c(VCol, {
    staticClass: "shrink"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VTextField, {
    staticClass: "company-id mr-2",
    attrs: {
      "label": "Helpdesk-ID",
      "hide-details": "",
      "dense": "",
      "outlined": "",
      "clearable": ""
    },
    model: {
      value: _vm.customHelpdeskId,
      callback: function callback($$v) {
        _vm.customHelpdeskId = $$v;
      },
      expression: "customHelpdeskId"
    }
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.customHelpdeskId === "" || _vm.isLinking,
      "outlined": ""
    },
    on: {
      "click": _vm.setHelpdeskId
    }
  }, [_c(VIcon, {
    staticClass: "pr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-user-check")]), _c('span', [_vm._v("Verknüpfen")])], 1), _c(VBtn, {
    staticClass: "no-helpdesk-id",
    attrs: {
      "disabled": _vm.customHelpdeskId !== "" || _vm.isLinking,
      "outlined": ""
    },
    on: {
      "click": _vm.createCompany
    }
  }, [_c(VIcon, {
    staticClass: "pr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("far fa-user-plus")]), _c('span', [_vm._v("Anlegen & Verknüpfen")])], 1)], 1)])], 1)], 1)], 1) : _c('div', {
    staticClass: "helpdesk-data"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "pr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-user")]), _c('div', {
    staticClass: "kontakt-label caption font-weight-bold"
  }, [_vm._v("Kontakte")]), _vm.contacts && _vm.contacts.length ? _c('div', {
    staticClass: "ml-2"
  }, [_vm._v("Doppelklick auf den Kontakt, um ihn zu öffnen")]) : _vm._e()], 1), _vm.contacts && _vm.contacts.length ? _c('div', {
    staticClass: "contacts-table mt-2"
  }, [_c('ag-grid-vue', {
    staticClass: "ag-theme-balham mb-4",
    staticStyle: {
      "height": "150px"
    },
    attrs: {
      "columnDefs": _vm.columnsContacts,
      "rowData": _vm.contacts,
      "suppress-cell-selection": "true",
      "grid-options": "gridOptions"
    },
    on: {
      "cell-double-clicked": _vm.onContactCellClicked
    }
  })], 1) : _c(VAlert, {
    attrs: {
      "text": "",
      "icon": "fas fa-user-slash",
      "dense": ""
    }
  }, [_c('span', [_vm._v("Es sind keine Kontakte hinterlegt")])]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "pr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-ticket")]), _c('div', {
    staticClass: "ticket-label caption font-weight-bold"
  }, [_vm._v("Tickets")]), _vm.tickets && _vm.tickets.length ? _c('div', {
    staticClass: "ml-2"
  }, [_vm._v("Doppleklick auf das Ticket, um es zu öffnen")]) : _vm._e()], 1)]), _vm.tickets && _vm.tickets.length ? _c('div', {
    staticClass: "tickets-table mt-2"
  }, [_c('ag-grid-vue', {
    staticClass: "ag-theme-balham",
    staticStyle: {
      "height": "150px"
    },
    attrs: {
      "columnDefs": _vm.columnsTickets,
      "rowData": _vm.tickets,
      "suppress-cell-selection": "true"
    },
    on: {
      "cell-double-clicked": _vm.onTicketCellClicked
    }
  })], 1) : _c(VAlert, {
    attrs: {
      "text": "",
      "icon": "fas fa-ticket",
      "dense": ""
    }
  }, [_c('span', [_vm._v("Es sind keine Tickets hinterlegt")])])], 1)]), _c(VDialog, {
    attrs: {
      "max-width": "400"
    },
    model: {
      value: _vm.showHelpdeskIdModal,
      callback: function callback($$v) {
        _vm.showHelpdeskIdModal = $$v;
      },
      expression: "showHelpdeskIdModal"
    }
  }, [_c(VCard, [_c(VCardTitle, [_c('h4', {
    staticClass: "font-weight-bold pl-4"
  }, [_vm._v("Helpdesk-ID anpassen")])]), _c(VCardText, {
    staticClass: "pb-2"
  }, [_c(VTextField, {
    staticClass: "mt-2",
    attrs: {
      "label": "Neue Helpdesk-ID",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.customHelpdeskId,
      callback: function callback($$v) {
        _vm.customHelpdeskId = $$v;
      },
      expression: "customHelpdeskId"
    }
  }), _c('span', {
    staticClass: "caption"
  }, [_vm._v("Aktuelle Helpdesk-ID: " + _vm._s(_vm.helpdeskid))])], 1), _c(VCardActions, {
    staticClass: "justify-end"
  }, [_c(VBtn, {
    attrs: {
      "disabled": _vm.customHelpdeskId === "",
      "outlined": "",
      "color": "#00A37B"
    },
    on: {
      "click": _vm.setHelpdeskId
    }
  }, [_c('span', [_vm._v("Ändern")])]), _c(VBtn, {
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.showHelpdeskIdModal = false;
      }
    }
  }, [_c('span', [_vm._v("Abbrechen")])])], 1)], 1)], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };