import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.gutschein ? _c('div', {
    staticClass: "wrap"
  }, [_c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Code")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "autofocus": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.gutschein.code,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "code", $$v);
      },
      expression: "gutschein.code"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Sponsor ID")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_vm.forceSponsor ? [_c(VTextField, {
    attrs: {
      "dense": "",
      "disabled": ""
    },
    model: {
      value: _vm.gutschein.sponsor,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "sponsor", $$v);
      },
      expression: "gutschein.sponsor"
    }
  })] : _c(VTextField, {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.gutschein.sponsor,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "sponsor", $$v);
      },
      expression: "gutschein.sponsor"
    }
  })], 2)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Laufzeit")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "type": "number",
      "suffix": "Monate"
    },
    model: {
      value: _vm.gutschein.monatelaufzeit,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "monatelaufzeit", $$v);
      },
      expression: "gutschein.monatelaufzeit"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Prozentualerkundenerlass")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.gutschein.prozentualerkundenerlass,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "prozentualerkundenerlass", $$v);
      },
      expression: "gutschein.prozentualerkundenerlass"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Absoluterkundenerlass")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "type": "number",
      "suffix": "€"
    },
    model: {
      value: _vm.gutschein.absoluterkundenerlass,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "absoluterkundenerlass", $$v);
      },
      expression: "gutschein.absoluterkundenerlass"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Produkte")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.productsCommaString,
      callback: function callback($$v) {
        _vm.productsCommaString = $$v;
      },
      expression: "productsCommaString"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Notiz")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.gutschein.notiz,
      callback: function callback($$v) {
        _vm.$set(_vm.gutschein, "notiz", $$v);
      },
      expression: "gutschein.notiz"
    }
  })], 1)], 1), _vm.gutschein.meta && _vm.gutschein.meta.sponsorsubscription && _vm.gutschein.meta.customersubscription ? _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Links")]), _c(VFlex, {
    attrs: {
      "xs4": "",
      "pa-2": ""
    }
  }, [_c(VBtn, {
    staticClass: "link-button",
    attrs: {
      "href": _vm.sponsorSubscriptionLink,
      "color": "primary",
      "small": "",
      "target": "_blank"
    }
  }, [_vm._v("Sponsor-Sub")])], 1), _c(VFlex, {
    attrs: {
      "xs4": "",
      "pa-2": ""
    }
  }, [_c(VBtn, {
    staticClass: "link-button",
    attrs: {
      "href": _vm.customerSubscriptionLink,
      "color": "primary",
      "small": "",
      "target": "_blank"
    }
  }, [_vm._v("Customer-Sub")])], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };