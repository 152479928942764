import { map, padStart, split } from 'lodash';
import { roseDayjs } from '../../../base';

export const dauerFilter = {
  filters: {
    dauer(dauer: number, dauerUnit: 'ms' | 's' | 'm' = 's', log = false): string {
      const duration = roseDayjs.duration(dauer, dauerUnit);
      if (log) {
        console.log(duration.format());
      }
      if (duration.asHours() >= 1) {
        if (duration.minutes() > 0) {
          return duration.format('H[h] m[m]');
        }
        return duration.format('H[h]');
      } else if (duration.asMinutes() >= 1) {
        return duration.format('m[m]');
      } else if (dauerUnit === 'ms' && dauer < 1000 && dauer > 0) {
        return `<1s`;
      }
      return duration.format('s[s]');
    },
    dauerShort(
      dauer: number,
      dauerUnit: 'ms' | 's' | 'm' = 's',
      trimMonthZeroes = false,
      trimDayZeroes = false,
      trimHourZeroes = false,
      trimMinuteZeroes = false,
      showSeconds = false,
      log = false,
    ): string {
      const duration = roseDayjs.duration(dauer, dauerUnit);
      if (log) {
        console.log(duration, duration.format());
      }
      // no months
      const f = duration.format(showSeconds ? 'MM:DD:HH:mm:ss' : 'MM:DD:HH:mm');
      const parts = split(f, ':');
      if ((showSeconds && parts.length === 5) || parts.length === 4) {
        const [MM, DD, HH, mm] = map(parts, p => parseInt(p, 10));
        if (MM > 0) {
          return `${DD + 30 * MM}:${padStart(HH + '', 2, '0')}:${padStart(mm + '', 2, '0')}`;
        }
      }
      if (trimMinuteZeroes) {
        return f.replace(/^00:00:00:00:/, '');
      }
      if (trimHourZeroes) {
        return f.replace(/^00:00:00:/, '');
      }
      if (trimDayZeroes) {
        return f.replace(/^00:00:/, '');
      }
      if (trimMonthZeroes) {
        return f.replace(/^00:/, '');
      }
      return f;
    },
  },
};
