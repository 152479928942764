import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VExpansionPanels, {
    attrs: {
      "flat": ""
    },
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_vm._v("Import Chart")]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "pb-6"
  }, [_c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c('Chart', {
    attrs: {
      "options": _vm.incChartOptions
    }
  }), _vm.normalRangeInc ? _c('div', {
    staticClass: "normal-range-inc d-flex justify-space-between px-12"
  }, [_c('div', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("Normal Range Incremental")]), _vm._l(_vm.normalRangeInc, function (range, index) {
    return _c('div', {
      key: index,
      staticClass: "range"
    }, [_c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(range.pvs) + ": " + _vm._s(range.from) + "m - " + _vm._s(range.to) + "m")])]);
  })], 2) : _vm._e()], 1), _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c('Chart', {
    attrs: {
      "options": _vm.fullChartOptions
    }
  }), _vm.normalRangeFull ? _c('div', {
    staticClass: "normal-range-full d-flex justify-space-between px-12"
  }, [_c('div', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("Normal Range Full")]), _vm._l(_vm.normalRangeFull, function (range, index) {
    return _c('div', {
      key: index,
      staticClass: "range"
    }, [_c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(range.pvs) + ": " + _vm._s(range.from) + "m - " + _vm._s(range.to) + "m")])]);
  })], 2) : _vm._e()], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };