import { defineComponent } from 'vue';
import { FEATURES, ALLFEATURES } from '@rose/types';
import { includes } from 'lodash';
export default defineComponent({
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isValid: false,
      prefixLabel: 'cid',
      prefixRules: [],
      allFeatures: ALLFEATURES,
      requiredRule: function requiredRule(v) {
        return !!v || 'Field is required';
      }
    };
  },
  computed: {
    newClient: {
      get: function get() {
        return this.client;
      },
      set: function set(value) {
        this.$emit('update:client', value);
      }
    }
  },
  watch: {
    isValid: function isValid() {
      this.$emit('valid', this.isValid);
    }
  },
  methods: {
    clientOnChangeLabel: function clientOnChangeLabel() {
      this.prefixLabel = this.client.addSuffix ? 'cid-Prefix' : 'cid';
      this.prefixRules = [this.requiredRule];
    },
    hasMetricsFeature: function hasMetricsFeature() {
      return includes(this.newClient.features, FEATURES.METRICS);
    }
  }
});