import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VBtn, _vm._b({
    staticClass: "main-button",
    "class": {
      "no-padding": _vm.noPadding,
      "no-min-width": _vm.noMinWidth
    },
    on: {
      "click": [function ($event) {
        _vm.showConfirmDialog = true;
      }, function ($event) {
        if (!$event.shiftKey) return null;
        $event.stopPropagation();
        return _vm.confirmed.apply(null, arguments);
      }]
    }
  }, 'v-btn', _vm.buttonAttrs, false), [_vm._t("default"), _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "400px"
    },
    model: {
      value: _vm.showConfirmDialog,
      callback: function callback($$v) {
        _vm.showConfirmDialog = $$v;
      },
      expression: "showConfirmDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(_vm.confirmTitle))]), _c(VCardText, [_vm._v(_vm._s(_vm.description || _vm.actionText))]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showConfirmDialog = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": _vm.colorConfirm
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.confirmed.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.actionText))])], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };