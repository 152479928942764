import { IRoseAGGridColumn, euroAndCentFormatter, dateFormatter } from '@rose/common-ui';
import { IRoseInvoice } from '../../../../types';

export function subscriptionInvoiceColumns(smallScreen: boolean): IRoseAGGridColumn<IRoseInvoice>[] {
  return [
    {
      headerName: 'Customer-ID',
      field: 'customer',
      sort: 'asc',
      cellRenderer: 'ClientsClientIdCell',
      headerTooltip: 'Eindeutige ID des Kunden',
      width: 170,
      pinned: smallScreen ? undefined : 'left',
    },
    {
      headerName: 'Invoice-ID',
      field: 'id',
      headerTooltip: 'Eindeutige ID der Rechnung',
      width: 240,
    },
    {
      headerName: 'Feature',
      field: 'feature',
      headerTooltip: 'Feature des Abonnements',
      width: 400,
    },
    {
      headerName: 'Rechnungsdatum',
      field: 'created',
      headerTooltip: 'Datum der Rechnung',
      valueFormatter: dateFormatter,
      width: 140,
    },
    {
      headerName: 'Rechnungsnummer',
      field: 'number',
      headerTooltip: 'Nummer der Rechnung',
      cellRenderer: 'ClientsClientIdCell',
      width: 140,
    },
    {
      headerName: 'Betrag',
      field: 'amount',
      headerTooltip: 'Betrag der Rechnung',
      valueFormatter: euroAndCentFormatter,
      width: 140,
      flex: 1,
    },
  ];
}
