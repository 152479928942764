var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "vertraege"
  }, _vm._l(_vm.vertraege, function (v) {
    return _c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: v,
        expression: "v",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.copySuccess,
        expression: "copySuccess",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.copyError,
        expression: "copyError",
        arg: "error"
      }],
      staticClass: "vertrag"
    }, [_vm._v(_vm._s(v))]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };