var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.copyValue,
      expression: "copyValue",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }]
  }, [_vm._v(_vm._s(_vm.displayValue))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };