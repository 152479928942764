import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, [_c(VCardTitle, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))])]), _c(VCardText, [_vm.status ? _c('div', {
    staticClass: "status-info"
  }, [_c('div', {
    staticClass: "status"
  }, [_vm.status.ok ? _c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "green"
    }
  }, [_vm._v("fa-circle-check")]) : _c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "red"
    }
  }, [_vm._v("fa-square-xmark")])], 1), _c('div', {
    staticClass: "db"
  }, [_vm.status.dbok === true ? _c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "green"
    }
  }, [_vm._v("fa-database")]) : _vm.status.dbok === false ? _c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "red"
    }
  }, [_vm._v("fa-database")]) : _c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "gray"
    }
  }, [_vm._v("fa-database")])], 1), _c('div', {
    staticClass: "started"
  }, [_vm._v("started: " + _vm._s(_vm._f("moment")(_vm.status.started, 'DD.MM.YY HH:mm')))]), _c('div', {
    staticClass: "version"
  }, [_vm.status.versions ? _c('span', [_vm._v("version: " + _vm._s(_vm.status.versions.build) + " / " + _vm._s(_vm._f("moment")(_vm.status.versions.datum, 'DD.MM.YY HH:mm')))]) : _c('div', {
    staticClass: "no-version"
  }, [_vm._v("noversion")])]), _vm.restartAction ? _c('div', {
    staticClass: "restart"
  }, [_c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "red",
      "title": "restart"
    },
    on: {
      "click": _vm.restart
    }
  }, [_vm._v("fa-arrows-rotate")])], 1) : _vm._e(), _vm.cleanCacheAction ? _c('div', {
    staticClass: "clean-cache"
  }, [_c(VIcon, {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "blue",
      "title": "clear cache"
    },
    on: {
      "click": _vm.cleanCache
    }
  }, [_vm._v("fa-trash")])], 1) : _vm._e()]) : _c('div', {
    staticClass: "no-status"
  }, [_vm._v("nostatus")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };