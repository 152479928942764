import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('MasterDetailComponent', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c(VCard, {
          attrs: {
            "flat": ""
          }
        }, [_c(VContainer, {
          staticClass: "pt-1 pb-0 px-2",
          attrs: {
            "fluid": ""
          }
        }, [_c('div', {
          staticClass: "d-flex justify-end pb-1"
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "title": "Export",
            "color": "secondary",
            "outlined": ""
          },
          on: {
            "click": _vm.exportUsers
          }
        }, [_c(VIcon, {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("fas fa-file-export")]), _c('span', [_vm._v("Export")])], 1)], 1), _c('ag-grid-vue', {
          staticClass: "user-grid ag-theme-balham",
          attrs: {
            "rowData": _vm.users,
            "columnDefs": _vm.columnDefs,
            "gridOptions": _vm.gridOptions,
            "rowSelection": "single"
          },
          on: {
            "row-selected": _vm.rowSelected,
            "first-data-rendered": _vm.onFirstDataRendered,
            "grid-ready": _vm.onGridReady,
            "model-updated": _vm.onModelUpdated
          }
        }), _vm.users && _vm.allUsers ? _c('div', {
          staticClass: "users-count"
        }, [_c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm.users.length) + " / " + _vm._s(_vm.allUsers.length))])]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedUsers && _vm.selectedUsers.length > 1 ? _c('div', {
          staticClass: "detail-wrapper"
        }, [_c(VCard, {
          staticClass: "user-wrapper"
        }, [_c(VCardTitle, [_c('span', [_vm._v("Multi-Actions")])]), _c(VCardText, [_c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_c('span', [_vm._v("Füge Clients hinzu")])]), _c(VFlex, {
          attrs: {
            "xs6": ""
          }
        }, [_c(VAutocomplete, {
          attrs: {
            "items": _vm.allClients,
            "item-text": "cid",
            "item-value": "cid",
            "chips": "",
            "clearable": "",
            "deletable-chips": "",
            "multiple": "",
            "small-chips": "",
            "eager": ""
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "profile-selection"
              }, [_c('div', {
                staticClass: "profile-name"
              }, [_vm._v(_vm._s(data.item.praxisName))]), _c('div', {
                staticClass: "profile-id"
              }, [_vm._v(_vm._s(data.item.cid))])])];
            }
          }], null, false, 3200130014),
          model: {
            value: _vm.addMultiClient,
            callback: function callback($$v) {
              _vm.addMultiClient = $$v;
            },
            expression: "addMultiClient"
          }
        })], 1), _c(VFlex, {
          attrs: {
            "mr-1": "",
            "xs1": ""
          }
        }, [_c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.addMultiClientIdsToUsers(_vm.selectedUsers, _vm.addMultiClient);
            }
          }
        }, [_vm._v("+")])], 1), _c(VFlex, {
          attrs: {
            "xs1": ""
          }
        }, [_c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "warning",
            "disabled": !_vm.selectedMultiClient
          },
          on: {
            "click": function click($event) {
              return _vm.removeClientIdFromUsers(_vm.selectedUsers, _vm.selectedMultiClient);
            }
          }
        }, [_vm._v("-")])], 1)], 1)], 1)], 1)], 1) : _vm.selectedUser ? _c('div', {
          staticClass: "detail-wrapper"
        }, [_c(VCard, {
          staticClass: "user-wrapper"
        }, [_c(VCardTitle, [_c('span', [_vm._v("Persönliche Informationen")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.saveUser(_vm.selectedUser);
            }
          }
        }, [_vm._v("Speichern")])], 1), _c(VCardText, [_c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Vorname")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "firstName", $$v);
            },
            expression: "selectedUser.firstName"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Nachname")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.lastName,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "lastName", $$v);
            },
            expression: "selectedUser.lastName"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Telefon")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.telephone,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "telephone", $$v);
            },
            expression: "selectedUser.telephone"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("E-Mail")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VLayout, {
          attrs: {
            "nowrap": "",
            "align-center": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "disabled": "",
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.email,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "email", $$v);
            },
            expression: "selectedUser.email"
          }
        }), _c(VBtn, {
          staticClass: "ml-4",
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.changeUserMail(_vm.selectedUser);
            }
          }
        }, [_vm._v("Ändern")])], 1)], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": "",
            "mt-4": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Tags")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VCombobox, {
          attrs: {
            "clearable": "",
            "hide-selected": "",
            "multiple": "",
            "small-chips": "",
            "deletable-chips": "",
            "dense": "",
            "items": _vm.allUserTags,
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.tags,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "tags", $$v);
            },
            expression: "selectedUser.tags"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Haupt-Kunde")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.mainclientid,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "mainclientid", $$v);
            },
            expression: "selectedUser.mainclientid"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Verbotene Kunden")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_vm.selectedUser.permittedclients ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": _vm.selectedUser.permittedclients.join(", "),
            "hide-details": ""
          }
        }) : _vm._e(), !_vm.selectedUser.permittedclients ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": "-",
            "hide-details": ""
          }
        }) : _vm._e()], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Erlaubte Views")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_vm.selectedUser.accessibleViews ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": _vm.selectedUser.accessibleViews.join(", "),
            "hide-details": ""
          }
        }) : _vm._e(), !_vm.selectedUser.accessibleViews ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": "-",
            "hide-details": ""
          }
        }) : _vm._e()], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_c('span', [_vm._v("Client-IDs")]), _vm.selectedUser.clientinfos ? _c('span', [_vm._v("(" + _vm._s(_vm.selectedUser.clientinfos.length) + ")")]) : _vm._e()]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VList, {
          staticClass: "select-list",
          attrs: {
            "dense": ""
          }
        }, [_c(VListItemGroup, {
          model: {
            value: _vm.selectedMultiClient,
            callback: function callback($$v) {
              _vm.selectedMultiClient = $$v;
            },
            expression: "selectedMultiClient"
          }
        }, _vm._l(_vm.selectedUser.clientinfos, function (ci) {
          return _c(VListItem, {
            key: ci.cid,
            attrs: {
              "value": ci.cid
            }
          }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(ci.cid))]), _c(VListItemSubtitle, [_vm._v(_vm._s(ci.namekurz))]), _c(VListItemSubtitle, {
            staticClass: "admin-level"
          }, [_vm._v(_vm._s(_vm.adminLevelString(ci.adminLevel)))])], 1)], 1);
        }), 1)], 1)], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }), _c(VFlex, {
          attrs: {
            "xs6": ""
          }
        }, [_c(VAutocomplete, {
          attrs: {
            "items": _vm.usersMissingClients,
            "item-text": "cid",
            "item-value": "cid",
            "chips": "",
            "clearable": "",
            "deletable-chips": "",
            "multiple": "",
            "small-chips": "",
            "eager": "",
            "hide-details": "",
            "filter": _vm.filterClients
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "profile-selection"
              }, [_c('div', {
                staticClass: "profile-name"
              }, [_vm._v(_vm._s(data.item.praxisName))]), _c('div', {
                staticClass: "profile-id"
              }, [_vm._v(_vm._s(data.item.cid))])])];
            }
          }]),
          model: {
            value: _vm.addMultiClient,
            callback: function callback($$v) {
              _vm.addMultiClient = $$v;
            },
            expression: "addMultiClient"
          }
        })], 1), _c(VFlex, {
          attrs: {
            "mr-1": "",
            "xs1": ""
          }
        }, [_c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.addMultiClientIdsToUsers(_vm.selectedUsers, _vm.addMultiClient);
            }
          }
        }, [_vm._v("+")])], 1), _c(VFlex, {
          attrs: {
            "xs1": ""
          }
        }, [_c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "warning",
            "disabled": !_vm.selectedMultiClient
          },
          on: {
            "click": function click($event) {
              return _vm.removeClientIdFromUsers(_vm.selectedUsers, _vm.selectedMultiClient);
            }
          }
        }, [_vm._v("-")])], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Benutzertyp")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VSelect, {
          attrs: {
            "items": _vm.userAdminLevelOptions
          },
          model: {
            value: _vm.selectedUser.adminLevel,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "adminLevel", $$v);
            },
            expression: "selectedUser.adminLevel"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Sichtbarkeit")]), _c(VFlex, {
          attrs: {
            "xs8": ""
          }
        }, [_c(VCheckbox, {
          attrs: {
            "label": "verbergen",
            "hide-details": ""
          },
          model: {
            value: _vm.selectedUser.hide,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "hide", $$v);
            },
            expression: "selectedUser.hide"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Behandlerzuordnung")]), _c(VFlex, {
          attrs: {
            "mr-9": "",
            "xs8": ""
          }
        }, [_vm.selectedUser.extids ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": _vm.selectedUser.extids.join(", "),
            "hide-details": ""
          }
        }) : _vm._e(), !_vm.selectedUser.extids ? _c(VTextField, {
          attrs: {
            "disabled": "",
            "value": "-",
            "hide-details": ""
          }
        }) : _vm._e()], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs4": ""
          }
        }, [_vm._v("Zwei-Faktor-Authentifizierung")]), _c(VFlex, {
          attrs: {
            "mr-9": "",
            "xs8": ""
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('strong', [_vm._v(_vm._s(_vm.mfaTypeToString(_vm.selectedUser.mfatype)))]), _c('div', {
          staticClass: "mr-4"
        }), _c(VSwitch, {
          attrs: {
            "hide-details": "",
            "label": "2FA Erzwungen",
            "dense": "",
            "small": ""
          },
          model: {
            value: _vm.selectedUser.mfaenforced,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedUser, "mfaenforced", $$v);
            },
            expression: "selectedUser.mfaenforced"
          }
        })], 1)])], 1), _c(VLayout, {
          staticClass: "mt-2",
          attrs: {
            "wrap": "",
            "justify-xs-space-between": ""
          }
        }, [_c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.saveUser(_vm.selectedUser);
            }
          }
        }, [_vm._v("Speichern")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.resetPassword(_vm.selectedUser);
            }
          }
        }, [_vm._v("Passwort zurücksetzen")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.resetMfa(_vm.selectedUser);
            }
          }
        }, [_vm._v("2FA zurücksetzen")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.loginUser(_vm.selectedUser);
            }
          }
        }, [_vm._v("Einloggen")]), _c('div', {
          staticClass: "spacer"
        }), _c('rose-confirm-button', {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "description": "Wollen Sie den User " + _vm.selectedUser.email + " wirklich löschen?",
            "actionText": "Endgültig löschen"
          },
          on: {
            "confirmed": function confirmed($event) {
              return _vm.deleteUser(_vm.selectedUser);
            }
          }
        }, [_vm._v("Löschen")])], 1)], 1)], 1), _c('div', {
          staticClass: "statistics-wrapper"
        }, [_c(VCard, {
          staticClass: "chart-wrapper"
        }, [_c(VCardTitle, [_vm._v("User activity")]), _c(VCardText, [_c('UserActivityChartComponent', {
          attrs: {
            "chartData": _vm.userActivity
          }
        })], 1)], 1), _c(VCard, {
          staticClass: "token-wrapper"
        }, [_c(VCardTitle, [_vm._v("Token")]), _c(VCardText, [_c(VLayout, {
          staticClass: "token-main-actions",
          attrs: {
            "justify-end": ""
          }
        }, [_c('rose-confirm-button', {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "actionText": "Alle Token löschen",
            "description": "Wirklich alle Token löschen?"
          },
          on: {
            "confirmed": function confirmed($event) {
              return _vm.deleteAllTokens(_vm.selectedUser);
            }
          }
        }, [_vm._v("Alle Token löschen")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "x-small": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.createToken(_vm.selectedUser);
            }
          }
        }, [_vm._v("Token erzeugen")])], 1), _c('el-table', {
          attrs: {
            "data": _vm.selectedUser.tokens
          }
        }, [_c('el-table-column', {
          attrs: {
            "label": "Info",
            "property": "device",
            "show-overflow-tooltip": ""
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "ID",
            "width": "180",
            "property": "id",
            "show-overflow-tooltip": ""
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Ausgestellt",
            "property": "issuedAt",
            "formatter": _vm.formatDateTime,
            "width": "180"
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Ungültig",
            "property": "expiresAt",
            "formatter": _vm.formatDateTime,
            "width": "180"
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Letzte Aktivität",
            "property": "lastActivity",
            "formatter": _vm.formatDateTime,
            "width": "180"
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Aktion",
            "width": "180"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('span', [_c(VBtn, {
                attrs: {
                  "x-small": "",
                  "color": "error"
                },
                on: {
                  "click": function click($event) {
                    return _vm.deleteUserToken(scope.row.id);
                  }
                }
              }, [_vm._v("Löschen")])], 1)];
            }
          }])
        })], 1)], 1)], 1)], 1)], 1) : _c('div', {
          staticClass: "detail-wrapper"
        }, [_c('span', [_vm._v("Bitte einen oder mehrere Benutzer auswählen")])]), _c(VDialog, {
          attrs: {
            "scrollable": "",
            "max-width": "800px"
          },
          model: {
            value: _vm.newUserDialog,
            callback: function callback($$v) {
              _vm.newUserDialog = $$v;
            },
            expression: "newUserDialog"
          }
        }, [_c(VCard, [_c(VCardTitle, [_vm._v("Neuen Benutzer anlegen")]), _c(VCardText, [_c('NewUserDialogComponent', {
          attrs: {
            "user": _vm.newUser
          },
          on: {
            "valid": function valid($event) {
              _vm.newUserValid = $event;
            }
          }
        })], 1), _c(VCardActions, [_c(VBtn, {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.closeNewUserDialog(false, true);
            }
          }
        }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
          attrs: {
            "color": "primary",
            "disabled": !_vm.newUserValid
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.closeNewUserDialog(true, false);
            }
          }
        }, [_vm._v("Anlegen und schließen")]), _c(VBtn, {
          attrs: {
            "color": "primary",
            "disabled": !_vm.newUserValid
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.closeNewUserDialog(true, true);
            }
          }
        }, [_vm._v("Anlegen")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };