import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { defineComponent } from 'vue';
import { iosVersion } from '@rose/common-ui';
import _ from 'lodash';
import { columnCellRendererComponents } from '@/components/columns/cellComponents';
import { subscriptionInvoiceColumns } from '@/components/columns/subscriptionInvoice';
import { globalSearchStore } from '@/state/globalSearch.state';
export default defineComponent({
  name: 'SubscriptionsInvoiceGrid',
  components: Object.assign({}, columnCellRendererComponents),
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    invoices: {
      type: Array,
      required: true
    },
    gridFilters: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      columnDefs: subscriptionInvoiceColumns(!!iosVersion),
      gridOptions: {},
      gridApi: {},
      selectedRow: null
    };
  },
  computed: {
    filteredInvoices: function filteredInvoices() {
      var _this = this;
      if (!this.invoices) {
        return null;
      }
      var invoices = this.invoices;
      var searchInput = globalSearchStore.searchInput;
      if (searchInput) {
        var rgx = new RegExp(searchInput, 'i');
        invoices = _.filter(invoices, function (s) {
          return rgx.test(s.customer);
        });
      }
      if (this.gridFilters.feature) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invoices = _.filter(invoices, function (s) {
          return s.feature === _this.gridFilters.feature;
        });
      }
      return invoices;
    },
    featureSelected: function featureSelected() {
      return this.gridFilters.feature;
    },
    statusSelected: function statusSelected() {
      return this.gridFilters.status;
    }
  },
  watch: {
    isLoading: function isLoading() {
      if (_.isEmpty(this.gridApi)) return;
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true,
        sortable: true
      },
      rowSelection: {
        mode: 'singleRow'
      },
      suppressCellFocus: true
    };
  },
  methods: {
    getRowId: function getRowId(params) {
      return params.data.id;
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
    },
    onCellClicked: function onCellClicked(event) {
      console.log('cell clicked', event);
    },
    onSelectionChange: function onSelectionChange() {
      this.$emit('selection-change', _.first(this.gridApi.getSelectedRows()));
    },
    onRowCountChanged: function onRowCountChanged() {
      var _a;
      this.$emit('row-count-changed', (_a = this.filteredInvoices) === null || _a === void 0 ? void 0 : _a.length);
    }
  }
});