import { saveAs } from 'file-saver';
import { getXLSXFromBuffer } from '../../../base';
import * as ExcelJS from 'exceljs';

export function saveXLSX(workbook: any, name: string) {
  workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, name);
  });
}

export async function saveXLSXFromBase64(base64: string, name: string) {
  const xlsx = await getXLSXFromBuffer(createWorkBook(), atob(base64));
  saveXLSX(xlsx, name);
}

export function createWorkBook() {
  return new ExcelJS.Workbook();
}
