import _slicedToArray from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.pad-start.js";
import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      date: '',
      dateFormatted: '',
      menu1: false
    };
  },
  watch: {
    date: function date(val) {
      this.dateFormatted = this.formatDateTime(this.date);
    }
  },
  methods: {
    formatDateTime: function formatDateTime(date) {
      if (!date) {
        return null;
      }
      var _date$split = date.split('-'),
        _date$split2 = _slicedToArray(_date$split, 3),
        year = _date$split2[0],
        month = _date$split2[1],
        day = _date$split2[2];
      return "".concat(day, ".").concat(month, ".").concat(year);
    },
    parseDate: function parseDate(date) {
      if (!date) {
        return null;
      }
      var _date$split3 = date.split('.'),
        _date$split4 = _slicedToArray(_date$split3, 3),
        day = _date$split4[0],
        month = _date$split4[1],
        year = _date$split4[2];
      return "".concat(year, "-").concat(month.padStart(2, '0'), "-").concat(day.padStart(2, '0'));
    }
  }
});