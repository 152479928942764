import { RoseApi } from '../apiTypes';
import { QUERYSTRINGS } from '../../../../types';

export function createCollectorTriggerApi(roseApi: RoseApi) {
  return {
    executeAutoBehandlerZuordnung: (fake: boolean, reset: boolean) =>
      roseApi.collector.get<any>(`/api/trigger/behandlerautoconfig/${fake}/${reset}`),
    abrechnung: (notify = false) => {
      const query = {
        [QUERYSTRINGS.NONOTIFY]: notify ? undefined : 1,
      };
      return roseApi.collector.get<void>(`/api/trigger/abrechnungsautomat`, { query });
    },
    heuteUpdate: (notify = false) => {
      const query = {
        [QUERYSTRINGS.NONOTIFY]: notify ? undefined : 1,
      };
      return roseApi.collector.get<void>(`/api/trigger/heute`, { query });
    },
    terminArtUpdate: () => roseApi.collector.get<void>(`/api/trigger/terminartupdate`),
    transformRohdaten: (useImportTables: boolean, notify = false) => {
      const query = {
        [QUERYSTRINGS.NONOTIFY]: notify ? undefined : 1,
      };
      return roseApi.collector.get<void>(`/api/trigger/transformrohdaten/${useImportTables}`, { query });
    },
  };
}
