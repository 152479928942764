import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PvsCell',
  data: function data() {
    return {
      params: null,
      pvs: null
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a;
      this.pvs = (_a = this.params) === null || _a === void 0 ? void 0 : _a.value;
    }
  }
});