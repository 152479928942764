/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Sponsoring.vue?vue&type=template&id=5ce8aac4&scoped=true&lang=pug"
import script from "./Sponsoring.vue?vue&type=script&lang=ts"
export * from "./Sponsoring.vue?vue&type=script&lang=ts"
import style0 from "./Sponsoring.vue?vue&type=style&index=0&id=5ce8aac4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce8aac4",
  null
  
)

export default component.exports