import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bool-icon"
  }, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "depressed": "",
      "color": _vm.isTrue ? "orange" : "green",
      "dark": ""
    },
    on: {
      "click": function click($event) {
        _vm.isTrue = !_vm.isTrue;
      }
    }
  }, [_vm.isTrue === true ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kostenfrei")]) : _vm.isTrue === false ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Zahlt")]) : _c('span', [_vm._v("-")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };