var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "labelValue"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "value"
  }, [_vm.value ? _c('span', [_vm._v(_vm._s(_vm.value))]) : _vm._e(), _vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };