import VueRouter from 'vue-router';
import { loadEventTargetPolyFill } from '../polyfills/eventtarget';
import { IViewStateProvider } from './v-model-bind-view-state';

export function createViewStateProviderFromRouter(router: VueRouter): IViewStateProvider {
  loadEventTargetPolyFill();

  // removes null entries
  function cleanQueryValue(viewStateEntry: string | (string | null)[]) {
    let cleaned: string | string[];
    if (Array.isArray(viewStateEntry)) {
      cleaned = viewStateEntry.filter(entry => entry !== null) as string[];
    } else {
      cleaned = viewStateEntry;
    }
    return cleaned;
  }

  const viewStateEventTarget = new EventTarget();
  router.afterEach((to, from) => {
    const query = to.query;
    Object.keys(query).forEach(key => viewStateEventTarget.dispatchEvent(new CustomEvent(key, { detail: query[key] })));
  });

  return {
    getViewStateKey(key: string) {
      const viewStateEntry = router.currentRoute.query[key];
      return cleanQueryValue(viewStateEntry);
    },

    setViewStateKey(key: string, value: string | string[], history) {
      const query = { ...router.currentRoute.query, [key]: value };
      if (history) {
        router.push({ query });
      } else {
        router.replace({ query });
      }
    },

    subscribeToViewStateKey(key: string, handler: (value: string | string[]) => void): CallableFunction {
      const internalHandler = (e: any) => handler(e.detail);
      viewStateEventTarget.addEventListener(key, internalHandler);
      return () => viewStateEventTarget.removeEventListener(key, internalHandler);
    },
  };
}
