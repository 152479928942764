import { defineComponent } from 'vue';
import moment from 'moment';
export default defineComponent({
  name: 'DateSelectModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      "default": 'Datum wählen'
    },
    preSelectedDate: {
      type: String,
      required: false,
      "default": moment().format('YYYY-MM-DD')
    }
  },
  data: function data() {
    return {
      showPicker: false,
      date: ''
    };
  },
  computed: {
    showModal: {
      get: function get() {
        console.log('get', this.value);
        return this.value;
      },
      set: function set(value) {
        console.log('set', value);
        this.$emit('input', value);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.date = this.preSelectedDate;
  },
  methods: {
    acceptDate: function acceptDate() {
      this.$emit('accept', this.date);
      this.showModal = false;
    }
  }
});