import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mailer-container"
  }, [_c(VCard, {
    staticClass: "mailer-card"
  }, [_c(VCardTitle, {
    staticClass: "empfanger-title"
  }, [_vm._v("Empfänger:")]), _c(VCardText, {
    staticClass: "mailer-filter"
  }, [_c(VTextField, {
    attrs: {
      "label": "filter"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c(VBtnToggle, {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.recipientType,
      callback: function callback($$v) {
        _vm.recipientType = $$v;
      },
      expression: "recipientType"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": "alle"
    },
    on: {
      "click": function click($event) {
        return _vm.selectAllUsers();
      }
    }
  }, [_vm._v("Alle")]), _c(VBtn, {
    attrs: {
      "value": "metrics"
    },
    on: {
      "click": function click($event) {
        return _vm.selectMetricsUsers();
      }
    }
  }, [_vm._v("Metrics")]), _c(VBtn, {
    attrs: {
      "value": "r4c"
    },
    on: {
      "click": function click($event) {
        return _vm.selectR4cUsers();
      }
    }
  }, [_vm._v("r4c")])], 1), _c(VBtn, {
    staticClass: "ma-1",
    attrs: {
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.unselectUsers();
      }
    }
  }, [_c(VIcon, [_vm._v("fa-trash")])], 1)], 1), _c('div', {
    staticClass: "users"
  }, [!_vm.users ? _c('p', [_vm._v("Loading...")]) : _c(VList, _vm._l(_vm.users, function (user) {
    return _c(VListItem, {
      key: user.email
    }, [_c(VListItemAction, [_c(VCheckbox, {
      model: {
        value: user.selected,
        callback: function callback($$v) {
          _vm.$set(user, "selected", $$v);
        },
        expression: "user.selected"
      }
    })], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(user.firstName + ' ' + user.lastName))]), _c(VListItemSubtitle, [_vm._v(_vm._s(user.email))]), _c(VListItemSubtitle, [_vm._v(_vm._s(user.mainPraxisKurzName) + " (" + _vm._s(user.mainclientid) + ")")])], 1)], 1);
  }), 1)], 1), _c('span', {
    staticClass: "empfanger"
  }, [_vm._v(_vm._s(_vm.selected.length || 0) + " Empfänger")]), _c(VCardTitle, {
    staticClass: "betreff-title"
  }, [_vm._v("Betreff:")]), _c(VCardText, {
    staticClass: "mailer-title"
  }, [_c(VTextField, {
    attrs: {
      "label": "betreff",
      "required": ""
    },
    model: {
      value: _vm.subject,
      callback: function callback($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1), _c('span', {
    staticClass: "text-title"
  }, [_vm._v("Text:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mailBody,
      expression: "mailBody"
    }],
    staticClass: "text-content",
    domProps: {
      "value": _vm.mailBody
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.mailBody = $event.target.value;
      }
    }
  }), _c(VAlert, {
    staticClass: "info",
    attrs: {
      "type": "info"
    }
  }, [_c('div', [_vm._v("Text wird als markdown interpretiert. In Betreff und Text können folgende Platzhalter verwendet werden: " + _vm._s('email') + ", " + _vm._s('name') + ", " + _vm._s('vorname') + ", " + _vm._s('cid') + ", " + _vm._s('praxisname'))])]), _c(VBtn, {
    staticClass: "btn-senden",
    attrs: {
      "color": "primary",
      "disabled": !_vm.mailBody || !_vm.subject || !(_vm.selected && _vm.selected.length)
    },
    on: {
      "click": function click($event) {
        return _vm.sendEmails();
      }
    }
  }, [_vm._v("Senden"), _c('ResponseDialogComponent', {
    attrs: {
      "data": _vm.responseDialogData,
      "eventTrigger": _vm.eventTrigger,
      "status": _vm.responseDialogStatus,
      "path": _vm.path
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };