import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ca-licences"
  }, [_c('div', {
    staticClass: "licence-header"
  }, [_c(VBtn, {
    attrs: {
      "small": "",
      "outlined": ""
    },
    on: {
      "click": _vm.newLicence
    }
  }, [_c('span', [_vm._v("Lizenz anlegen")]), _c(VIcon, {
    attrs: {
      "right": ""
    }
  }, [_vm._v("add")])], 1), _c('CreateCaLicence', {
    ref: "createLicence",
    on: {
      "created": _vm.reloadAction
    }
  })], 1), _c('div', {
    staticClass: "licence-table"
  }, [_c('rose-master-detail', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c(VCard, {
          attrs: {
            "outlined": ""
          }
        }, [_c('rose-master-table', {
          attrs: {
            "data": _vm.licences,
            "idKey": "serialnumber",
            "default-sort": {
              prop: "serialnumber",
              order: "ascending"
            },
            "height": "350",
            "URL_KEY_NAME": "selectedLicence"
          },
          on: {
            "selectionChange": _vm.selectionChangeLicence
          },
          scopedSlots: _vm._u([{
            key: "columns",
            fn: function fn() {
              return [_c('el-table-column', {
                attrs: {
                  "property": "serialnumber",
                  "label": "Seriennummer",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.serialnumber
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "tenant",
                  "label": "Mandant",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.tenant
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "status",
                  "label": "Status",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.status
                      }
                    })];
                  }
                }])
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedLicence ? _c(VCard, [_c(VCardTitle, [_c('span', [_vm._v("Licence Details")])]), _c(VCardText, [_c(VForm, [_c('CaLicenceFields', {
          attrs: {
            "licence": _vm.selectedLicence
          }
        })], 1)], 1), _c(VCardActions, [_c('rose-confirm-button', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "description": "Lizenz \"".concat(_vm.selectedLicence.serialnumber, "\" wirklich L\xF6schen?")
          },
          on: {
            "confirmed": _vm.deleteLicence
          }
        }, [_vm._v("Lizenz löschen")]), _c(VBtn, {
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": _vm.saveLicence
          }
        }, [_vm._v("Speichern")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };