/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./LinkCellComponent.vue?vue&type=template&id=5d210eb1&scoped=true&lang=pug"
import script from "./LinkCellComponent.vue?vue&type=script&lang=ts"
export * from "./LinkCellComponent.vue?vue&type=script&lang=ts"
import style0 from "./LinkCellComponent.vue?vue&type=style&index=0&id=5d210eb1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d210eb1",
  null
  
)

export default component.exports