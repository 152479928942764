import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex py-2"
  }, [_c(VChip, {
    attrs: {
      "color": _vm.aboStatus === "good" ? "success" : "error",
      "x-small": "",
      "label": "",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.aboStatus === 'good' ? 'GOOD' : 'BAD'))]), _vm.reason ? _c('span', {
    staticClass: "pl-1"
  }, [_vm._v(_vm._s(_vm.reason))]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };