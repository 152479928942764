import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "formularEditorWrap"
  }, [_c(VCard, {
    staticClass: "editor-list"
  }, [!_vm.isDev ? _c(VCard, {
    staticClass: "warning pa-2"
  }, [_c('h3', [_vm._v("Formulare sollten nur in Dev geändert werden.")])]) : _vm._e(), _c(VCardTitle, [_vm._v("Factoring Formulare")]), _vm._l(_vm.rechenzentren, function (rz) {
    return [_c('div', {
      staticClass: "list-sub-header"
    }, [_vm._v(_vm._s(rz))]), _vm.formularConfig[rz].positionen ? _c('div', {
      staticClass: "list-item text-left",
      "class": {
        active: _vm.formularConfig[rz] === _vm.selectedConfigEntry
      },
      on: {
        "click": function click($event) {
          return _vm.selectConfigEntry(_vm.formularConfig[rz], rz);
        }
      }
    }, [_vm._v(_vm._s(rz) + " root")]) : _vm._e(), _vm._l(_vm.formularConfig[rz].subConfigs, function (subConfig) {
      return [_c('div', {
        staticClass: "list-item text-right",
        "class": {
          active: subConfig === _vm.selectedConfigEntry
        },
        on: {
          "click": function click($event) {
            return _vm.selectConfigEntry(subConfig, rz, _vm.formularConfig[rz]);
          }
        }
      }, [_vm._v(_vm._s(subConfig.name))])];
    })];
  })], 2), _c('div', {
    ref: "editorContainer",
    staticClass: "editor"
  }, [_c(VCard, {
    staticClass: "canvasWrap"
  }, [_c('v-stage', {
    ref: "stage",
    attrs: {
      "config": _vm.stageSize
    },
    on: {
      "mousedown": _vm.handleStageMouseDown,
      "touchstart": _vm.handleStageMouseDown
    }
  }, [_c('v-layer', {
    ref: "layer"
  }, [_c('v-image', {
    attrs: {
      "config": _vm.imageConfig
    }
  }), _vm._l(_vm.rectangles, function (item) {
    return _c('v-rect', {
      key: item.id,
      attrs: {
        "config": item
      },
      on: {
        "transformend": _vm.handleTransformEnd,
        "dragstart": _vm.handleDragStart,
        "dragend": _vm.handleDragEnd,
        "mouseenter": _vm.cursorPointer,
        "mouseleave": _vm.cursorNormal
      }
    });
  }), _c('v-transformer', {
    ref: "transformer"
  })], 2)], 1)], 1)], 1), _c('div', {
    staticClass: "sideBar"
  }, [_vm.selectedConfigEntry ? _c(VCard, {
    staticClass: "configEntry pl-2"
  }, [_c('div', {
    staticClass: "pa-1"
  }, [_c('rose-key-value-display', {
    attrs: {
      "label": "ID"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.formularId))])]), _vm.selectedConfigProperties.date ? _c('rose-key-value-display', {
    attrs: {
      "label": "Datum"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedConfigProperties.date.value))]), _vm.selectedConfigProperties.date.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.date.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e(), _vm.selectedConfigProperties.pdfPageNum ? _c('rose-key-value-display', {
    attrs: {
      "label": "PDF Seite"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedConfigProperties.pdfPageNum.value + 1) + ".")]), _vm.selectedConfigProperties.pdfPageNum.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.pdfPageNum.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e(), _vm.selectedConfigProperties.gueltigvon ? _c('rose-key-value-display', {
    attrs: {
      "label": "Gültig von"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedConfigProperties.gueltigvon.value))]), _vm.selectedConfigProperties.gueltigvon.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.gueltigvon.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e(), _vm.selectedConfigProperties.gueltigbis ? _c('rose-key-value-display', {
    attrs: {
      "label": "Gültig bis"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedConfigProperties.gueltigbis.value))]), _vm.selectedConfigProperties.gueltigbis.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.gueltigbis.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e(), _vm.selectedConfigProperties.deactivated ? _c('rose-key-value-display', {
    attrs: {
      "label": "Deaktiviert"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedConfigProperties.deactivated.value))]), _vm.selectedConfigProperties.deactivated.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.deactivated.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e(), _vm.selectedConfigProperties.langs ? _c('rose-key-value-display', {
    attrs: {
      "label": "Sprachen"
    }
  }, [_c('div', {
    staticClass: "langSelectWrap"
  }, _vm._l(_vm.selectedConfigProperties.langs.value, function (lang) {
    return _c('span', {
      staticClass: "langSelect",
      "class": {
        active: _vm.selectedLang === lang
      },
      on: {
        "click": function click($event) {
          return _vm.selectConfigEntryLang(lang);
        }
      }
    }, [_vm._v(_vm._s(lang))]);
  }), 0), _vm.selectedConfigProperties.langs.inherited ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "geerbt"
    }
  }, [_vm._v("P")]) : _vm._e(), _vm.selectedConfigProperties.langs.overriden ? _c('span', {
    staticClass: "inherit-marker",
    attrs: {
      "title": "überschrieben"
    }
  }, [_vm._v("O")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "actionButtons pa-2"
  }, [_c('div', {
    staticClass: "editorActions mb-2"
  }, [_c(VBtn, {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.resetZoom
    }
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("crop_free")]), _c('span', [_vm._v("Reset Zoom")])], 1), _c(VBtn, {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.loadPreview
    }
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('span', [_vm._v("Preview")])], 1), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.savePositionen
    }
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("save")]), _c('span', [_vm._v("Speichern")])], 1)], 1)])]) : _vm._e(), _vm.rectangles.length || _vm.currentOffset ? _c(VCard, {
    staticClass: "mt-4 editor-list positionenList"
  }, [_c(VCardTitle, [_vm._v("Positionen")]), _vm.currentOffset ? [_c('div', {
    staticClass: "list-item offsetConfig",
    "class": {
      inherited: _vm.currentOffsetInherited
    }
  }, [_c('span', {
    staticClass: "text-left"
  }, [_vm._v("Offset")]), _c('div', {
    staticClass: "offsetInputs"
  }, [_c('label', [_vm._v("x")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentOffset.x,
      expression: "currentOffset.x"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.currentOffset.x
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentOffset, "x", $event.target.value);
      }, _vm.updateOffset]
    }
  }), _c('label', [_vm._v("y")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentOffset.y,
      expression: "currentOffset.y"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.currentOffset.y
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentOffset, "y", $event.target.value);
      }, _vm.updateOffset]
    }
  })]), _vm.currentOffsetInherited ? _c('span', [_vm._v("P")]) : _vm._e()])] : _vm._e(), _vm._l(_vm.positionGroups, function (group) {
    return [_c('div', {
      staticClass: "list-sub-header"
    }, [_c('span', [_vm._v(_vm._s(group) + "/")]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c(VIcon, _vm._g(_vm._b({
            staticClass: "float-right ml-2 mt-1 mr-2",
            attrs: {
              "small": "",
              "color": "white"
            },
            on: {
              "click": function click($event) {
                return _vm.addNewPositionField(group);
              }
            }
          }, 'v-icon', attrs, false), on), [_vm._v("add")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Neues Feld")])])], 1), _vm._l(_vm.positionsByGroup(group), function (rect) {
      return _c('div', {
        staticClass: "list-item",
        "class": {
          active: _vm.selectedShapeName === rect.name,
          inherited: rect.isParent
        },
        on: {
          "click": function click($event) {
            return _vm.selectShape(rect);
          }
        }
      }, [_c('span', {
        staticClass: "text-left"
      }, [_vm._v(_vm._s(rect.displayName))]), rect.isParent ? _c('span', {
        staticClass: "float-right ml-2"
      }, [_vm._v("P")]) : _vm._e(), _c(VTooltip, {
        attrs: {
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref2) {
            var on = _ref2.on,
              attrs = _ref2.attrs;
            return [_c(VIcon, _vm._g(_vm._b({
              staticClass: "float-right ml-2 mt-1",
              attrs: {
                "small": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.resetPosition(rect);
                }
              }
            }, 'v-icon', attrs, false), on), [_vm._v("cached")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v("Reset")])]), _c('span', {
        staticClass: "float-right ml-2"
      }, [_vm._v("(" + _vm._s(rect.x.toFixed(0)) + "/" + _vm._s(rect.y.toFixed(0)) + ")")])], 1);
    }), _vm._l(_vm.missingPositionsByGroup(group), function (prop) {
      return _c('div', {
        staticClass: "list-item missingPosition",
        on: {
          "click": function click($event) {
            return _vm.addPositionProperty(group, prop);
          }
        }
      }, [_c('span', {
        staticClass: "text-left"
      }, [_vm._v("Nicht gesetzt: " + _vm._s(prop))]), _c(VTooltip, {
        attrs: {
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref3) {
            var on = _ref3.on,
              attrs = _ref3.attrs;
            return [_c(VIcon, _vm._g(_vm._b({
              staticClass: "float-right ml-2 mt-1",
              attrs: {
                "small": ""
              }
            }, 'v-icon', attrs, false), on), [_vm._v("add")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v("Hinzufügen")])])], 1);
    })];
  }), _vm._l(_vm.missingGroups(), function (group) {
    return [_c('div', {
      staticClass: "list-sub-header"
    }, [_vm._v(_vm._s(group) + "/")]), _vm._l(_vm.missingPositionsByGroup(group), function (prop) {
      return _c('div', {
        staticClass: "list-item missingPosition",
        on: {
          "click": function click($event) {
            return _vm.addPositionProperty(group, prop);
          }
        }
      }, [_c('span', {
        staticClass: "text-left"
      }, [_vm._v("Nicht gesetzt: " + _vm._s(prop))])]);
    })];
  }), _c('div', {
    staticClass: "list-sub-header click",
    on: {
      "click": function click($event) {
        return _vm.addNewPositionGroup();
      }
    }
  }, [_c('span', [_vm._v("Neue gruppe")]), _c(VIcon, {
    staticClass: "ml-1 mr-2",
    attrs: {
      "small": "",
      "color": "white"
    }
  }, [_vm._v("add")])], 1)], 2) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };