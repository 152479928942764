import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VContainer, {
    staticClass: "import-error-groups",
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, {
    staticClass: "group-row",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.errorGroups, function (clients, error, index) {
    return _c(VCol, {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c(VCard, {
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [_c(VCardTitle, {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(error))]), _c(VCardSubtitle, [_vm._v("Clients Affected: " + _vm._s(Object.keys(clients).length))]), _c(VCardText, [_c('div', {
      staticStyle: {
        "max-height": "150px",
        "overflow-y": "auto"
      }
    }, [_c('ul', _vm._l(clients, function (clientErrors, clientId) {
      return _c('li', {
        key: clientId
      }, [_c('span', [_vm._v(_vm._s(clientId))]), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("#")]), _c('span', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(clientErrors.length))])]);
    }), 0)])]), _c(VCardActions, [_c(VBtn, {
      attrs: {
        "text": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.showDetails(error, clients);
        }
      }
    }, [_c('span', [_vm._v("View Details")])])], 1)], 1)], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };