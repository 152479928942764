import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "lizenzen"
  }, [_c(VCard, {
    staticClass: "lizenz-table",
    attrs: {
      "color": "grey lighten-4",
      "flat": ""
    }
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_vm.lizenzen && _vm.lizenzen.length ? _c('ag-grid-vue', {
    staticClass: "ag-theme-balham",
    staticStyle: {
      "height": "150px"
    },
    attrs: {
      "columnDefs": _vm.columns,
      "rowData": _vm.lizenzen,
      "getRowClass": _vm.getRowClass,
      "grid-options": _vm.gridOptions
    }
  }) : _c('span', [_vm._v("Keine Lizenz-Info")]), _c('div', {
    staticClass: "lizenz-new-actions d-flex align-center justify-space-between pt-2"
  }, [_c(VAutocomplete, {
    staticClass: "pr-6",
    attrs: {
      "items": _vm.allFeatures,
      "dense": "",
      "hide-details": "",
      "placeholder": "Lizenz",
      "clearable": false
    },
    model: {
      value: _vm.newLicenceKey,
      callback: function callback($$v) {
        _vm.newLicenceKey = $$v;
      },
      expression: "newLicenceKey"
    }
  }), _c(VBtn, {
    attrs: {
      "small": "",
      "color": "info",
      "depressed": "",
      "disabled": !_vm.newLicenceKey
    },
    on: {
      "click": _vm.showAddLicenceDialog
    }
  }, [_c('span', [_vm._v("Lizenz erstellen")]), _c(VIcon, {
    staticClass: "pl-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-plus")])], 1)], 1)], 1)], 1), _c('DateSelectModal', {
    attrs: {
      "pre-selected-date": _vm.cancelLicenceDate,
      "title": "Lizenz deaktivieren"
    },
    on: {
      "accept": function accept($event) {
        return _vm.cancelLizenz($event, {
          row: _vm.selectedLicense
        });
      }
    },
    model: {
      value: _vm.showCancelLicenceModal,
      callback: function callback($$v) {
        _vm.showCancelLicenceModal = $$v;
      },
      expression: "showCancelLicenceModal"
    }
  }), _c('DateSelectModal', {
    attrs: {
      "pre-selected-date": _vm.newLicenceStart,
      "title": "Lizenz erstellen (".concat(_vm.newLicenceKey, ")")
    },
    on: {
      "accept": function accept($event) {
        return _vm.createLizenz(_vm.client, _vm.newLicenceKey, $event);
      }
    },
    model: {
      value: _vm.showAddLicenceModal,
      callback: function callback($$v) {
        _vm.showAddLicenceModal = $$v;
      },
      expression: "showAddLicenceModal"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };