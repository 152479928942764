import { loginStore } from '@/state/login.state';
import moment from 'moment';
import {
  AuthRequestStateGetter,
  createCollectorApi,
  createMetricsApi,
  createR4cApi,
  createRoseApiWithAxios,
} from '../../../common-ui';
import {
  IAdminDashBoardItem,
  ICollectionStatistics,
  IFactoringEWEMiniStats,
  IOnboardingDaten,
  IOnboardingEntryExtended,
  IProfileExtended,
  IRoseClientData,
  ISocketsMeta,
  IUIUser,
  MultiClientLoginType,
  IAdminUserInfoExtended,
  IR4cStats,
  IAllImportInfo,
  IClientsImportInfos,
  IBotExtended,
  IProfile,
  FEATURES,
  IProfileWithLocation,
  IClientImportErrors,
  IProfileLocationSearchResult,
  IWorkerStatus,
  INewUser,
  IRequestResult,
  IActionResult,
  IAuthUser,
  ISubscriptionOverview,
  IRoseSubscription,
  IRoseInvoice,
} from '../../../types';
import { createDoctosyncApi } from '@rose/common-ui/src/api/doctosyncApi';

const authRequestStateGetter: AuthRequestStateGetter = () => ({
  authToken: loginStore.user?.token,
  cid: loginStore.user?.cid,
});

const {
  roseApi,
  backends: { metricsBackend, collectorBackend },
} = createRoseApiWithAxios(authRequestStateGetter);

function responseSuccessInterceptor(response: any) {
  return response;
}

const UNAUTHORIZED_STATUS_CODE = 401;

async function responseErrorInterceptor(error: any) {
  // if user is not authorized
  if (error.response && error.response.status === UNAUTHORIZED_STATUS_CODE) {
    // log user out
    loginStore.logout();
  }

  return Promise.reject(error);
}

metricsBackend.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
collectorBackend.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export const metricsApi = createMetricsApi(roseApi);
export const metricsAdminApi = metricsApi.admin;
export const stripeApi = metricsApi.stripe;
export const profileApi = metricsApi.profile;
export const sponsoringApi = metricsApi.sponsoring;
export const caLicenceApi = metricsApi.calicences;
export const r2csvlicences = metricsApi.r2csvlicences;

export const r4cServerApi = createR4cApi(roseApi);
export const r4cAdminApi = r4cServerApi.admin;

export const collectorApi = createCollectorApi(roseApi);
export const triggerApi = collectorApi.trigger;

export const doctosyncApi = createDoctosyncApi(roseApi);

// **************
// NOT yet converted routes
// **************

export interface INewUserExtended extends INewUser {
  cid: string;
}

// status
// status
export const createNewProblemEvent = (event: any) => roseApi.metrics.post('uistatus/createNewProblemEvent', { event });
export const editProblemEvent = (event: any) => roseApi.metrics.put('uistatus/editProblemEvent', { event });
export const deleteNewProblemEvent = (eventId: string) =>
  roseApi.metrics.delete('uistatus/deleteProblemEvent', { eventId });
export const resolveNewProblemEvent = (eventId: string) =>
  roseApi.metrics.post('uistatus/resolveProblemEvent', { eventId });
export const getProblemEvents = () => roseApi.metrics.get<any>('uistatus/problemEvents');
export const switchFactoringPrefetcher = (r4chost: string, on: boolean) =>
  roseApi.r4c.put(r4chost, `admin/prefetcher/${on}`);
export const getR4cFactoringPrefetcherStatus = (r4chost: string) =>
  roseApi.r4c.get<boolean>(r4chost, `admin/prefetcher/`);
export const getUiAlertStatus = () => roseApi.metrics.get<any>('uistatus');
export const setUiAlertStatus = (text: string) =>
  roseApi.metrics.post('uistatus', { id: 'metrics', message: text, status: 'NOK' });
export const deleteUiAlertStatus = () => roseApi.metrics.delete('uistatus', { id: 'metrics' });
export const restartMetrics = () => roseApi.metrics.get('admin/exit');
export const cleanCacheMetrics = () => roseApi.metrics.delete('admin/caches');
export const restartR4c = (r4chost: string) => roseApi.r4c.get(r4chost, 'admin/exit');
export const cleanCacheR4c = (r4chost: string) => roseApi.r4c.delete(r4chost, 'admin/cache');
export const restartCollector = () => roseApi.collector.get('admin/exit');
export const cleanCacheCollector = () => roseApi.collector.delete('admin/caches');

// dashboard
// dashboard
export const getImportStatus = () =>
  roseApi.collector.get<IRequestResult<{ incremental: boolean; full: boolean }>>('api/statusimport');
export const pauseImport = () => roseApi.collector.get('api/pauseincimport');
export const resumeImport = () => roseApi.collector.get('api/resumeincimport');

export const loadCollectorStatus = () => roseApi.collector.get('status');
export const loadSaverStatus = () => roseApi.saver.get('status');
export const loadMetricsStatus = () => roseApi.metrics.get(`status`);
export const loadR4cStatus = (r4chost: string) => roseApi.r4c.get(r4chost, `status`);

export const loadDashboard = () => roseApi.metrics.get<IAdminDashBoardItem[]>('admin/dashboard');
export const loadUser = (email: string) => roseApi.metrics.get<IUIUser>(`admin/user/${email}`);
export const loadUsers = () => roseApi.metrics.get<IUIUser[]>('admin/allusers');
export const loadAdminUserInfos = () => roseApi.metrics.get<IAdminUserInfoExtended[]>('admin/allusersinfo');
export const loadBots = () => roseApi.metrics.get<IBotExtended[]>('admin/bots');
export const loadClients = () => roseApi.metrics.get<IProfileExtended[]>('admin/clients');
export const loadProfiles = () => roseApi.metrics.get<IProfile[]>('api/profiles');
export const loadSubscriptionOverview = () =>
  roseApi.metrics.get<ISubscriptionOverview[]>('admin/subscriptionOverview');
export const loadSubscriptions = () => roseApi.metrics.get<IRoseSubscription[]>('admin/subscriptions');
export const loadInvoices = () => roseApi.metrics.get<IRoseInvoice[]>('admin/invoices');
export const loadConnectedSockets = (r4chost: string) =>
  roseApi.r4c.get<ISocketsMeta>(r4chost, 'admin/connectedsockets');
export const loadConnectedBots = () => roseApi.collector.get('api/bots');
export const runFullImportSchedule = () => roseApi.collector.get('api/runfullimportschedule');

export const loadR4cFactoringBackendStatus = (r4chost: string) => roseApi.r4c.get(r4chost, `admin/status`);
export const loadEweStats = () => roseApi.r4cDispatch.get<IFactoringEWEMiniStats[]>('admin/ewestats');
export const loadR4cStats = () => roseApi.r4cDispatch.get<IR4cStats>('admin/r4cstats');
export const updateFactoringBackendStatus = (r4chost: string, backend: string, enabled: boolean) =>
  roseApi.r4c.put<any>(r4chost, `admin/status/${backend}/${enabled}`);

// user
// user
export const deleteUserToken = metricsApi.auth.deleteToken;
export const resetUserPassword = metricsApi.auth.resetPassword;
export const deleteUser = metricsApi.admin.deleteUserCompletely;

export const createNewUser = (newUser: Partial<INewUserExtended>) => roseApi.metrics.post(`/auth/user`, newUser);
export const createUserToken = (userEmail: string) =>
  roseApi.metrics.get<{ token: string }>(`/admin/token/${userEmail}?expiresInHours=1`);
export const changeUserMail = (cid: string, userMail: string, newUserMail: string) =>
  roseApi.metrics.post(`/auth/changeusermail`, { userMail, newUserMail }, { clientIdHeader: cid });
export const addMultiClientIdsToUsers = (userMails: string[], clientIds: string[]) =>
  roseApi.metrics.post<IActionResult<IAuthUser>[]>(`/admin/addclientidstousers`, { userMails, clientIds });
export const removeClientidFromUsers = (userMails: string[], cid: string) =>
  roseApi.metrics.post<IActionResult<IAuthUser>[]>(`/admin/removeclientidfromusers`, { userMails, cid });

export const getUserActivity = (userEmail: string) => roseApi.metrics.get(`/admin/useractivity/${userEmail}`);

export const updateUser = (updatedUser: any) => roseApi.metrics.put(`/auth/user`, updatedUser);

// client
// client
export const setLastChangeTimestamp = (cid: string, timestamp: number | string) =>
  roseApi.metrics.put(`/admin/setlastchange/${timestamp}`, { clientIdHeader: cid });

export const changeClientId = (cid: string, newClientId: string, raw = false) =>
  roseApi.metrics.post(`/admin/changeclientid/`, { newClientId }, { clientIdHeader: cid, raw });

export const triggerIncrementalCollect = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/startimport/true`, { raw, clientIdHeader: cid });
export const triggerFullCollect = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/startimport/false`, { raw, clientIdHeader: cid });
export const triggerFullCollectForOld = () => roseApi.collector.get(`/api/runfullimportschedule?tooold=true`);

export const triggerRebuildDb = (cid: string, raw = false) =>
  roseApi.metrics.get(`/admin/rebuilddb/${cid}`, { raw, clientIdHeader: cid });
export const triggerMigrateDb = (cid: string, raw = false) =>
  roseApi.metrics.get(`/admin/migratedb/${cid}`, { raw, clientIdHeader: cid });
export const triggerReloadR4cApps = (cid: string, r4chost: string, raw = false) =>
  roseApi.r4c.get(r4chost, `/admin/reloadr4c`, { raw, clientIdHeader: cid });
export const triggerFlushRouteCache = (cid: string, r4chost: string, raw = false) =>
  roseApi.r4c.delete(r4chost, `/admin/cache/routes`, null, { raw, clientIdHeader: cid });
export const triggerFlushAVACache = (cid: string, r4chost: string, raw = false) =>
  roseApi.r4c.delete(r4chost, `/admin/cache/ava`, null, { raw, clientIdHeader: cid });
export const triggerPraxisDataUpdate = (cid: string, r4chost: string, raw = false) =>
  roseApi.r4c.get(r4chost, `/admin/updatepraxisdata`, { raw, clientIdHeader: cid });

export const getR4cClientData = (cid: string, r4chost: string, raw = false) =>
  roseApi.r4c.get<IRoseClientData>(r4chost, `/admin/fromr4c/clientdata`, { raw, clientIdHeader: cid });

export const triggerFullImportCycle = (raw = false) => roseApi.collector.get(`/api/runfullimportschedule`, { raw });

export const triggerTodomailer = (raw = false) => roseApi.metrics.get(`/trigger/todomailer`, { raw });
export const triggerHkpmailer = (raw = false) => roseApi.metrics.get(`/trigger/hkpmailer`, { raw });

export const triggerTransformRohdaten = (cid: string, useImportTables: boolean, raw = false) =>
  roseApi.collector.get(`/api/trigger/transformrohdaten/${useImportTables}`, { raw, clientIdHeader: cid });
export const triggerTerminbuch = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/terminbuch`, { raw, clientIdHeader: cid });
export const triggerUmsatz = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/umsatz`, { raw, clientIdHeader: cid });
export const triggerHkp = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/hkp`, { raw, clientIdHeader: cid });
export const triggerPA = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/pa`, { raw, clientIdHeader: cid });
export const triggerAbrechnungsautomat = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/abrechnungsautomat`, {
    raw,
    clientIdHeader: cid,
  });
export const triggerLeistungsbloecke = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/leistungsbloecke`, {
    raw,
    clientIdHeader: cid,
  });
export const triggerMetaUpdate = (cid: string) =>
  roseApi.metrics.get(`/api/profile/updatemeta`, {
    clientIdHeader: cid,
  });

export const triggerHeute = (cid: string, days: number, raw = false) =>
  roseApi.collector.get(`/api/trigger/heute?days=${days}`, {
    raw,
    clientIdHeader: cid,
  });
export const triggerBenchmark = (cid: string, raw = false) =>
  roseApi.collector.get(`/api/trigger/benchmark`, {
    raw,
    clientIdHeader: cid,
  });
export const triggerLeistungsautomatik = (cid: string) =>
  roseApi.collector.get(`/api/trigger/leistungsautomatik`, {
    clientIdHeader: cid,
  });
export const deleteClient = (cid: string) => roseApi.metrics.delete(`admin/client/${cid}`);
export const clientLocations = (): Promise<IProfileWithLocation[]> => metricsApi.profile.getProfilesWithLocation();
export const profileLocationLookup = (cid: string): Promise<IProfileLocationSearchResult> =>
  metricsAdminApi.profileLocationLookup(cid);
export const mergeR4cToMetricsProfile = (r4cClientid: string, metricsClientid: string): Promise<void> =>
  metricsAdminApi.mergeR4cToMetricsProfile(r4cClientid, metricsClientid);
export const mergeMetricsToR4cProfile = (metricsClientid: string, r4cClientid: string): Promise<void> =>
  metricsAdminApi.mergeMetricsToR4cProfile(metricsClientid, r4cClientid);

export const createHelpdeskInfo = (cid: string): Promise<string> => profileApi.createHelpdeskInfo(cid);
export const setHelpdeskId = (cid: string, helpdeskId: string): Promise<void> =>
  profileApi.setHelpdeskId(cid, helpdeskId);

export const createR4CStripeSubscription = (
  cid: string,
  factoringFeature: FEATURES | null,
  trialDays?: number,
  startDate?: Date,
  additionalFeatures: FEATURES[] = [],
) => stripeApi.createR4CStripeSubscription(cid, factoringFeature, trialDays, startDate, additionalFeatures);
export const createMetricsStripeSubscription = (
  cid: string,
  stripeCustomerId: string,
  startDate: string,
  gutscheincode: string,
  multiclientlogin: MultiClientLoginType,
  savesetupfee: boolean,
) =>
  stripeApi.createMetricsStripeSubscription(
    cid,
    stripeCustomerId,
    startDate,
    gutscheincode,
    multiclientlogin,
    savesetupfee,
  );

export const saveOnboardingData = (cid: string, onboardingData: IOnboardingDaten) =>
  roseApi.metrics.post('admin/saveOnboardingData', {
    cid,
    onboardingData,
  });

export const getRecentR4cData = (cid: string) => r4cAdminApi.getRecentData(cid);

// bot
// bot
export const sendRestartBotCommand = (cid: string) => roseApi.collector.get(`api/reboot`, { clientIdHeader: cid });
export const sendRestartBotServiceCommand = (cid: string) =>
  roseApi.collector.get(`api/restartservice`, { clientIdHeader: cid });
export const sendRestartAutosshCommand = (cid: string) =>
  roseApi.collector.get(`api/restartsshservice`, { clientIdHeader: cid });
export const sendUpdateBotCommand = (cid: string) => roseApi.collector.get(`api/update`, { clientIdHeader: cid });
export const sendChangeSSHPortCommand = (cid: string, port: '22' | '80') =>
  roseApi.collector.get(`api/changesshport/${port}`, { clientIdHeader: cid });
export const updateBot = (updatedBot: any) => roseApi.metrics.put(`/admin/bot`, updatedBot);
export const updateExternalIPBot = (cid: string): Promise<{ ip: string }> =>
  roseApi.collector.get(`api/updateexternalip/${cid}`);

// import
// import
export const getCollectionStats = (
  cid: string,
  fullCollectionStatsData?: boolean,
  incrementalCollectionStatsData?: boolean,
  from?: moment.Moment,
  to?: moment.Moment,
  limit?: number,
  excludeprotocol?: boolean,
) =>
  roseApi.metrics.get(`api/collectionstats`, {
    query: {
      full: fullCollectionStatsData,
      incremental: incrementalCollectionStatsData,
      from: from?.toString(),
      to: to?.toString(),
      limit: limit?.toString(),
      excludeprotocol: excludeprotocol,
    },
    clientIdHeader: cid,
  });
export const getProtocolCollectionStats = (cid: string, id: string) =>
  roseApi.metrics.get<ICollectionStatistics>(`api/protocolcollectionstats`, {
    query: { id: id },
    clientIdHeader: cid,
  });
export const getLogsEvents = (date: string) => roseApi.metrics.get(`/logevents/${date}`);
export const getLogsEventsByHour = (date: string, hour: string) => roseApi.metrics.get(`/logevents/${date}/${hour}`);
export const getLogsEventsDetails = (id: string) => roseApi.metrics.get(`/logeventsdetails/${id}`);

export const loadImportProgress = (cid: string) =>
  roseApi.collector.get(`api/importprogress/`, { clientIdHeader: cid });
export const loadAllImportProgress = (includelast = false) =>
  roseApi.collector.get<IAllImportInfo>(`api/allimportprogress/`, { query: { includelast } });
export const getCurrentImportState = (limitErrors?: number) =>
  roseApi.metrics.get<{
    importe: IClientsImportInfos;
    lasterrors: IClientImportErrors[];
    workers: { worker: IWorkerStatus[] };
  }>(`admin/currentimportstate/`, { query: { limitErrors } });
export const loadAllImports = (fullImportData?: boolean, incrementalImportData?: boolean, limit?: number) =>
  roseApi.metrics.get(`admin/imports/`, {
    query: {
      full: fullImportData,
      incremental: incrementalImportData,
      limit: limit?.toString(),
    },
  });
export const getImportDetails = (importId: string) => roseApi.metrics.get<any>(`admin/imports/${importId}`);

// onboarding
// onboarding
export const getStripeCustomer = (id: string) => stripeApi.loadStripeCustomer(id);
export const getOnboardingEntries = () =>
  roseApi.metrics.get<{ onboardingEntries: IOnboardingEntryExtended[]; unacceptedProfiles: IProfile[] }>(
    `admin/onboarding`,
  );
export const deleteOnboardingEntry = (id: string) =>
  roseApi.metrics.delete<{
    ok: boolean;
    onboardingEntries: IOnboardingEntryExtended[];
    unacceptedProfiles: IProfile[];
  }>(`admin/onboarding`, { id });
export const acceptOnboardingEntry = (
  id: string,
  cid: string,
  dbHost: string,
  existingCustomer: boolean,
  reuseExistingStripeCustomer: boolean,
  addSuffix: boolean,
) =>
  roseApi.metrics.post(`admin/onboarding/accept`, {
    id,
    cid,
    dbHost,
    existingCustomer,
    reuseExistingStripeCustomer,
    addSuffix,
  });
export const createStripeAccount4OnboardingEntry = (id: string, reuseExistingStripeCustomer: boolean) =>
  stripeApi.createStripeAccount4OnboardingEntry(id, reuseExistingStripeCustomer);
export const createStripeAccount4CID = (cid: string, reuseExistingStripeCustomer: boolean) =>
  stripeApi.createStripeAccount4CID(cid, reuseExistingStripeCustomer);
export const activateOnboardingEntry = (id: string, reuseExistingStripeCustomer: boolean) =>
  roseApi.metrics.post(`admin/onboarding/activate`, { id, reuseExistingStripeCustomer });

// email
// email
export const sendEmails = (entry: any) => roseApi.metrics.post(`admin/sendemail`, entry);

// formular editor
// formular editor
export const getEweFormularConfig = () => roseApi.r4cDispatch.get('admin/formulare/config');
export const getFormularRenderedUrl = (formularId: string) =>
  roseApi.r4cDispatch.get(`admin/formulare/rendered/${formularId}`);
export const setFormularConfigEntryPositionen = (update: {
  rz: string;
  isRoot: boolean;
  entryPositionen: any;
  entryRootPositionen?: any;
  subEntryName?: string;
}) => roseApi.r4cDispatch.post(`admin/formulare/config/positionen`, update);
export const previewFormularPositionen = (formularId: string, positionen: any) =>
  roseApi.r4cDispatch.post<any>(`admin/formulare/preview/${formularId}`, { positionen });

// charly analytics licences
export const getCharlyAnalyticsLicences = () => roseApi.metrics.get(`/admin/calicences`);
