import _defineProperty from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { defineComponent } from 'vue';
import { OnboardingProcessStatusType } from '@rose/types';
var colors = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, OnboardingProcessStatusType.UNBEARBEITET, {
  background: 'grey lighten-2',
  text: 'grey darken-2'
}), OnboardingProcessStatusType.VERSANDT, {
  background: 'blue',
  text: 'white'
}), OnboardingProcessStatusType.ANGEKOMMEN, {
  background: 'yellow darken-1',
  text: 'grey darken-2'
}), OnboardingProcessStatusType.VERBINDUNG_OK, {
  background: 'green',
  text: 'white'
}), OnboardingProcessStatusType.ERSTSCHULUNG, {
  background: 'purple',
  text: 'white'
});
export default defineComponent({
  name: 'OnboardingProcessStatusCell',
  data: function data() {
    return {
      params: {},
      colors: colors
    };
  },
  computed: {
    onboardingStatus: function onboardingStatus() {
      return this.params.value;
    },
    backgroundColor: function backgroundColor() {
      var _a;
      return this.onboardingStatus ? (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.background : undefined;
    },
    textColor: function textColor() {
      var _a;
      return this.onboardingStatus ? (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.text : undefined;
    }
  }
});