import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "client-ids"
  }, _vm._l(_vm.clientIds, function (cid) {
    return _c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: cid,
        expression: "cid",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.copySuccess,
        expression: "copySuccess",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.copyError,
        expression: "copyError",
        arg: "error"
      }],
      key: cid,
      staticClass: "client-id"
    }, [_c('span', {
      staticClass: "cid"
    }, [_vm._v(_vm._s(cid))]), _c('router-link', {
      attrs: {
        "to": {
          name: "Clients",
          params: {
            selectedKey: cid
          }
        }
      }
    }, [_c(VIcon, {
      staticClass: "mb-1 ml-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v("launch")])], 1)], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };