import "core-js/modules/es.array.sort.js";
import { defineComponent } from 'vue';
import _ from 'lodash';
export default defineComponent({
  name: 'TagsCell',
  data: function data() {
    return {
      params: null,
      tags: []
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a;
      if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
        if (_.isArray(this.params.value)) {
          this.tags = _(this.params.value).uniq().sort().value();
        } else {
          this.tags = [this.params.value];
        }
      }
    }
  }
});