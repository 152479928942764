import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import moment from 'moment';
import { every, map } from 'lodash';
import { defineComponent } from 'vue';
import JsonEditorEmbeddedComponent from '@/components/JsonEditorEmbeddedComponent.vue';
import StripeInvoicesTable from '@/components/StripeInvoicesTable.vue';
import { stripeApi, metricsAdminApi, createStripeAccount4CID } from '@/services/data';
import { FEATURES } from '@rose/types';
import CreateR4cSubscriptionDialogue from '@/components/dialogues/CreateR4cSubscriptionDialogue.vue';
import CreateMetricsSubscriptionDialogue from '@/components/dialogues/CreateMetricsSubscriptionDialogue.vue';
import DeleteSubscriptionsDialogue from '@/components/dialogues/DeleteSubscriptionsDialogue.vue';
import ChangeIbanDialogue from '@/components/dialogues/ChangeIbanDialogue.vue';
import ChangeMailDialogue from '@/components/dialogues/ChangeMailDialogue.vue';
import ExtendTestPeriodDialogue from '@/components/dialogues/ExtendTestPeriodDialogue.vue';
export default defineComponent({
  name: 'ClientStripe',
  components: {
    ExtendTestPeriodDialogue: ExtendTestPeriodDialogue,
    ChangeMailDialogue: ChangeMailDialogue,
    ChangeIbanDialogue: ChangeIbanDialogue,
    DeleteSubscriptionsDialogue: DeleteSubscriptionsDialogue,
    CreateMetricsSubscriptionDialogue: CreateMetricsSubscriptionDialogue,
    CreateR4cSubscriptionDialogue: CreateR4cSubscriptionDialogue,
    StripeInvoicesTable: StripeInvoicesTable,
    JsonEditorEmbeddedComponent: JsonEditorEmbeddedComponent
  },
  props: {
    isSolutioTeam: Boolean,
    isDev: Boolean,
    isStaging: Boolean,
    selectedClient: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {
      selectedStripeInfoComplete: {},
      selectedStripeInvoices: [],
      selectedStripeSubscription: {},
      noMoreTestPeriod: true,
      // change iban dialog
      changeIbanDialog: false,
      // change mail dialog
      changeMailDialog: false,
      // prolong client dialog
      showProlongTestperiodDialog: false,
      // r4c sub client dialog
      showR4cSubscriptionDialog: false,
      // metrics sub client dialog
      showMetricsSubscriptionDialog: false,
      // delete sub dialog
      showDeleteStripeSubscriptionDialog: false,
      FEATURES: FEATURES
    };
  },
  computed: {
    stripeCustomerLink: function stripeCustomerLink() {
      var _a, _b;
      if ((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.stripeCustomerId) {
        return "https://dashboard.stripe.com/customers/".concat((_b = this.selectedClient) === null || _b === void 0 ? void 0 : _b.stripeCustomerId);
      }
      return '#';
    },
    stripeCASubscriptionLink: function stripeCASubscriptionLink() {
      var _a, _b;
      if ((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.charlyanalyticssubscriptionid) {
        return "https://dashboard.stripe.com/subscriptions/".concat((_b = this.selectedClient) === null || _b === void 0 ? void 0 : _b.charlyanalyticssubscriptionid);
      }
      return '#';
    },
    getSubscriptionInfosOfSelectedCustomer: function getSubscriptionInfosOfSelectedCustomer() {
      var si = this.selectedStripeInfoComplete;
      if (si === null || si === void 0 ? void 0 : si.customer) {
        var subs = [];
        var _iterator = _createForOfIteratorHelper(si.subscriptions),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var sub = _step.value;
            subs.push({
              id: sub.id,
              items: map(sub.items, function (i) {
                return i.priceNickname;
              }),
              trialstart: sub.trialstart,
              trialende: moment(sub.trialend).format('DD.MM.YYYY')
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        return subs;
      }
      return [];
    },
    allSelectedStripeSubscriptions: function allSelectedStripeSubscriptions() {
      var _a;
      var subscriptions = [];
      if ((_a = this.selectedStripeInfoComplete) === null || _a === void 0 ? void 0 : _a.subscriptions) {
        subscriptions = this.selectedStripeInfoComplete.subscriptions;
      }
      if (this.selectedStripeSubscription) {
        subscriptions.push(this.selectedStripeSubscription);
      }
      return subscriptions;
    },
    hasShippingAddress: function hasShippingAddress() {
      var _a, _b, _c, _d, _e;
      return !!((_e = (_d = (_c = (_b = (_a = this.selectedStripeInfoComplete) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.raw) === null || _c === void 0 ? void 0 : _c.shipping) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.line1);
    }
  },
  watch: {
    selectedClient: {
      immediate: true,
      handler: function handler(newSelectedClient) {
        var _this = this;
        this.selectedStripeInfoComplete = null;
        this.selectedStripeInvoices = null;
        this.selectedStripeSubscription = null;
        if (newSelectedClient.stripeCustomerId || newSelectedClient.charlyanalyticssubscriptionid) {
          // stripe invoices
          console.log("stripe: load invoices");
          if (newSelectedClient.stripeCustomerId) {
            stripeApi.loadStripeCustomerInvoices(newSelectedClient.stripeCustomerId).then(function (invoices) {
              console.log('stripe: got invoices by customer', invoices);
              _this.selectedStripeInvoices = invoices;
            })["catch"](function (err) {
              console.error('stripe: error loading invoices', err);
            });
          } else if (newSelectedClient.charlyanalyticssubscriptionid) {
            stripeApi.loadStripeSubscriptionInvoices(newSelectedClient.charlyanalyticssubscriptionid).then(function (invoices) {
              console.log('stripe: got invoices by subscription', invoices);
              _this.selectedStripeInvoices = invoices;
            })["catch"](function (err) {
              console.error('stripe: error loading invoices by subscription', err);
            });
          }
          // stripe customer information
          if (newSelectedClient.stripeCustomerId) {
            console.log("stripe: load infos");
            stripeApi.loadStripeInfoComplete(newSelectedClient.stripeCustomerId).then(function (si) {
              if (si) {
                _this.noMoreTestPeriod = every(si.subscriptions, function (s) {
                  return moment(s.trialend).isBefore();
                });
              } else {
                _this.noMoreTestPeriod = true;
              }
              console.log('stripe: got infos', si);
              _this.selectedStripeInfoComplete = si;
            })["catch"](function (err) {
              console.error('stripe: error loading infos', err);
              _this.selectedStripeInfoComplete = null;
            });
          }
          if (newSelectedClient.charlyanalyticssubscriptionid) {
            stripeApi.loadStripeSubscription(newSelectedClient.charlyanalyticssubscriptionid).then(function (si) {
              console.log('stripe: got subscription', si);
              _this.selectedStripeSubscription = si;
            })["catch"](function (err) {
              console.error('stripe: error loading subscription', err);
              _this.selectedStripeSubscription = null;
            });
          }
        }
      }
    }
  },
  methods: {
    changeIban: function changeIban() {
      this.changeIbanDialog = true;
    },
    moveSubscriptions: function moveSubscriptions() {
      var targetCustomerId = prompt('Stripe ID des Zielkunden') || '';
      if (!targetCustomerId) {
        return;
      }
      if (!this.selectedStripeInfoComplete) {
        return;
      }
      var stripeInfos = this.selectedStripeInfoComplete;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a, _b;
        return ((_a = stripeInfos.customer) === null || _a === void 0 ? void 0 : _a.id) ? stripeApi.moveStripeSubscriptions((_b = stripeInfos.customer) === null || _b === void 0 ? void 0 : _b.id, targetCustomerId) : Promise.resolve();
      }, 'Moved subscriptions');
    },
    createCASubscription: function createCASubscription() {
      var _this2 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        return metricsAdminApi.charlyanalyticsaboanlegen(_this2.selectedClient.cid);
      }, 'created ca subscription');
    },
    createStripecustomer: function createStripecustomer() {
      var _this3 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        return createStripeAccount4CID(_this3.selectedClient.cid, false);
      }, 'created stripe customer', true);
    },
    extendTestperiod: function extendTestperiod() {
      this.showProlongTestperiodDialog = true;
    },
    addR4cSubscription: function addR4cSubscription() {
      this.showR4cSubscriptionDialog = true;
    },
    addMetricsSubscription: function addMetricsSubscription() {
      this.showMetricsSubscriptionDialog = true;
    },
    cancelSubscription: function cancelSubscription() {
      this.showDeleteStripeSubscriptionDialog = true;
    },
    changeEmail: function changeEmail() {
      this.changeMailDialog = true;
    }
  }
});