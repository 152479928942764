import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    isTrue: {
      get: function get() {
        return !!this.params.value;
      },
      set: function set(value) {
        this.params.context.clicked({
          row: this.params.data,
          value: value
        });
      }
    },
    label: function label() {
      return this.params.context.label;
    }
  },
  methods: {
    clicked: function clicked(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
});