import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "400px"
    },
    model: {
      value: _vm.showDialogue,
      callback: function callback($$v) {
        _vm.showDialogue = $$v;
      },
      expression: "showDialogue"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Change IBAN")]), _c(VCardText, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('label', {
    staticClass: "font-weight-bold",
    attrs: {
      "for": "iban-element"
    }
  }, [_vm._v("IBAN")]), _c('span', {
    ref: "ibanElementContainer",
    staticClass: "my-1"
  }), _vm.isDev ? _c('span', [_vm._v("test iban: DE89370400440532013000")]) : _vm._e()])]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showDialogue = false;
      }
    }
  }, [_vm._v("Close")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "warning",
      "disabled": !_vm.ibanValid
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeIban();
      }
    }
  }, [_vm._v("Accept")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };