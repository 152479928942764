import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c(VAutocomplete, {
    attrs: {
      "placeholder": "Gruppe auswählen",
      "items": _vm.availableGroups,
      "item-value": "id",
      "item-text": "name",
      "clearable": "",
      "dense": "",
      "flat": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.change
    },
    model: {
      value: _vm.selectedGroupId,
      callback: function callback($$v) {
        _vm.selectedGroupId = $$v;
      },
      expression: "selectedGroupId"
    }
  }), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": "",
      "depressed": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.openEditGroupModal(null);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-plus")])], 1), _c(VBtn, {
    attrs: {
      "small": "",
      "depressed": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.openEditGroupModal(_vm.selectedGroup);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-edit")])], 1), _c('EditProfileGroupDialogue', {
    attrs: {
      "profileGroup": _vm.editProfileGroup,
      "available-clusters": _vm.availableClusters
    },
    on: {
      "save": _vm.loadGroups
    },
    model: {
      value: _vm.showEditGroupModal,
      callback: function callback($$v) {
        _vm.showEditGroupModal = $$v;
      },
      expression: "showEditGroupModal"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };