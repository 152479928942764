import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "actions d-flex align-baseline"
  }, [_c(VSelect, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "items": _vm.r4cs
    },
    model: {
      value: _vm.r4chost,
      callback: function callback($$v) {
        _vm.r4chost = $$v;
      },
      expression: "r4chost"
    }
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "color": "warning",
      "disabled": _vm.testsRunning
    },
    on: {
      "click": _vm.runTest
    }
  }, [_c('span', [_vm._v("Run All Tests on Server")]), _vm.testsRunning ? _c(VIcon, {
    staticClass: "ml-2"
  }, [_vm._v("mdi-loading mdi-spin")]) : _vm._e()], 1), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.loadResults
    }
  }, [_vm._v("Load Results")])], 1), _c('div', {
    staticClass: "tests"
  }, _vm._l(_vm.tests, function (test) {
    return _c('div', {
      key: test.name,
      staticClass: "test"
    }, [_c('div', {
      staticClass: "testName"
    }, [_c('span', [_vm._v(_vm._s(test.name))]), _c(VBtn, {
      staticClass: "ml-4",
      attrs: {
        "text": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.runTest(test.name);
        }
      }
    }, [_vm._v("Run Test")])], 1), _c('div', {
      staticClass: "images"
    }, [_c('div', {
      staticClass: "image"
    }, [_c('span', [_vm._v("Expected")]), _c('img', {
      staticClass: "image1",
      attrs: {
        "src": _vm.getFullImageUrl(test.expectedPng),
        "crossorigin": "anonymous"
      }
    })]), _c('div', {
      staticClass: "image"
    }, [_c('span', [_vm._v("Generated")]), _c('img', {
      staticClass: "image2",
      attrs: {
        "src": _vm.getFullImageUrl(test.generatedPng),
        "crossorigin": "anonymous"
      }
    })])])]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };