import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "import-info"
  }, [_vm.importinfo.running ? _c(VBtn, {
    staticClass: "ec pt-1",
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toggleExpand();
      }
    }
  }, [!_vm.expanded && _vm.importinfo.running ? _c(VIcon, {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "zeige import verlauf",
      expression: "\"zeige import verlauf\""
    }],
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-plus-circle")]) : _vm._e(), _vm.expanded ? _c(VIcon, {
    attrs: {
      "title": "collapse",
      "x-small": ""
    }
  }, [_vm._v("fa-minus-circle")]) : _vm._e()], 1) : _c(VIcon, {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "es läuft kein import",
      expression: "\"es läuft kein import\""
    }],
    staticClass: "no-ec pt-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-circle-xmark")]), _c('div', {
    staticClass: "status"
  }, [_vm.importinfo.running ? _c(VIcon, {
    attrs: {
      "title": "running",
      "small": ""
    }
  }, [_vm._v("fa-hourglass-start")]) : _c('rose-confirm-button', {
    staticClass: "trigger-import v-icon",
    attrs: {
      "x-small": "",
      "title": "not running, start",
      "actionText": "Start Import",
      "block": false,
      "color": "primary",
      "description": _vm._f("importTriggerDesc")(_vm.importinfo),
      "noPadding": true,
      "noMinWidth": true,
      "elevation": 0
    },
    on: {
      "confirmed": function confirmed($event) {
        return _vm.triggerCollect(_vm.importinfo);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fa-circle-play")])], 1)], 1), _vm.importinfo.running ? _c('div', {
    staticClass: "import-status",
    attrs: {
      "title": "import status: S=selected, R=rawdata, D=derived, F=finishing"
    }
  }, [_vm._v(_vm._s(_vm.importinfo.running.state[0]))]) : _vm._e(), _c('div', {
    staticClass: "pn"
  }, [_c('CopyText', {
    staticClass: "overflow-ellipsis",
    "class": {
      veraltet: _vm.importinfo.veraltet
    },
    attrs: {
      "value": _vm.importinfo.praxisKurzName
    }
  }), _vm.importinfo.pvs === "z1" ? _c('div', {
    staticClass: "is-z1"
  }, [_vm._v("z")]) : _vm.importinfo.pvs === "z1v2" ? _c('div', {
    staticClass: "is-z1"
  }, [_vm._v("z²")]) : _vm.importinfo.pvs === "evident" ? _c('div', {
    staticClass: "is-evident"
  }, [_vm._v("e")]) : _vm.importinfo.pvs === "charly" && _vm.isCharlyAnalyticsCustomer ? _c('div', {
    staticClass: "is-charly"
  }, [_vm._v("ca")]) : _vm.importinfo.pvs === "charly" ? _c('div', {
    staticClass: "is-charly"
  }, [_vm._v("c")]) : _c('div', {
    staticClass: "is-unknown"
  }, [_vm._v("?")]), _vm._l(_vm.importinfo.tags, function (tag) {
    return _c('div', {
      key: tag,
      staticClass: "tag"
    }, [_vm._v(_vm._s(tag))]);
  })], 2), _c('div', {
    staticClass: "cid"
  }, [_c('CopyText', {
    staticClass: "overflow-ellipsis",
    attrs: {
      "value": _vm.importinfo.cid
    }
  }), _c('router-link', {
    attrs: {
      "to": {
        name: "Clients",
        params: {
          selectedKey: _vm.importinfo.cid
        }
      }
    }
  }, [_c(VIcon, {
    staticClass: "mb-1 ml-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("launch")])], 1)], 1), _c('div', {
    staticClass: "start",
    attrs: {
      "title": "import started"
    }
  }, [_vm.importinfo.started ? _c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.started, 'DD.MM.YY')))]) : _vm.importinfo.running && _vm.importinfo.running.iinfo && _vm.importinfo.running.iinfo.started ? _c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.running.iinfo.started, 'DD.MM.YY')))]) : _vm.importinfo.errorcount > 0 ? _c('span', {
    staticClass: "bad"
  }, [_vm._v("last import failed e:" + _vm._s(_vm.importinfo.errorcount))]) : _c('span', [_vm._v("-")]), _vm.importinfo.started ? _c('span', {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.started, 'HH:mm')))]) : _vm.importinfo.running && _vm.importinfo.running.iinfo && _vm.importinfo.running.iinfo.started ? _c('span', {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.running.iinfo.started, 'HH:mm')))]) : _c('span', [_vm._v("-")])]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "import alter",
      expression: "\"import alter\""
    }],
    staticClass: "age",
    "class": {
      bad: !_vm.importinfo.running && _vm.importinfo.ageinseconds > _vm.agethreshold,
      warn: _vm.importinfo.running && _vm.importinfo.ageinseconds > _vm.agethreshold
    },
    on: {
      "click": function click($event) {
        return _vm.logData(_vm.importinfo);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.formatDurationSimple(_vm.importinfo.ageinseconds)))])]), _c('div', {
    staticClass: "worker"
  }, [_vm.importinfo.workerid ? _c('span', [_vm._v(_vm._s(_vm.importinfo.workerid) + "@" + _vm._s(_vm.importinfo.workerhost))]) : _vm._e()]), _c('div', {
    staticClass: "pg"
  }, [_c('span', [_vm._v(_vm._s(_vm.importinfo.dbhost))])]), _c('div', {
    staticClass: "importid"
  }, [_c('CopyText', {
    staticClass: "importid-last",
    attrs: {
      "value": _vm.importinfo.importid,
      "title": "last finisehd import"
    }
  }), _vm.importinfo.running && _vm.importinfo.running.iinfo ? _c('div', {
    staticClass: "importid-running less-important",
    attrs: {
      "title": "running import"
    }
  }, [_c('span', [_vm._v("(")]), _c('CopyText', {
    attrs: {
      "value": _vm.importinfo.running.iinfo.id
    }
  }), _c('span', [_vm._v(")")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "end",
    attrs: {
      "title": "import finished"
    }
  }, [_vm.importinfo.finished ? _c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.finished, 'DD.MM.YY')))]) : _c('span', [_vm._v("-")]), _vm.importinfo.finished ? _c('span', {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.importinfo.finished, 'HH:mm')))]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "online-status"
  }, [_vm.importinfo.online ? _c(VIcon, {
    attrs: {
      "title": "socket"
    }
  }, [_vm._v("done")]) : _c(VIcon, {
    attrs: {
      "title": "socket"
    }
  }, [_vm._v("highlight_off")]), _vm.importinfo.sshonline && _vm.isCharlyAnalyticsService ? _c('span', [_vm._v("-")]) : _vm._e(), _vm.importinfo.sshonline ? _c(VIcon, {
    attrs: {
      "title": "ssh: " + _vm.importinfo.sshport
    }
  }, [_vm._v("done")]) : _c(VIcon, {
    attrs: {
      "title": "ssh: " + _vm.importinfo.sshport
    }
  }, [_vm._v("highlight_off")])], 1), _vm.importinfo.patcount ? _c('div', {
    staticClass: "patcount less-important",
    attrs: {
      "title": "anzahl patienten"
    }
  }, [_vm._v(_vm._s(_vm.importinfo.patcount) + "p")]) : _vm._e(), _c('div', {
    staticClass: "duration less-important",
    attrs: {
      "title": "importdauer"
    }
  }, [_vm.importinfo.duration ? _c('span', [_vm._v(_vm._s(_vm.formatDurationSimple(_vm.importinfo.duration)))]) : _vm.importinfo.running && _vm.importinfo.running.iinfo && _vm.importinfo.running.iinfo.started ? _c('span', [_vm._v(_vm._s(_vm.fromNowSimple(_vm.importinfo.running.iinfo.started)))]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "progress",
    style: {
      width: _vm.importinfo.progress + "%"
    }
  }), _vm.expanded ? _c('div', {
    staticClass: "import-steps-details"
  }, [_c('div', {
    staticClass: "step-times"
  }, _vm._l(_vm.steps, function (s, i) {
    return _c('div', {
      staticClass: "step",
      style: {
        "grid-row": i + 1
      }
    }, [_c('div', {
      staticClass: "step-name"
    }, [_vm._v(_vm._s(s.info.name))]), _c('div', {
      staticClass: "step-line"
    }), _c('div', {
      staticClass: "step-time"
    }, [_vm._v(_vm._s(_vm._f("moment")(s.time, 'HH:mm:ss')))])]);
  }), 0), _c('div', {
    staticClass: "step-diffs"
  }, [_vm._l(_vm.steps, function (s, i) {
    return [i === 0 ? _c('div', {
      staticClass: "half-step"
    }) : _c('div', {
      staticClass: "step-diff"
    }, [_vm._v(_vm._s(_vm.formatDurationSimple(s.diff)))])];
  })], 2)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };