import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "text-edit-cell d-flex align-center"
  }, [_c('input', {
    staticClass: "text-field",
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onChange
    }
  }), _c(VBtn, {
    attrs: {
      "icon": "",
      "small": "",
      "color": "primary",
      "disabled": !_vm.canSave
    },
    on: {
      "click": _vm.onCommit
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-save")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };