import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit("routeAction");
            }
          }
        }, on), [_c(VIcon, [_vm._v("add_circle_outline")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Neuen Kunden anlegen")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };