import { defineComponent } from 'vue';
import { notifySuccess, notifyError, walkPropertyPath } from '@/services/helpers';
export default defineComponent({
  props: ['value', 'formatter', 'scope', 'path', 'className', 'copyText'],
  computed: {
    displayValue: function displayValue() {
      var value = this.value;
      if (this.scope) {
        value = this.scope.row;
      }
      value = walkPropertyPath(value, this.path);
      if (this.formatter) {
        return this.formatter({
          prop: value
        }, {
          property: 'prop'
        });
      }
      return value;
    },
    hasDefaultSlot: function hasDefaultSlot() {
      return !!this.$slots["default"];
    }
  },
  methods: {
    copySuccess: function copySuccess() {
      notifySuccess('Copied!');
    },
    copyError: function copyError() {
      notifyError('Copy failed: ' + this.displayValue);
    }
  }
});