import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { defineComponent } from 'vue';
import { Chart } from 'highcharts-vue';
import { chain, forEach, sortBy, mapValues, map } from 'lodash';
import { roseDayjs } from '../../../base';
export default defineComponent({
  name: 'ImportChartComponent',
  components: {
    Chart: Chart
  },
  props: {
    importInfos: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      panel: [0],
      normalRangeFull: [],
      normalRangeInc: [],
      fullChartOptions: {
        chart: {
          type: 'scatter'
        },
        title: {
          text: 'Full Duration Distribution by PVS'
        },
        xAxis: {
          type: 'category',
          categories: []
        },
        yAxis: {
          type: 'logarithmic',
          title: {
            text: 'Duration (m)'
          },
          labels: {
            format: '{value} m'
          },
          plotBands: []
        },
        legend: {},
        plotOptions: {
          scatter: {
            showInLegend: false,
            jitter: {
              x: 0.24,
              y: 0
            },
            marker: {
              radius: 2,
              symbol: 'circle'
            },
            tooltip: {
              pointFormat: '{point.name}: {point.y:.2f}m'
            }
          }
        },
        series: []
      },
      incChartOptions: {
        chart: {
          type: 'scatter'
        },
        title: {
          text: 'Incremental Duration Distribution by PVS'
        },
        xAxis: {
          type: 'category',
          categories: []
        },
        yAxis: {
          type: 'logarithmic',
          title: {
            text: 'Duration (m)'
          },
          labels: {
            format: '{value} m'
          },
          plotBands: []
        },
        legend: {},
        plotOptions: {
          scatter: {
            showInLegend: false,
            jitter: {
              x: 0.24,
              y: 0
            },
            marker: {
              radius: 2,
              symbol: 'circle'
            },
            tooltip: {
              pointFormat: '{point.name}: {point.y:.2f}m'
            }
          }
        },
        series: []
      }
    };
  },
  watch: {
    importInfos: {
      handler: function handler() {
        this.updateCharts();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.updateCharts();
  },
  methods: {
    getQuantile: function getQuantile(arr, quantile) {
      var sorted = sortBy(arr);
      var pos = (sorted.length - 1) * quantile;
      var base = Math.floor(pos);
      var rest = pos - base;
      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
        return sorted[base];
      }
    },
    updateCharts: function updateCharts() {
      var _this = this;
      var imports = chain(this.importInfos).map(function (value, key) {
        var _a, _b;
        return {
          cid: key,
          pvs: value.pvs,
          full: ((_a = value.full) === null || _a === void 0 ? void 0 : _a.duration) ? roseDayjs.duration(value.full.duration, 's').asMinutes() : 0,
          inc: ((_b = value.incremental) === null || _b === void 0 ? void 0 : _b.duration) ? roseDayjs.duration(value.incremental.duration, 's').asMinutes() : 0
        };
      }).groupBy(function (cid) {
        return cid.pvs;
      }).value();
      console.log('imports:', imports);
      this.fullChartOptions.xAxis.categories = Object.keys(imports);
      this.incChartOptions.xAxis.categories = Object.keys(imports);
      this.fullChartOptions.series = [];
      this.incChartOptions.series = [];
      var x = 0;
      forEach(imports, function (value, key) {
        _this.fullChartOptions.series.push({
          name: key,
          data: value.map(function (item) {
            return {
              name: item.cid,
              x: x,
              y: item.full
            };
          })
        });
        _this.incChartOptions.series.push({
          name: key,
          data: value.map(function (item) {
            return {
              name: item.cid,
              x: x,
              y: item.inc
            };
          })
        });
        x += 1;
      });
      this.abnormal();
    },
    abnormal: function abnormal() {
      var _this2 = this;
      var groupedData = chain(this.importInfos).map(function (value, key) {
        var _a, _b;
        return {
          cid: key,
          pvs: value.pvs,
          full: ((_a = value.full) === null || _a === void 0 ? void 0 : _a.duration) ? roseDayjs.duration(value.full.duration, 's').asMinutes() : 0,
          inc: ((_b = value.incremental) === null || _b === void 0 ? void 0 : _b.duration) ? roseDayjs.duration(value.incremental.duration, 's').asMinutes() : 0
        };
      }).groupBy('pvs').value();
      var normalRangesPerPvs = mapValues(groupedData, function (group) {
        var fullDurations = group.map(function (d) {
          return d.full;
        }).filter(function (d) {
          return d > 0;
        });
        var incDurations = group.map(function (d) {
          return d.inc;
        }).filter(function (d) {
          return d > 0;
        });
        var fullQuantiles = {
          q25: _this2.getQuantile(fullDurations, 0.01),
          q75: _this2.getQuantile(fullDurations, 0.95)
        };
        var incQuantiles = {
          q25: _this2.getQuantile(incDurations, 0.01),
          q75: _this2.getQuantile(incDurations, 0.95)
        };
        return {
          full: {
            from: fullQuantiles.q25,
            to: fullQuantiles.q75
          },
          inc: {
            from: incQuantiles.q25,
            to: incQuantiles.q75
          }
        };
      });
      this.normalRangeInc = map(normalRangesPerPvs, function (value, key) {
        return {
          pvs: key,
          from: Math.round(value.inc.from * 100) / 100,
          to: Math.round(value.inc.to * 100) / 100
        };
      });
      this.normalRangeFull = map(normalRangesPerPvs, function (value, key) {
        return {
          pvs: key,
          from: Math.round(value.full.from * 100) / 100,
          to: Math.round(value.full.to * 100) / 100
        };
      });
      console.log('Normal Ranges per PVS:', normalRangesPerPvs);
      forEach(this.fullChartOptions.series, function (series) {
        series.data = series.data.map(function (point) {
          var normalRange = normalRangesPerPvs[series.name].full;
          var isAbnormal = normalRange && (point.y < normalRange.from || point.y > normalRange.to);
          return Object.assign(Object.assign({}, point), {
            color: isAbnormal ? '#FFA500' : undefined
          });
        });
      });
      forEach(this.incChartOptions.series, function (series) {
        series.data = series.data.map(function (point) {
          var normalRange = normalRangesPerPvs[series.name].inc;
          var isAbnormal = normalRange && (point.y < normalRange.from || point.y > normalRange.to);
          return Object.assign(Object.assign({}, point), {
            color: isAbnormal ? '#FFA500' : undefined
          });
        });
      });
      console.log('Updated Series Data with Abnormal Points:', this.fullChartOptions.series, this.incChartOptions.series);
    }
  }
});