import { dateTimeFormatter, fromNowFormatter, IRoseAGGridColumn, mfaInfoFormatter } from '@rose/common-ui';

const getName = (params: any) => `${params.data.firstName} ${params.data.lastName}`;

export const userColumns = [
  {
    headerName: 'ID / E-Mail',
    field: 'email',
    sort: 'asc',
    sortable: true,
    resizable: true,
    width: 400,
    cellRenderer: 'CopyTextCell',
  },
  { headerName: 'Name', valueGetter: getName, sortable: true, resizable: true, width: 400 },
  {
    headerName: 'Client-IDs',
    field: 'alleclientids',
    cellRenderer: 'ClientIdCell',
    width: 500,
    sortable: true,
    resizable: true,
  },
  { headerName: 'User-Tags', field: 'tags', cellRenderer: 'TagsCell', sortable: true, resizable: true },
  { headerName: 'Client-Tags', field: 'alletags', cellRenderer: 'TagsCell', sortable: true, resizable: true },
  {
    headerName: 'Last Active',
    field: 'lastActivity',
    width: 200,
    valueFormatter: fromNowFormatter,
    sortable: true,
    resizable: true,
  },
  {
    headerName: 'Views',
    field: 'accessibleViews',
    cellRenderer: 'TagsCell',
    sortable: true,
    resizable: true,
  },
  { headerName: 'Admin Level', field: 'adminLevel', sortable: true, resizable: true },
  { headerName: '2FA Status', field: 'mfatype', sortable: true, resizable: true, valueFormatter: mfaInfoFormatter },
  {
    headerName: 'Last Password Change',
    field: 'lastPasswordUpdate',
    valueFormatter: fromNowFormatter,
    sortable: true,
    resizable: true,
  },
  { headerName: 'Changed', field: 'updatedAt', valueFormatter: dateTimeFormatter, sortable: true, resizable: true },
] as IRoseAGGridColumn<any>[];
