/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ContactPositionCell.vue?vue&type=template&id=0fe79f73&scoped=true&lang=pug"
import script from "./ContactPositionCell.vue?vue&type=script&lang=ts"
export * from "./ContactPositionCell.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe79f73",
  null
  
)

export default component.exports