import _defineProperty from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { defineComponent } from 'vue';
import { OnboardingStatusType } from '@rose/types';
var colors = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, OnboardingStatusType.NEW, {
  background: 'light-blue lighten-3',
  // or 'yellow lighten-3' for a yellow variant
  text: 'grey darken-4'
}), OnboardingStatusType.PENDING, {
  background: 'amber lighten-3',
  // or 'orange lighten-3' for a more vibrant orange
  text: 'grey darken-4'
}), OnboardingStatusType.ACCEPTED, {
  background: 'green',
  text: 'white'
}), OnboardingStatusType.ACTIVATED, {
  background: 'indigo',
  // or 'purple' for a purple variant
  text: 'white'
}), OnboardingStatusType.IGNORE, {
  background: 'grey lighten-1',
  text: 'grey darken-3'
});
export default defineComponent({
  name: 'OnboardingMainStatusCell',
  data: function data() {
    return {
      params: {},
      colors: colors
    };
  },
  computed: {
    onboardingStatus: function onboardingStatus() {
      return this.params.value;
    },
    backgroundColor: function backgroundColor() {
      var _a;
      return this.onboardingStatus ? (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.background : undefined;
    },
    textColor: function textColor() {
      var _a;
      return this.onboardingStatus ? (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.text : undefined;
    }
  }
});