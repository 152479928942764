import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('Split', {
    "class": {
      "gutter-visible": _vm.showAdditionalTab
    },
    attrs: {
      "id": "tools",
      "gutterSize": _vm.showAdditionalTab ? 10 : 0
    }
  }, [_c('SplitArea', {
    staticClass: "wrap",
    attrs: {
      "size": _vm.isSecondTab ? 100 : _vm.showAdditionalTab ? 50 : 100
    }
  }, [_c('div', {
    staticClass: "nav-items"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('router-link', {
      key: item.text,
      staticClass: "nav-link",
      attrs: {
        "to": _vm.isSecondTab ? {
          params: {
            view2: item.path
          }
        } : {
          params: {
            view1: item.path
          }
        }
      }
    }, [_c(VIcon, {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(item.icon))]), _c('span', [_vm._v(_vm._s(item.text))])], 1)];
  }), !_vm.isSecondTab && !_vm.showAdditionalTab ? _c('button', {
    staticClass: "nav-link d-flex align-center",
    on: {
      "click": _vm.addTab
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("far fa-plus")]), _c('span', [_vm._v("Tab")])], 1) : _vm._e(), _vm.isSecondTab ? _c('button', {
    staticClass: "nav-link",
    on: {
      "click": _vm.closeTab
    }
  }, [_c(VIcon, [_vm._v("far fa-circle-xmark")])], 1) : _vm._e()], 2), _c('KeepAlive', [_c(_vm.showComponent, {
    tag: "Component"
  })], 1), !_vm.showComponent ? _c('div', {
    staticClass: "noChild"
  }, [_c('h1', [_vm._v("Tools")]), _c('p', [_vm._v("Wähle ein Tool aus der Navigation")])]) : _vm._e()], 1), !_vm.isSecondTab ? _c('SplitArea', {
    attrs: {
      "size": _vm.showAdditionalTab ? 50 : 0
    }
  }, [_vm.showAdditionalTab ? _c('Tools', {
    attrs: {
      "isSecondTab": ""
    },
    on: {
      "closeTab": function closeTab($event) {
        _vm.showAdditionalTab = false;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };