import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('div', {
    staticClass: "kunde-header"
  }, [_c('div', {
    staticClass: "card-title mr-2"
  }, [_vm._v("Kunde:")]), _c('CopyText', {
    staticClass: "card-title cid",
    attrs: {
      "value": _vm.selectedClientModified.cid
    }
  }), _c('div', {
    staticClass: "created-at card-title ml-4"
  }, [_vm._v("angelegt am: " + _vm._s(_vm._f("moment")(_vm.selectedClientModified.createdAt, 'DD.MM.YYYY')))]), _c('CopyText', {
    staticClass: "card-title apikey ml-4",
    attrs: {
      "value": _vm.selectedClientModified.apikey
    }
  }, [_c(VIcon, [_vm._v("fa-key")])], 1), _c(VBtn, {
    staticClass: "save",
    attrs: {
      "x-small": "",
      "color": "success"
    },
    on: {
      "click": _vm.updateSelectedClient
    }
  }, [_vm._v("Speichern")])], 1)]), _c(VCardText, [_c('div', {
    staticClass: "infos"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Praxis-Name"
    },
    model: {
      value: _vm.selectedClientModified.praxisName,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "praxisName", $$v);
      },
      expression: "selectedClientModified.praxisName"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Kurz-Name"
    },
    model: {
      value: _vm.selectedClientModified.praxisKurzName,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "praxisKurzName", $$v);
      },
      expression: "selectedClientModified.praxisKurzName"
    }
  }), _c(VCombobox, {
    attrs: {
      "clearable": "",
      "hide-selected": "",
      "chips": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": "",
      "dense": "",
      "label": "Tags",
      "items": _vm.allUserTags,
      "hide-details": ""
    },
    model: {
      value: _vm.selectedClientModified.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "tags", $$v);
      },
      expression: "selectedClientModified.tags"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Strasse"
    },
    model: {
      value: _vm.selectedClientModified.strasse,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "strasse", $$v);
      },
      expression: "selectedClientModified.strasse"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "PLZ"
    },
    model: {
      value: _vm.selectedClientModified.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "plz", $$v);
      },
      expression: "selectedClientModified.plz"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Stadt"
    },
    model: {
      value: _vm.selectedClientModified.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "stadt", $$v);
      },
      expression: "selectedClientModified.stadt"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "EMail (wird als Antwortadresse bei r4c-Mails verwendet)"
    },
    model: {
      value: _vm.selectedClientModified.praxisEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "praxisEmail", $$v);
      },
      expression: "selectedClientModified.praxisEmail"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "News-EMail"
    },
    model: {
      value: _vm.selectedClientModified.praxisNewsEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "praxisNewsEmail", $$v);
      },
      expression: "selectedClientModified.praxisNewsEmail"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Telefon"
    },
    model: {
      value: _vm.selectedClientModified.praxisTelefon,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "praxisTelefon", $$v);
      },
      expression: "selectedClientModified.praxisTelefon"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Website"
    },
    model: {
      value: _vm.selectedClientModified.website,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "website", $$v);
      },
      expression: "selectedClientModified.website"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "DB-Host"
    },
    model: {
      value: _vm.selectedClientModified.dbhost,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "dbhost", $$v);
      },
      expression: "selectedClientModified.dbhost"
    }
  }), _c('GroupAutocomplete', {
    attrs: {
      "available-groups": _vm.availableGroups
    },
    on: {
      "load-groups": _vm.loadGroups
    },
    model: {
      value: _vm.selectedClientModified.group,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "group", $$v);
      },
      expression: "selectedClientModified.group"
    }
  }), _c('div', {
    staticClass: "r4chost"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "label": "r4c-Host"
    },
    model: {
      value: _vm.selectedClientModified.r4chost,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "r4chost", $$v);
      },
      expression: "selectedClientModified.r4chost"
    }
  }), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "info",
      "title": "r4c-Cache leeren"
    },
    on: {
      "click": function click($event) {
        return _vm.clearR4cCache(_vm.selectedClientModified.r4chost);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-trash-xmark")])], 1)], 1), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "r4c-cid"
    },
    model: {
      value: _vm.selectedClientModified.r4creferencecid,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "r4creferencecid", $$v);
      },
      expression: "selectedClientModified.r4creferencecid"
    }
  }), _vm.selectedClientModified.location && _vm.selectedClientModified.location.lat ? _c('div', {
    staticClass: "location-info"
  }, [_c('div', {
    staticClass: "location-entry"
  }, [_vm._v("Location lat:")]), _c(VTextField, {
    attrs: {
      "dense": "",
      "hide-details": "",
      "auto": ""
    },
    model: {
      value: _vm.selectedClientModified.location.lat,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified.location, "lat", $$v);
      },
      expression: "selectedClientModified.location.lat"
    }
  }), _c('div', {
    staticClass: "location-entry"
  }, [_vm._v("lon:")]), _c(VTextField, {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selectedClientModified.location.lon,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified.location, "lon", $$v);
      },
      expression: "selectedClientModified.location.lon"
    }
  }), _c('div', {
    staticClass: "location-entry"
  }, [_vm._v("osmid:")]), _c(VTextField, {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selectedClientModified.location.osmid,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified.location, "osmid", $$v);
      },
      expression: "selectedClientModified.location.osmid"
    }
  }), _c(VBtn, {
    staticClass: "location-entry",
    attrs: {
      "href": "https://www.openstreetmap.org/#map=18/" + _vm.selectedClientModified.location.lat + "/" + _vm.selectedClientModified.location.lon,
      "x-small": "",
      "color": "info",
      "target": "_blank"
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-arrow-up-right-from-square")])], 1), _c(VBtn, {
    staticClass: "location-entry",
    attrs: {
      "x-small": "",
      "color": "info",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteLocation();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-trash")])], 1)], 1) : _c('div', {
    staticClass: "no-location"
  }, [_c('span', [_vm._v("Keine Location")]), _c(VBtn, {
    staticClass: "location-entry",
    attrs: {
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.locationLookup();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fa-map-location-dot")])], 1)], 1), _c(VSelect, {
    attrs: {
      "items": _vm.metricspackages,
      "mandatory": "",
      "dense": "",
      "label": "Paket"
    },
    model: {
      value: _vm.selectedClientModified.lizenzInfo.packageName,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified.lizenzInfo, "packageName", $$v);
      },
      expression: "selectedClientModified.lizenzInfo.packageName"
    }
  }), _c('div', {
    staticClass: "client-actions"
  }, [_c(VSwitch, {
    staticClass: "client-switch",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "Nur Billing"
    },
    model: {
      value: _vm.selectedClientModified.billingonly,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "billingonly", $$v);
      },
      expression: "selectedClientModified.billingonly"
    }
  }), _c(VSwitch, {
    staticClass: "client-switch",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "Debug",
      "color": "warning"
    },
    model: {
      value: _vm.selectedClientModified.debug,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "debug", $$v);
      },
      expression: "selectedClientModified.debug"
    }
  }), _c(VDialog, {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "red lighten-2",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Kunde deaktivieren")])];
      }
    }]),
    model: {
      value: _vm.deprecateDialogue,
      callback: function callback($$v) {
        _vm.deprecateDialogue = $$v;
      },
      expression: "deprecateDialogue"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "text-h5 grey lighten-2"
  }, [_c(VIcon, {
    staticClass: "pr-2"
  }, [_vm._v("fa-user-slash")]), _c('span', [_vm._v("Kunde deaktivieren")])], 1), _c(VCardText, {
    staticClass: "pt-6"
  }, [_c('span', [_vm._v("Soll der Kunde wirklich deaktiviert werden?")]), _c('br'), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Dieser Schritt kann nicht rückgängig gemacht werden!")])]), _c(VDivider), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deprecateDialogue = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VBtn, {
    attrs: {
      "color": "red lighten-2",
      "dark": ""
    },
    on: {
      "click": _vm.deprecateClient
    }
  }, [_vm._v("Deaktivieren")])], 1)], 1)], 1)], 1)], 1), _c('label', [_vm._v("Lizenzen")]), _c('div', {
    staticClass: "lizenzen"
  }, [_c('LizenzenTable', {
    attrs: {
      "client": _vm.selectedClientModified
    }
  })], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('label', [_vm._v("Bemerkung")])]), _c('div', {
    staticClass: "bemerkung"
  }, [_c(VTextarea, {
    attrs: {
      "clearable": "",
      "filled": "",
      "outlined": ""
    },
    model: {
      value: _vm.selectedClientModified.bemerkung,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedClientModified, "bemerkung", $$v);
      },
      expression: "selectedClientModified.bemerkung"
    }
  })], 1), _c('div', {
    staticClass: "actions-buttons mt-2"
  }, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v("Passwort zurücksetzen")]), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.changeClientId(_vm.selectedClientModified.cid);
      }
    }
  }, [_vm._v("Client-Id ändern")]), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "warning",
      "disabled": !_vm.hasR4CFeature
    },
    on: {
      "click": function click($event) {
        return _vm.mergeR4cToMetricsProfile(_vm.selectedClientModified.cid);
      }
    }
  }, [_vm._v("Merge r4c in metrics (ca merge)")]), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "warning",
      "disabled": !_vm.hasMetricsFeature
    },
    on: {
      "click": function click($event) {
        return _vm.mergeMetricsToR4cProfile(_vm.selectedClientModified.cid);
      }
    }
  }, [_vm._v("Merge metrics in r4c (not for ca)")]), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.editSettings.apply(null, arguments);
      }
    }
  }, [_vm._v("Einstellungen bearbeiten")]), _c(VBtn, {
    attrs: {
      "x-small": "",
      "color": "success"
    },
    on: {
      "click": _vm.updateSelectedClient
    }
  }, [_vm._v("Speichern")]), _c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "block": true
    },
    on: {
      "confirmed": function confirmed($event) {
        return _vm.deleteClient(_vm.selectedClientModified.cid);
      }
    }
  }, [_vm._v("Löschen")])], 1), _c('JsonEditorComponent', {
    ref: "jsonEditorComponent",
    attrs: {
      "value": _vm.selectedClientSettings,
      "title": _vm.jsonEditorTitle
    },
    on: {
      "saveJsonChange": _vm.saveJsonChangeAction
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };