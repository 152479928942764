import { defineComponent } from 'vue';
import { FreshdeskTicketStatus } from '../../../../types';
export default defineComponent({
  name: 'TicketStatusCell',
  data: function data() {
    return {
      params: {},
      ticketStatus: FreshdeskTicketStatus
    };
  },
  computed: {
    text: function text() {
      switch (this.params.value) {
        case FreshdeskTicketStatus.Open:
          return 'Offen';
        case FreshdeskTicketStatus.Pending:
          return 'In Bearbeitung';
        case FreshdeskTicketStatus.Resolved:
          return 'Gelöst';
        case FreshdeskTicketStatus.Closed:
          return 'Geschlossen';
        case FreshdeskTicketStatus.WaitingOnCustomer:
          return 'Warte auf Kunden';
        case FreshdeskTicketStatus.WaitingOnThirdParty:
          return 'Warte auf Drittanbieter';
        case FreshdeskTicketStatus.CallBack:
          return 'Rückmelden';
        case FreshdeskTicketStatus.InternalClarification:
          return 'Interne Klärung';
        default:
          return '';
      }
    },
    color: function color() {
      switch (this.params.value) {
        case FreshdeskTicketStatus.Open:
          return 'error';
        case FreshdeskTicketStatus.Pending:
        case FreshdeskTicketStatus.CallBack:
        case FreshdeskTicketStatus.InternalClarification:
          return 'warning';
        case FreshdeskTicketStatus.Resolved:
          return 'success';
        case FreshdeskTicketStatus.Closed:
        case FreshdeskTicketStatus.WaitingOnCustomer:
        case FreshdeskTicketStatus.WaitingOnThirdParty:
          return 'grey';
        default:
          return '';
      }
    }
  }
});