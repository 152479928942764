import { IDoctosyncUnmatchedPatients, IDBDoctolib, IDoctosyncError } from '../../../types';
import { RoseApi } from './apiTypes';

export type DoctosyncApi = ReturnType<typeof createDoctosyncApi>;

export function createDoctosyncApi(roseApi: RoseApi) {
  return {
    getUnmatchedPatients: () => roseApi.doctosync.get<IDoctosyncUnmatchedPatients[]>(`/api/unmatchedpatients`),
    addPatid: (extid: string, patid: string) => roseApi.doctosync.post(`/api/addpatid`, { extid, patid }),
    reset: (cid: string, hard = false) =>
      roseApi.doctosync.post(`/api/reset`, { hard }, { headers: { 'rose-client-id': cid } }),
    getMeta: (cid: string) => roseApi.doctosync.get<IDBDoctolib>(`/api/meta`, { headers: { 'rose-client-id': cid } }),
    getProblems: (cid: string) =>
      roseApi.doctosync.get<IDoctosyncError>(`/api/problems`, { headers: { 'rose-client-id': cid } }),
  };
}
