import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.statusText === false ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.setStatus
          }
        }, on), [_c(VIcon, [_vm._v("warning")])], 1)];
      }
    }], null, false, 3177252851)
  }, [_c('span', [_vm._v("Statusnachricht setzen")])]) : _vm._e(), _vm.statusText ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.deleteStatus
          }
        }, on), [_c(VIcon, {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("delete")])], 1)];
      }
    }], null, false, 3943419186)
  }, [_c('span', [_vm._v("Nachricht: " + _vm._s(_vm.statusText))])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };