import * as Visibility from 'visibilityjs';
import { BehaviorSubject } from 'rxjs';

import { IMPORT_STEPS } from '../../../types';
import { loadAllImportProgress } from '@/services/data';
import * as _ from 'lodash';
import { importStateStore } from '@/state/import.state';

let updateTimeout: any;

export const visible$ = new BehaviorSubject<boolean>(Visibility ? Visibility.isSupported() : true);

export function startTrackingVisibility() {
  Visibility.change((ev, state) => {
    visible$.next(state === 'visible');
  });
}

export function visibleSubscription() {
  visible$.subscribe(async visible => {
    // update if last update was longer than 5 seconds ago
    clearTimeout(updateTimeout);
    // disable autoload
    // updateTimeout = setTimeout(async () => {
    //   if (visible) {
    //     await setImportCount();
    //     visibleSubscription();
    //   }
    // }, 1000 * 10);
  });
}

export async function setImportCount() {
  let count = 0;
  const aipRaw = await loadAllImportProgress();
  let allImportProgress = aipRaw.imports;
  allImportProgress = _.filter(
    allImportProgress,
    i => (i.incrementalInfo && i.incrementalInfo.step) !== IMPORT_STEPS.FINISHED,
  );
  for (const importItem of allImportProgress) {
    if (
      (importItem.incrementalInfo && importItem.incrementalInfo.iinfo) ||
      (importItem.fullInfo && importItem.fullInfo.iinfo)
    ) {
      count++;
    }
  }
  importStateStore.setImportCount(count);
}
