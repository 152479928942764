import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.pvs === "z1" ? _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "#359aff",
      "text-color": "white"
    }
  }, [_vm._v("z1-v1")]) : _vm.pvs === "z1v2" ? _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "#353fff",
      "text-color": "white"
    }
  }, [_vm._v("z1-v2")]) : _vm.pvs === "charly" ? _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "#f1a52c",
      "text-color": "white"
    }
  }, [_vm._v("c")]) : _vm.pvs === "evident" ? _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "#278f27",
      "text-color": "white"
    }
  }, [_vm._v("e")]) : _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "red",
      "text-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.pvs))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };