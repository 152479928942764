import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.json.stringify.js";
import { defineComponent } from 'vue';
import * as _ from 'lodash';
export default defineComponent({
  filters: {
    uppercase: function uppercase(innput) {
      return _.toUpper(innput);
    }
  },
  props: ['data', 'eventTrigger', 'status', 'path', 'rawResponse'],
  data: function data() {
    return {
      newResulatDialog: false,
      responseDialogData: {}
    };
  },
  watch: {
    data: function data() {
      this.responseDialogData = JSON.stringify(this.data, null, 2);
      this.newResulatDialog = true;
    }
  },
  mounted: function mounted() {},
  methods: {}
});