/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=576c3390&scoped=true&lang=pug"
import script from "./Root.vue?vue&type=script&lang=ts"
export * from "./Root.vue?vue&type=script&lang=ts"
import style0 from "./Root.vue?vue&type=style&index=0&id=576c3390&prod&lang=scss&scoped=true"
import style1 from "./Root.vue?vue&type=style&index=1&id=576c3390&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576c3390",
  null
  
)

export default component.exports