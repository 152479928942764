var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('Dashboard', {
    staticClass: "dashbord",
    attrs: {
      "filter": _vm.globalSearchStore.searchInput,
      "dashBoardItems": _vm.dashBoardItems
    },
    on: {
      "filterChanged": function filterChanged($event) {
        return _vm.globalSearchStore.setSearchInput($event);
      },
      "realod": _vm.loadData
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };