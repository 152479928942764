/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DeleteSubscriptionDialogue.vue?vue&type=template&id=50a20022&scoped=true&lang=pug"
import script from "./DeleteSubscriptionDialogue.vue?vue&type=script&lang=ts"
export * from "./DeleteSubscriptionDialogue.vue?vue&type=script&lang=ts"
import style0 from "./DeleteSubscriptionDialogue.vue?vue&type=style&index=0&id=50a20022&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a20022",
  null
  
)

export default component.exports