import { defineComponent } from 'vue';
import BarChart from './BarChart.vue';
import moment from 'moment';
import * as _ from 'lodash';
export default defineComponent({
  components: {
    BarChart: BarChart
  },
  props: ['chartData', 'options'],
  data: function data() {
    return {
      datacollection: null
    };
  },
  watch: {
    chartData: function chartData() {
      this.datacollection = this.getData();
    }
  },
  methods: {
    getChartData: function getChartData() {
      var data = _.map(this.chartData, function (d) {
        return d.count;
      });
      return data;
    },
    getLabels: function getLabels() {
      var data = _.map(this.chartData, function (d) {
        return moment(d.createdat).utc().format('HH:mm');
      });
      return data;
    },
    getData: function getData() {
      var labels = this.getLabels();
      var formatedData = {
        labels: labels,
        datasets: [{
          label: 'logs per hour',
          backgroundColor: '#f87979',
          data: this.getChartData()
        }]
      };
      return formatedData;
    }
  }
  // TODO why this is not working
  // computed: {
  //   options() {
  //     const onClick = this.handle;
  //     return {
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
  //       onClick,
  //     };
  //   },
  // },
});