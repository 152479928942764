import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.changeIbanDialog,
      callback: function callback($$v) {
        _vm.changeIbanDialog = $$v;
      },
      expression: "changeIbanDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("IBAN Ändern")]), _c(VCardText, [_c('h4', [_vm._v(_vm._s(_vm.heading))]), _c('label', {
    attrs: {
      "for": "iban-element"
    }
  }, [_vm._v("IBAN")]), _c('div', {
    attrs: {
      "id": "iban-element"
    }
  }), _vm.isDev || _vm.isStaging ? _c('span', [_vm._v("test iban: DE89370400440532013000")]) : _vm._e()]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.changeIbanDialog = false;
      }
    }
  }, [_vm._v("Close")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "warning",
      "disabled": !_vm.ibanValid,
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeIban.apply(null, arguments);
      }
    }
  }, [_vm._v("Ändern")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };