import { defineComponent } from 'vue';
import { EnumValues, CharlyAnalyticsLicenceStatus } from '../../../../types';
import { rules } from '../../../../common-ui';
export default defineComponent({
  props: {
    licence: {
      type: Object
    }
  },
  data: function data() {
    return {
      rules: rules
    };
  },
  computed: {
    statusItems: function statusItems() {
      return EnumValues.getNamesAndValues(CharlyAnalyticsLicenceStatus);
    }
  },
  methods: {}
});