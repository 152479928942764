import { metricsApi } from './data';

export const login = async (creds: { email: string; password: string; twoFaCode?: string | undefined }) => {
  const res = await metricsApi.auth.login(creds);

  if (!validateUserObject(res)) {
    throw new Error(`login failed, message: ${(res as any).message}`);
  }

  const user = {
    email: res.email,
    cid: res.mainclientid,
    token: res.token,
    firstName: res.firstName,
    lastName: res.lastName,
    adminLevel: res.adminLevel,
  };

  return user;
};

function validateUserObject(user: any): boolean {
  return user.cid && user.email && user.token;
}
