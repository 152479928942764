import { defineComponent } from 'vue';
import { FreshdeskTicketPriority } from '../../../../types';
export default defineComponent({
  name: 'TicketPrioCell',
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    text: function text() {
      switch (this.params.value) {
        case FreshdeskTicketPriority.Low:
          return 'Niedrig';
        case FreshdeskTicketPriority.Medium:
          return 'Mittel';
        case FreshdeskTicketPriority.High:
          return 'Hoch';
        case FreshdeskTicketPriority.Urgent:
          return 'Dringend';
        default:
          return '';
      }
    },
    color: function color() {
      switch (this.params.value) {
        case FreshdeskTicketPriority.Low:
          return 'grey';
        case FreshdeskTicketPriority.Medium:
          return 'warning';
        case FreshdeskTicketPriority.High:
          return 'error';
        case FreshdeskTicketPriority.Urgent:
          return 'error darken-2';
        default:
          return '';
      }
    }
  }
});