var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bot-version"
  }, [_c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.botVersion,
      expression: "botVersion",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticClass: "span",
    "class": {
      "bold-red": _vm.isOldVersion
    }
  }, [_vm._v(_vm._s(_vm.botVersion))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };