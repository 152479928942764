var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_vm.hasDefaultSlot ? _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.displayValue,
      expression: "displayValue",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticClass: "slotWrap",
    "class": _vm.className
  }, [_vm._t("default")], 2) : _c('span', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.displayValue,
      expression: "displayValue",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    "class": _vm.className
  }, [_vm._v(_vm._s(_vm.displayValue))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };