var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('label', {
    attrs: {
      "for": "iban-element"
    }
  }, [_vm._v("IBAN")]), _c('div', {
    attrs: {
      "id": "iban-element"
    }
  }), _vm.isDev || _vm.isStaging ? _c('span', [_vm._v("test iban: DE89370400440532013000")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };