import _slicedToArray from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { chain, groupBy } from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    errors: {
      type: Array,
      required: true,
      "default": function _default() {
        return [];
      }
    }
  },
  computed: {
    errorGroups: function errorGroups() {
      // Step 1: Normalize error messages to remove redundant client IDs and handle more generic error categories
      var cleanedErrors = this.errors.map(function (error) {
        var cleanedMessage = error.errors.map(function (msg) {
          // Generic case: Match errors that are connection-related (e.g., ECONNREFUSED, EHOSTUNREACH)
          var matchConnection = msg.match(/^(.*\s)?connect\s(?:ECONNREFUSED|EHOSTUNREACH|ENOPROTOOPT|EADDRINUSE).*$/);
          if (matchConnection) {
            return {
              original: msg,
              cleaned: 'Connection Error'
            }; // Keep original and cleaned message
          }
          // Generic case: Match SQL-related errors (e.g., table not found)
          var matchSQL = msg.match(/^.*(?:Cannot find the object|syntax error|timeout|sqlsender).*$/);
          if (matchSQL) {
            return {
              original: msg,
              cleaned: 'SQL Error'
            }; // Keep original and cleaned message
          }
          // Generic case: Match timeouts or request errors
          var matchTimeout = msg.match(/^(timeout|request).*$/);
          if (matchTimeout) {
            return {
              original: msg,
              cleaned: 'Timeout Error'
            }; // Keep original and cleaned message
          }
          // Generic case: Handle reboot or system state related errors
          var matchReboot = msg.match(/^(reboot.*|system is.*|SHUTDOWN.*)$/);
          if (matchReboot) {
            return {
              original: msg,
              cleaned: 'System Error'
            }; // Keep original and cleaned message
          }
          // Generic case: Match any internal service-specific errors (e.g., abwesenheiten, bot disconnected)
          var matchService = msg.match(/^(bot\sdisconnected|abwesenheiten|inc:.*)$/);
          if (matchService) {
            return {
              original: msg,
              cleaned: 'Service Error'
            }; // Keep original and cleaned message
          }
          // Default case: return the message itself if no pattern matches
          return {
            original: msg,
            cleaned: msg
          }; // No change if no match
        });
        return Object.assign(Object.assign({}, error), {
          errors: cleanedMessage
        }); // Attach cleaned error messages to the error object
      });
      // Step 2: Group errors by their normalized (cleaned) message and further by `cid`
      var grouped = chain(cleanedErrors).map(function (e) {
        return Object.assign(Object.assign({}, e), {
          e: e.errors[0].cleaned
        });
      }).groupBy(function (g) {
        return g.e;
      }) // Group by the cleaned error message
      .mapValues(function (groupedErrors) {
        return groupBy(groupedErrors, function (x) {
          return x.cid;
        });
      }).value();
      // Step 3: Sort the groups by the count of affected clients (descending order)
      var sortedGroups = Object.entries(grouped).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          errorMessage = _ref2[0],
          clients = _ref2[1];
        return {
          errorMessage: errorMessage,
          clients: clients,
          affectedClientCount: Object.keys(clients).length
        };
      }).sort(function (a, b) {
        return b.affectedClientCount - a.affectedClientCount;
      }); // Sort by client count, descending
      // Convert sorted groups back into the desired object format
      var sortedGroupedObject = sortedGroups.reduce(function (acc, _ref3) {
        var errorMessage = _ref3.errorMessage,
          clients = _ref3.clients;
        acc[errorMessage] = clients;
        return acc;
      }, {}); // Ensure the final object is typed
      console.log('Cleaned, Grouped, and Sorted Errors:', sortedGroupedObject); // Debugging output
      return sortedGroupedObject;
    }
  },
  methods: {
    showDetails: function showDetails(group) {
      // Logic to show detailed view, e.g., opening a modal or navigating to another page
      alert("Selected Group: ".concat(group.name));
    }
  }
});