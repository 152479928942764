import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('LogsWrapper', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [!_vm.selectedHourEventsLogs ? _c(VCard, [_c(VLayout, {
          attrs: {
            "align-center": ""
          }
        }, [_c(VFlex, {
          staticClass: "date-picker-container",
          attrs: {
            "xs12": "",
            "md2": "",
            "lg2": ""
          }
        }, [_c(VMenu, {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            "return-value": _vm.date,
            "transition": "scale-transition",
            "offset-y": "",
            "min-width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              _vm.date = $event;
            },
            "update:return-value": function updateReturnValue($event) {
              _vm.date = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c(VTextField, _vm._g({
                attrs: {
                  "label": "Choose a specific day",
                  "prepend-icon": "event",
                  "readonly": ""
                },
                model: {
                  value: _vm.date,
                  callback: function callback($$v) {
                    _vm.date = $$v;
                  },
                  expression: "date"
                }
              }, on))];
            }
          }], null, false, 3365140281),
          model: {
            value: _vm.menu,
            callback: function callback($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c(VDatePicker, {
          on: {
            "input": function input($event) {
              return _vm.$refs.menu.save(_vm.date);
            }
          },
          model: {
            value: _vm.date,
            callback: function callback($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        })], 1)], 1), _c(VFlex, {
          staticClass: "card-container",
          attrs: {
            "xs10": ""
          }
        })], 1), _c(VCardText, [_c('ActivityLogsChartComponent', {
          attrs: {
            "chartData": _vm.eventLogs
          },
          on: {
            "hour": _vm.hourChild
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [!_vm.selectedHourEventsLogs ? _c(VCard, [_c('rose-master-table', {
          ref: "table",
          attrs: {
            "data": _vm.hourEventLogsData,
            "idKey": "createdAt",
            "default-sort": {
              prop: "createdAt",
              order: "ascending"
            }
          },
          on: {
            "selectionChange": _vm.selectionChange
          },
          scopedSlots: _vm._u([{
            key: "columns",
            fn: function fn() {
              return [_c('el-table-column', {
                attrs: {
                  "label": "Time",
                  "min-width": "120px",
                  "property": "createdAt",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('CopyText', {
                      attrs: {
                        "value": scope.row.createdAt,
                        "formatter": _vm.formatDateTime
                      }
                    })];
                  }
                }], null, false, 448416923)
              }), _c('el-table-column', {
                attrs: {
                  "label": "User",
                  "sortable": "",
                  "property": "meta.app.user"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('CopyText', {
                      attrs: {
                        "value": scope.row.meta.app.user
                      }
                    })];
                  }
                }], null, false, 2243447575)
              }), _c('el-table-column', {
                attrs: {
                  "label": "Client",
                  "sortable": "",
                  "property": "meta.patient.name"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('CopyText', {
                      attrs: {
                        "value": scope.row.meta.patient.name
                      }
                    })];
                  }
                }], null, false, 454094707)
              }), _c('el-table-column', {
                attrs: {
                  "label": "version",
                  "property": "meta.uuid",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('CopyText', {
                      attrs: {
                        "value": scope.row.meta.uuid
                      }
                    })];
                  }
                }], null, false, 1624270052)
              })];
            },
            proxy: true
          }], null, false, 4095527500)
        })], 1) : _vm._e(), _vm.selectedHourEventsLogs ? _c(VCard, [_c('div', {
          staticClass: "card-container"
        }, [_c(VFlex, {
          attrs: {
            "xs11": ""
          }
        }, [_c(VCardTitle, [_c('span', [_vm._v("Logs Events Details")])]), _c(VCardText)], 1), _c(VFlex, {
          staticClass: "close",
          attrs: {
            "xs1": ""
          }
        }, [_c(VIcon, {
          on: {
            "click": function click($event) {
              _vm.selectedHourEventsLogs = false;
            }
          }
        }, [_vm._v("close")])], 1)], 1), _c(VFlex, {
          attrs: {
            "xs12": ""
          }
        }, _vm._l(_vm.logsEventsDetails, function (log) {
          return _c('p', [_vm._v(_vm._s(log))]);
        }), 0)], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };