import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.isSolutioTeam ? _c(VCard, {
    staticClass: "onboarding-infos",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("Onboarding-Infos")])]), _vm.onboardingDaten ? _c(VCardText, [_c('label', [_vm._v("Praxis")]), _vm.onboardingDaten.praxisDaten && _vm.onboardingDaten.praxisDaten.praxis ? _c('div', {
    staticClass: "label-value-grid"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "Name"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.name,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "name", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.name"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Name Kurz"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.nameKurz,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "nameKurz", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.nameKurz"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Tel"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "tel", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.tel"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Straße"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.straße,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "straße", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.straße"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "PLZ"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "plz", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.plz"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Stadt"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.praxis.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.praxis, "stadt", $$v);
      },
      expression: "onboardingDaten.praxisDaten.praxis.stadt"
    }
  })], 1) : _vm._e(), _c('label', [_vm._v("Auftraggeber")]), _vm.onboardingDaten.praxisDaten && _vm.onboardingDaten.praxisDaten.auftraggeber ? _c('div', {
    staticClass: "label-value-grid"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Vorname"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "vorname", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.name,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "name", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.name"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "E-Mail"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.email,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "email", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.email"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Straße"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.straße,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "straße", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.straße"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "PLZ"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "plz", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.plz"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Stadt"
    },
    model: {
      value: _vm.onboardingDaten.praxisDaten.auftraggeber.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.praxisDaten.auftraggeber, "stadt", $$v);
      },
      expression: "onboardingDaten.praxisDaten.auftraggeber.stadt"
    }
  })], 1) : _vm._e(), _c('label', [_vm._v("Admin Benutzer")]), _vm.onboardingDaten.adminBenutzer ? _c('div', {
    staticClass: "label-value-grid"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Vorname"
    },
    model: {
      value: _vm.onboardingDaten.adminBenutzer.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.adminBenutzer, "vorname", $$v);
      },
      expression: "onboardingDaten.adminBenutzer.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.onboardingDaten.adminBenutzer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.adminBenutzer, "name", $$v);
      },
      expression: "onboardingDaten.adminBenutzer.name"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "E-Mail"
    },
    model: {
      value: _vm.onboardingDaten.adminBenutzer.email,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.adminBenutzer, "email", $$v);
      },
      expression: "onboardingDaten.adminBenutzer.email"
    }
  })], 1) : _vm._e(), _c('label', [_vm._v("Ansprechpartner")]), _vm.onboardingDaten.ansprechpartner ? _c('div', {
    staticClass: "label-value-grid"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Vorname"
    },
    model: {
      value: _vm.onboardingDaten.ansprechpartner.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.ansprechpartner, "vorname", $$v);
      },
      expression: "onboardingDaten.ansprechpartner.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.onboardingDaten.ansprechpartner.name,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.ansprechpartner, "name", $$v);
      },
      expression: "onboardingDaten.ansprechpartner.name"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "E-Mail"
    },
    model: {
      value: _vm.onboardingDaten.ansprechpartner.email,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.ansprechpartner, "email", $$v);
      },
      expression: "onboardingDaten.ansprechpartner.email"
    }
  }), _c(VTextField, {
    attrs: {
      "dense": "",
      "label": "Tel"
    },
    model: {
      value: _vm.onboardingDaten.ansprechpartner.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.onboardingDaten.ansprechpartner, "tel", $$v);
      },
      expression: "onboardingDaten.ansprechpartner.tel"
    }
  })], 1) : _vm._e(), _c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "success"
    },
    on: {
      "click": _vm.saveOnboardingDaten
    }
  }, [_vm._v("Speichern")])], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };