import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { defineComponent } from 'vue';
import { dateFormatter } from '@rose/common-ui';
function currency(value) {
  var showDecimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  value = value.value;
  var nf = new Intl.NumberFormat();
  return showDecimal ? "".concat(nf.format(value), " \u20AC") : "".concat(nf.format(Number(value.toFixed())), " \u20AC");
}
export default defineComponent({
  components: {},
  props: {
    invoices: Array
  },
  data: function data() {
    return {
      rewriteInvoiceDialog: false,
      invoicesHeaders: [{
        headerName: 'Nummer',
        width: 140,
        field: 'number'
      }, {
        headerName: 'Datum',
        width: 100,
        field: 'created',
        valueFormatter: dateFormatter
      }, {
        headerName: 'Betrag',
        width: 100,
        field: 'amount',
        valueFormatter: currency
      }, {
        headerName: 'Neuschreiben',
        width: 120,
        field: 'id',
        sortable: false,
        cellRenderer: 'RewriteInvoiceCellComponent'
      }, {
        headerName: 'Download',
        width: 100,
        field: 'downloadLink',
        sortable: false,
        cellRenderer: 'LinkCellComponent',
        cellRendererParams: {
          linkText: 'Download',
          linkIcon: 'fa-download'
        }
      }, {
        headerName: 'Mail',
        width: 140,
        field: 'id',
        sortable: false,
        cellRenderer: 'ReMailInvoiceCellComponent'
      }]
    };
  },
  methods: {
    onCellDoubleClicked: function onCellDoubleClicked(event) {
      var _a, _b;
      console.log('open invoice ID', (_a = event.data) === null || _a === void 0 ? void 0 : _a.id);
      window.open("https://dashboard.stripe.com/invoices/".concat((_b = event.data) === null || _b === void 0 ? void 0 : _b.id), '_blank');
    }
  }
});