import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "400px"
    },
    model: {
      value: _vm.showDialogue,
      callback: function callback($$v) {
        _vm.showDialogue = $$v;
      },
      expression: "showDialogue"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(_vm.modifiedProfileGroup.id ? 'Edit' : 'Create') + " Profile Group")]), _c(VCardText, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('label', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Gruppenname")]), _c(VTextField, {
    staticClass: "pt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "placeholder": "Gruppenname",
      "required": ""
    },
    model: {
      value: _vm.modifiedProfileGroup.name,
      callback: function callback($$v) {
        _vm.$set(_vm.modifiedProfileGroup, "name", $$v);
      },
      expression: "modifiedProfileGroup.name"
    }
  }), _c('label', {
    staticClass: "font-weight-bold mt-4"
  }, [_vm._v("Ansprechpartner")]), _c(VTextField, {
    staticClass: "py-2 mt-2",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.modifiedProfileGroup.dwh.contact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.modifiedProfileGroup.dwh.contact, "name", $$v);
      },
      expression: "modifiedProfileGroup.dwh.contact.name"
    }
  }), _c(VTextField, {
    staticClass: "py-2",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "E-Mail",
      "required": ""
    },
    model: {
      value: _vm.modifiedProfileGroup.dwh.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.modifiedProfileGroup.dwh.contact, "email", $$v);
      },
      expression: "modifiedProfileGroup.dwh.contact.email"
    }
  }), _c(VTextField, {
    staticClass: "py-2",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "Phone",
      "required": ""
    },
    model: {
      value: _vm.modifiedProfileGroup.dwh.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.modifiedProfileGroup.dwh.contact, "phone", $$v);
      },
      expression: "modifiedProfileGroup.dwh.contact.phone"
    }
  }), _c('label', {
    staticClass: "font-weight-bold mt-4"
  }, [_vm._v("Cluster")]), _c(VCombobox, {
    staticClass: "py-2",
    attrs: {
      "dense": "",
      "label": "Cluster",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": "",
      "hide-details": "",
      "items": _vm.availableClusters
    },
    model: {
      value: _vm.modifiedProfileGroup.dwh.clusters,
      callback: function callback($$v) {
        _vm.$set(_vm.modifiedProfileGroup.dwh, "clusters", $$v);
      },
      expression: "modifiedProfileGroup.dwh.clusters"
    }
  })], 1)]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showDialogue = false;
      }
    }
  }, [_vm._v("Cancel")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "warning",
      "disabled": !_vm.isValid
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.save();
      }
    }
  }, [_vm._v("Save")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };