import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "debugSuche"
  }, [_c('p', [_vm._v("Debug Suche")]), _c('div', {
    staticClass: "d-flex align-center justify-center"
  }, [_c(VTextField, {
    staticClass: "cidSearch",
    attrs: {
      "label": "cid (optional)",
      "outlined": "",
      "disabled": _vm.loading,
      "dense": "",
      "hide-details": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.doSeach.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchCid,
      callback: function callback($$v) {
        _vm.searchCid = $$v;
      },
      expression: "searchCid"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Taskid, MailSendId, ShareId",
      "outlined": "",
      "disabled": _vm.loading,
      "dense": "",
      "hide-details": "",
      "append-icon": "mdi-magnify"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.doSeach.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.doSeach
    }
  }, [_c('span', [_vm._v("Go")])])], 1), _c('div', {
    staticClass: "mt-2"
  }), _vm.loading ? _c(VProgressLinear, {
    attrs: {
      "color": "deep-purple accent-4",
      "indeterminate": "",
      "rounded": "",
      "height": "6"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "results"
  }, _vm._l(_vm.searchResults, function (result) {
    return _c('div', {
      staticClass: "result"
    }, [_c('h3', [_c('span', [_vm._v(_vm._s(result.table) + " " + _vm._s(result.res.length))]), result.res.length === 20 ? _c('span', [_vm._v(" (limit)")]) : _vm._e()]), _c('div', {
      staticClass: "tableWrap"
    }, [result.res.length > 0 ? _c('table', {
      staticClass: "resultTable"
    }, [_c('tr', [_vm._l(_vm.getColumns(result.res), function (column) {
      return _c('th', [_vm._v(_vm._s(column))]);
    }), _c('td')], 2), _vm._l(result.res, function (item) {
      return _c('tr', [_vm._l(_vm.getColumns(result.res), function (column) {
        return _c('td', [_vm._v(_vm._s(_vm.toStringWithTrucate(item[column])))]);
      }), _c('td', {
        on: {
          "click": function click($event) {
            return _vm.log(item);
          }
        }
      }, [_c(VBtn, {
        attrs: {
          "small": "",
          "color": "primary",
          "outlined": ""
        }
      }, [_vm._v("log")])], 1)], 2);
    })], 2) : _vm._e()])]);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };