import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VForm, {
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VCol, {
    staticClass: "client-prefix",
    attrs: {
      "cols": "6",
      "sm": "8",
      "md": "8"
    }
  }, [_c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "label": _vm.prefixLabel,
      "required": "",
      "rules": _vm.prefixRules,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.newClient.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "prefix", $$v);
      },
      expression: "newClient.prefix"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "4"
    }
  }, [_c(VCheckbox, {
    staticClass: "add-suffix",
    attrs: {
      "label": "Suffix anhängen"
    },
    on: {
      "change": function change($event) {
        return _vm.clientOnChangeLabel();
      }
    },
    model: {
      value: _vm.newClient.addSuffix,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "addSuffix", $$v);
      },
      expression: "newClient.addSuffix"
    }
  })], 1)], 1), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "label": "Praxis-Name",
      "required": "",
      "rules": [_vm.requiredRule],
      "dense": ""
    },
    model: {
      value: _vm.newClient.praxis,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "praxis", $$v);
      },
      expression: "newClient.praxis"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "label": "Praxis-Name kurz",
      "required": "",
      "dense": "",
      "rules": [_vm.requiredRule]
    },
    model: {
      value: _vm.newClient.praxisKurzName,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "praxisKurzName", $$v);
      },
      expression: "newClient.praxisKurzName"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "Strasse"
    },
    model: {
      value: _vm.newClient.strasse,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "strasse", $$v);
      },
      expression: "newClient.strasse"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "PLZ"
    },
    model: {
      value: _vm.newClient.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "plz", $$v);
      },
      expression: "newClient.plz"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "Stadt"
    },
    model: {
      value: _vm.newClient.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "stadt", $$v);
      },
      expression: "newClient.stadt"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "EMail (wird als Antwortadresse bei Mails verwendet)"
    },
    model: {
      value: _vm.newClient.praxisEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "praxisEmail", $$v);
      },
      expression: "newClient.praxisEmail"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "Telefon"
    },
    model: {
      value: _vm.newClient.praxisTelefon,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "praxisTelefon", $$v);
      },
      expression: "newClient.praxisTelefon"
    }
  }), _c(VTextField, {
    staticClass: "mb-1",
    attrs: {
      "dense": "",
      "label": "Website"
    },
    model: {
      value: _vm.newClient.website,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "website", $$v);
      },
      expression: "newClient.website"
    }
  }), _c(VCombobox, {
    attrs: {
      "items": _vm.allFeatures,
      "label": "Features",
      "multiple": "",
      "small-chips": "",
      "chips": "",
      "deletable-chips": "",
      "required": "",
      "rules": [_vm.requiredRule]
    },
    model: {
      value: _vm.newClient.features,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "features", $$v);
      },
      expression: "newClient.features"
    }
  }), _c(VCombobox, {
    attrs: {
      "label": "Views-Erlaubt (CSV)",
      "multiple": "",
      "required": "",
      "rules": [_vm.requiredRule]
    },
    model: {
      value: _vm.newClient.queriesAllowed,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "queriesAllowed", $$v);
      },
      expression: "newClient.queriesAllowed"
    }
  }), _c(VCombobox, {
    attrs: {
      "label": "Views-Verboten (CSV)",
      "multiple": "",
      "required": "",
      "rules": [_vm.requiredRule]
    },
    model: {
      value: _vm.newClient.queriesPermitted,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "queriesPermitted", $$v);
      },
      expression: "newClient.queriesPermitted"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Bot SSH-Port",
      "type": "number",
      "hint": _vm.hasMetricsFeature() ? "Wird kein Port angegeben, wird der nächste freie Port verwendet." : "Nur nötig mit metrics",
      "persistent-hint": "",
      "disabled": !_vm.hasMetricsFeature()
    },
    model: {
      value: _vm.newClient.botsshport,
      callback: function callback($$v) {
        _vm.$set(_vm.newClient, "botsshport", $$v);
      },
      expression: "newClient.botsshport"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };