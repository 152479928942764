import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.showDialogue,
      callback: function callback($$v) {
        _vm.showDialogue = $$v;
      },
      expression: "showDialogue"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("r4c Abo anlegen")]), _c(VCardText, [_c(VContainer, [_c(VRow, {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "sm": "4",
      "center": ""
    }
  }, [_c('label', [_vm._v("Abo starten")])]), _c(VCol, {
    attrs: {
      "sm": "8",
      "center": ""
    }
  }, [_c('Datepicker', {
    ref: "r4cAboDate",
    attrs: {
      "label": "Abo Start",
      "prepend-icon": "event"
    }
  })], 1), _c(VCol, {
    attrs: {
      "sm": "4",
      "center": ""
    }
  }, [_c('label', [_vm._v("Factoring")])]), _c(VCol, {
    attrs: {
      "sm": "8",
      "center": ""
    }
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.factoringLicenseSelection,
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.selectedFactoringLicense,
      callback: function callback($$v) {
        _vm.selectedFactoringLicense = $$v;
      },
      expression: "selectedFactoringLicense"
    }
  })], 1), _c(VCol, {
    attrs: {
      "sm": "4",
      "center": ""
    }
  }, [_c('label', [_vm._v("Documents")])]), _c(VCol, {
    attrs: {
      "sm": "8",
      "center": ""
    }
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.documentsLicenseSelection,
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.selectedDocumentsLicense,
      callback: function callback($$v) {
        _vm.selectedDocumentsLicense = $$v;
      },
      expression: "selectedDocumentsLicense"
    }
  })], 1), _c(VCol, {
    attrs: {
      "sm": "4",
      "center": ""
    }
  }, [_c('label', [_vm._v("Anamnese")])]), _c(VCol, {
    attrs: {
      "sm": "8",
      "center": ""
    }
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.anamneseLicenseSelection,
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.selectedAnamneseLicense,
      callback: function callback($$v) {
        _vm.selectedAnamneseLicense = $$v;
      },
      expression: "selectedAnamneseLicense"
    }
  })], 1), _c(VCol, {
    attrs: {
      "sm": "4",
      "center": ""
    }
  }, [_c('label', [_vm._v("Testperiode")])]), _c(VCol, {
    attrs: {
      "sm": "8",
      "center": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "type": "number"
    },
    model: {
      value: _vm.testperiode,
      callback: function callback($$v) {
        _vm.testperiode = $$v;
      },
      expression: "testperiode"
    }
  })], 1)], 1)], 1)], 1), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeR4cSubscriptionDialog(false);
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeR4cSubscriptionDialog(true);
      }
    }
  }, [_vm._v("Anlegen")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };