import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "treeWrap"
  }, [_c('div', {
    staticClass: "itemHeader",
    "class": {
      expanded: _vm.expanded,
      isActive: _vm.isActive
    },
    style: {
      "--depth": 20 - _vm.depth,
      "--sticky-top": _vm.depth * 28 + "px"
    },
    on: {
      "click": _vm.itemClicked
    }
  }, [_vm.type === "DIRECTORY" ? [_vm.expanded ? _c(VIcon, [_vm._v("mdi-folder-open-outline")]) : _c(VIcon, [_vm._v("mdi-folder-outline")])] : _vm._e(), _vm.type === "FILE" ? _c(VIcon, [_vm._v("mdi-file")]) : _vm._e(), _vm.isPinned ? _c(VIcon, [_vm._v("mdi-pin")]) : _vm._e(), _vm.itemError ? _c(VIcon, [_vm._v("mdi-alert-circle-outline")]) : _vm._e(), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(_vm._s(_vm.pathBasename))]), _c('div', {
    staticClass: "spacer"
  }), _vm.children && _vm.children.length > 0 ? _c('span', {
    staticClass: "childCount"
  }, [_vm._v(_vm._s(_vm.children.length))]) : _vm._e(), _vm.type === "FILE" ? _c(VBtn, {
    attrs: {
      "small": "",
      "elevation": "0",
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm._downloadFile
    }
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("far fa-download")]), _vm.type === "FILE" ? _c('span', [_vm._v(_vm._s(_vm.fileInfo ? _vm.formatFileSize(_vm.fileInfo.size) : ''))]) : _vm._e()], 1) : _vm._e(), _vm.loading ? _c(VIcon, {
    staticClass: "fa-spin",
    attrs: {
      "spin": ""
    }
  }, [_vm._v("fa-loader")]) : _vm._e()], 2), _vm.expanded ? _c('div', {
    staticClass: "children"
  }, _vm._l(_vm.childrenSorted, function (child) {
    return _c('AsyncFileTree', {
      key: child,
      staticClass: "item",
      attrs: {
        "r4chost": _vm.r4chost,
        "cid": _vm.cid,
        "depth": _vm.depth + 1,
        "path": _vm.path + "/" + child,
        "preventChildLoad": _vm.preventChildLoadForChildren
      }
    });
  }), 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };