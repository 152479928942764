import { IRoseAGGridColumn, dateFormatter } from '@rose/common-ui';
import { IRoseSubscription } from '../../../../types';

export function subscriptionSubscriptionColumns(smallScreen: boolean): IRoseAGGridColumn<IRoseSubscription>[] {
  return [
    {
      headerName: 'Customer-ID',
      field: 'customer',
      sort: 'asc',
      cellRenderer: 'ClientsClientIdCell',
      headerTooltip: 'Eindeutige ID des Kunden',
      width: 170,
      pinned: smallScreen ? undefined : 'left',
    },
    {
      headerName: 'Subscription-ID',
      field: 'id',
      headerTooltip: 'Eindeutige ID des Abonnements',
      width: 240,
    },
    {
      headerName: 'Feature',
      field: 'feature',
      headerTooltip: 'Feature des Abonnements',
      width: 200,
    },
    {
      headerName: 'Testzeitraum-Start',
      field: 'trialstart',
      headerTooltip: 'Start des Testzeitraums',
      width: 140,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Testzeitraum-Ende',
      field: 'trialend',
      headerTooltip: 'Ende des Testzeitraums',
      width: 140,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Start',
      field: 'start',
      headerTooltip: 'Start des Abonnements',
      width: 140,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Ende',
      field: 'cancelAtDate',
      headerTooltip: 'Ende des Abonnements',
      width: 140,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'gekündigt',
      field: 'cancelAtPeriodEnd',
      headerTooltip: 'Wird das Abonnement gekündigt?',
      width: 100,
    },
    {
      headerName: 'gekündigt am',
      field: 'cancelledAt',
      headerTooltip: 'Kündigungsdatum',
      width: 140,
      valueFormatter: dateFormatter,
      flex: 1,
    },
  ];
}
