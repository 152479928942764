import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import _ from 'lodash';
import { defineComponent } from 'vue';
import { ShortFeaturesMap } from '@rose/types';
export default defineComponent({
  name: 'FeaturesCell',
  data: function data() {
    return {
      params: null,
      featuresShort: []
    };
  },
  beforeMount: function beforeMount() {
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a;
      if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
        this.featuresShort = _(this.params.value).filter(function (k) {
          return !!ShortFeaturesMap[k];
        }).map(function (k) {
          return ShortFeaturesMap[k];
        }).uniqBy(function (k) {
          return k["short"];
        }).orderBy([function (k) {
          return k["short"].length;
        }, function (k) {
          return k["short"].toLowerCase();
        }], ['asc', 'asc']).compact().value();
      }
    }
  }
});