import { defineComponent } from 'vue';
import CopyText from '@/components/globals/CopyTextComponent.vue';
import { isCharlyAnalyticsCustomer } from '@/../../base/';
export default defineComponent({
  name: 'ClientsClientIdCell',
  components: {
    CopyText: CopyText
  },
  data: function data() {
    return {
      params: null,
      info: null,
      row: null
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    isCharlyAnalyticsCustomer: isCharlyAnalyticsCustomer,
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a, _b;
      if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
        this.info = this.params.value;
      }
      if ((_b = this.params) === null || _b === void 0 ? void 0 : _b.data) {
        this.row = this.params.data;
      }
    }
  }
});