var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap view-top-padding mainview-padding"
  }, [_c('div', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.clientCount) + " Kunden")]), _vm.loading ? _c('div', {
    staticClass: "loader"
  }, [_c('span', [_vm._v("loading...")])]) : _c('div', {
    staticClass: "map-wrap"
  }, [_c('l-map', {
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center,
      "options": _vm.mapOptions
    },
    on: {
      "update:center": _vm.centerUpdate,
      "update:zoom": _vm.zoomUpdate
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.mapUrl,
      "attribution": _vm.mapAttribution
    }
  }), _vm._l(_vm.locations, function (l) {
    return _c('l-marker', {
      key: l.cid,
      attrs: {
        "latLng": [l.lat, l.lon],
        "icon": _vm.icon
      }
    }, [_c('l-popup', [_c('div', {
      staticClass: "popup-name"
    }, [_vm._v(_vm._s(l.pn))]), _c('div', {
      staticClass: "popup-cid"
    }, [_vm._v(_vm._s(l.cid))])])], 1);
  })], 2)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };