import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "x-small": ""
    },
    on: {
      "click": _vm.remailInvoice
    }
  }, [_vm._v("Als Mail versenden")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };