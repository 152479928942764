import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {},
      value: ''
    };
  },
  computed: {
    canSave: function canSave() {
      return this.value !== this.params.value;
    }
  },
  beforeMount: function beforeMount() {
    this.value = this.params.value;
  },
  methods: {
    onChange: function onChange(event) {
      var target = event.target;
      this.value = target.value;
    },
    onCommit: function onCommit(event) {
      event.preventDefault();
      event.stopPropagation();
      this.params.context.clicked(this.params, this.value);
    },
    refresh: function refresh(params) {
      this.params = params;
      this.value = params.value;
    }
  }
});