import { isArray, compact } from 'lodash';
import { defineComponent } from 'vue';
import { notifySuccess, notifyError } from '@/services/helpers';
export default defineComponent({
  name: 'ClientIdCell',
  data: function data() {
    return {
      params: null,
      clientIds: []
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a;
      if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
        if (isArray(this.params.value)) {
          this.clientIds = compact(this.params.value);
        } else {
          this.clientIds = compact([this.params.value]);
        }
      }
    },
    copySuccess: function copySuccess() {
      notifySuccess('Copied!');
    },
    copyError: function copyError(value) {
      notifyError('Copy failed: ' + value);
    }
  }
});