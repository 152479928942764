import { defineComponent } from 'vue';
export default defineComponent({
  props: ['name', 'status', 'host', 'restartAction', 'cleanCacheAction'],
  data: function data() {
    return {};
  },
  methods: {
    restart: function restart() {
      this.restartAction(this.host);
    },
    cleanCache: function cleanCache() {
      this.cleanCacheAction(this.host);
    }
  }
});