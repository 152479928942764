import { TinyEmitter } from 'tiny-emitter';

const notifyBus = new TinyEmitter();

export function notifySuccess(message: string) {
  notifyBus.emit('success', message);
}

export function notifyError(message: string) {
  notifyBus.emit('error', message);
}

export function registerNotifyHandler(handler: {
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
}) {
  notifyBus.on('success', handler.notifySuccess);
  notifyBus.on('error', handler.notifyError);
}
