var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('BarChart', {
    attrs: {
      "chartData": _vm.datacollection,
      "height": 300
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };