import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BooleanIconCell',
  data: function data() {
    return {
      params: null,
      isTrue: null
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a;
      if (typeof ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) !== 'undefined') {
        this.isTrue = this.params.value;
      }
    }
  }
});