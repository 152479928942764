import { defineComponent } from 'vue';
import { roseDayjs, createUTC } from '../../../../base';
import { globalSearchStore } from '@/state/globalSearch.state';
export default defineComponent({
  name: 'ClientImportCell',
  data: function data() {
    return {
      params: null,
      importInfos: null,
      incError: false,
      fullError: false
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues();
      return true;
    },
    goToImport: function goToImport(event) {
      var _a, _b, _c, _d;
      if (event.target.id === 'i') {
        globalSearchStore.setSearchInput(((_b = (_a = this.importInfos) === null || _a === void 0 ? void 0 : _a.incremental) === null || _b === void 0 ? void 0 : _b.importid) || '');
        this.$router.push('/import');
      }
      if (event.target.id === 'f') {
        globalSearchStore.setSearchInput(((_d = (_c = this.importInfos) === null || _c === void 0 ? void 0 : _c.full) === null || _d === void 0 ? void 0 : _d.importid) || '');
        this.$router.push('/import');
      }
    },
    setValues: function setValues() {
      var _a, _b, _c;
      if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
        this.importInfos = this.params.value;
        if ((_b = this.importInfos) === null || _b === void 0 ? void 0 : _b.incremental) {
          var incStartTime = roseDayjs(this.importInfos.incremental.started);
          var hoursDiff = roseDayjs.duration(createUTC().diff(incStartTime)).asHours();
          this.incError = (this.importInfos.incremental.duration || 0) > 10 * 60 || hoursDiff > 1 || this.importInfos.incremental.errorcount > 0;
        }
        if ((_c = this.importInfos) === null || _c === void 0 ? void 0 : _c.full) {
          var fullStartTime = roseDayjs(this.importInfos.full.started);
          var _hoursDiff = roseDayjs.duration(createUTC().diff(fullStartTime)).asHours();
          this.fullError = (this.importInfos.full.duration || 0) > 4 * 60 * 60 || _hoursDiff > 25 || this.importInfos.full.errorcount > 0;
        }
      }
    }
  }
});