import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "dashboard-container"
  }, [_vm.filteredDashBoardItems && _vm.filteredDashBoardItems.length > 0 ? _c('div', {
    staticClass: "sorting"
  }, [_c('span', {
    staticClass: "sort-label"
  }, [_vm._v("Sortierung:")]), _c(VBtnToggle, {
    staticClass: "sort-by",
    attrs: {
      "mandatory": "",
      "dense": ""
    },
    model: {
      value: _vm.sortingProperty,
      callback: function callback($$v) {
        _vm.sortingProperty = $$v;
      },
      expression: "sortingProperty"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": "activity"
    }
  }, [_vm._v("Activity")]), _c(VBtn, {
    attrs: {
      "value": "score"
    }
  }, [_vm._v("Score")]), _c(VBtn, {
    attrs: {
      "value": "heute-score"
    }
  }, [_vm._v("Heute-Score")]), _c(VBtn, {
    attrs: {
      "value": "abrechnungs-score"
    }
  }, [_vm._v("Abrechnungs-Score")])], 1), _c(VBtnToggle, {
    staticClass: "sort-direction",
    attrs: {
      "mandatory": "",
      "dense": ""
    },
    model: {
      value: _vm.sortingDirection,
      callback: function callback($$v) {
        _vm.sortingDirection = $$v;
      },
      expression: "sortingDirection"
    }
  }, [_c(VBtn, {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Aufsteigend",
      expression: "\"Aufsteigend\""
    }],
    attrs: {
      "value": "asc"
    }
  }, [_c(VIcon, [_vm._v("fa-arrow-up-small-big")])], 1), _c(VBtn, {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Absteigend",
      expression: "\"Absteigend\""
    }],
    attrs: {
      "value": "desc"
    }
  }, [_c(VIcon, [_vm._v("fa-arrow-down-big-small")])], 1)], 1)], 1) : _vm._e(), _vm.minFirstAppointment && _vm.maxLastAppointment && _vm.enableAdmin ? _c(VAlert, {
    staticClass: "termin-range",
    attrs: {
      "type": "info"
    }
  }, [_vm._v("Termine heute: " + _vm._s(_vm.formatTime(_vm.minFirstAppointment.firstAppointment)) + " (" + _vm._s(_vm.minFirstAppointment.firstAppointment.extid) + "/" + _vm._s(_vm.minFirstAppointment.cid) + "/" + _vm._s(_vm.minFirstAppointment.clientName) + ") - " + _vm._s(_vm.formatTime(_vm.maxLastAppointment.lastAppointment)) + " (" + _vm._s(_vm.maxLastAppointment.lastAppointment.extid) + "/" + _vm._s(_vm.maxLastAppointment.cid) + "/" + _vm._s(_vm.maxLastAppointment.clientName) + ")")]) : _vm._e(), _vm.enableAdmin && _vm.filteredDashBoardItems && _vm.filteredDashBoardItems.length > 0 ? _c('div', {
    staticClass: "table-filters"
  }, [_vm._l(_vm.tableFilters, function (g) {
    return [_c('div', {
      key: g.name,
      staticClass: "table-filter-group"
    }, [_vm._v(_vm._s(g.name) + ":")]), _vm._l(g.filters, function (c, key, i) {
      return _c('div', {
        key: g.name + c.name,
        staticClass: "table-filter",
        "class": g["class"],
        style: {
          backgroundColor: g.bg,
          color: g.fg,
          marginLeft: i === 0 ? 10 : 0
        },
        attrs: {
          "title": g.name + " (" + c.d.percentage + "%)"
        },
        on: {
          "click": function click($event) {
            return _vm.setFilterExpression(c.filterExpression);
          }
        }
      }, [_c('div', {
        staticClass: "name"
      }, [_vm._v(_vm._s(c.name) + " " + _vm._s(i) + ":")]), _c('div', {
        staticClass: "count"
      }, [_vm._v(_vm._s(c.d.count))])]);
    })];
  })], 2) : _vm._e(), _c('div', {
    staticClass: "dashboard-cards"
  }, _vm._l(_vm.filteredDashBoardItems, function (item) {
    return _c('div', {
      key: item.cid,
      staticClass: "client-card"
    }, [_c(VCard, [_c(VCardText, [_c('div', {
      staticClass: "client-info"
    }, [_vm.enableAdmin ? _c(VIcon, {
      attrs: {
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.logItem(item);
        }
      }
    }, [_vm._v("fa-terminal")]) : _c(VIcon, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "Zu Praxis wechseln",
        expression: "\"Zu Praxis wechseln\""
      }],
      attrs: {
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.changeCID(item);
        }
      }
    }, [_vm._v("fa-arrow-up-right-from-square")]), _c('h3', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: item.cid,
        expression: "item.cid"
      }]
    }, [_vm._v(_vm._s(item.cid))]), _c('div', {
      staticClass: "tags"
    }, _vm._l(item.clientTags, function (tag) {
      return _c(VChip, {
        key: tag,
        attrs: {
          "x-small": ""
        }
      }, [_vm._v(_vm._s(tag))]);
    }), 1), _c('div', {
      staticClass: "bots-icons"
    }, [_c('div', {
      staticClass: "notting"
    }), item.botOnline ? _c(VIcon, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "connector-Kommandoverbindung online",
        expression: "\"connector-Kommandoverbindung online\""
      }]
    }, [_vm._v("fa-check")]) : _c(VIcon, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "connector-Kommandoverbindung offline",
        expression: "\"connector-Kommandoverbindung offline\""
      }]
    }, [_vm._v("far fa-times-circle")]), item.sshOnline ? _c(VIcon, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "connector-Serviceverbindung online",
        expression: "\"connector-Serviceverbindung online\""
      }]
    }, [_vm._v("fa-check")]) : _c(VIcon, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "connector-Serviceverbindung offline",
        expression: "\"connector-Serviceverbindung offline\""
      }]
    }, [_vm._v("far fa-times-circle")])], 1)], 1), _c('div', {
      staticClass: "client-name-and-features"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "Praxisname",
        expression: "\"Praxisname\""
      }],
      staticClass: "client-name"
    }, [_vm._v(_vm._s(item.clientName))]), _vm.isCA(item) ? _c(VChip, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "charly analytics",
        expression: "\"charly analytics\""
      }],
      staticClass: "mr-1 pl-2 pr-2",
      attrs: {
        "x-small": "",
        "label": "",
        "pill": "",
        "color": "#ffdc00"
      }
    }, [_c('div', {
      staticClass: "ca"
    }, [_vm._v("ca")])]) : _c('div', {
      staticClass: "notting"
    }), item.pvsInfo && item.pvsInfo.typ ? _c(VChip, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: item.pvsInfo.typ,
        expression: "item.pvsInfo.typ"
      }],
      staticClass: "mr-1 pl-2 pr-2",
      attrs: {
        "x-small": "",
        "label": "",
        "pill": "",
        "color": _vm.getPVSColor(item.pvsInfo.typ)
      }
    }, [_c('div', {
      staticClass: "pvs"
    }, [_vm._v(_vm._s(item.pvsInfo.typ[0]))])]) : _vm._e(), _c('div', {
      staticClass: "spacer"
    }), _c('div', {
      staticClass: "features"
    }, _vm._l(item.shortFeatures, function (f) {
      return _c(VChip, {
        directives: [{
          name: "tooltip",
          rawName: "v-tooltip",
          value: f["long"],
          expression: "f.long"
        }],
        key: f["short"],
        staticClass: "pl-2 pr-2 mr-1",
        attrs: {
          "x-small": "",
          "label": "",
          "pill": "",
          "color": f.color,
          "text-color": f.textColor
        }
      }, [_c('div', {
        staticClass: "span"
      }, [_vm._v(_vm._s(f["short"]))])]);
    }), 1)], 1), _c('div', {
      staticClass: "collect"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: "Letzter Update-Import",
        expression: "\"Letzter Update-Import\""
      }],
      staticClass: "collect-item"
    }, [_c(VIcon, {
      staticClass: "collect-icon"
    }, [_vm._v("far fa-hourglass")]), _c('CopyText', {
      "class": _vm.hoursAlarmCssClass(item.lastIncrementalImport, 1, "bold-red"),
      attrs: {
        "value": item.lastIncrementalImport,
        "formatter": _vm.formatDateTime
      }
    }), item.incrementalImportStatistics && item.incrementalImportStatistics.avg ? _c('span', {
      staticClass: "import-statistics"
    }, [_vm._v("(" + _vm._s(item.incrementalImportStatistics && _vm.formatNumeral(item.incrementalImportStatistics.avg / 60)) + " m)")]) : _vm._e()], 1), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('div', _vm._g({
            staticClass: "collect-item"
          }, on), [_c(VIcon, {
            staticClass: "collect-icon"
          }, [_vm._v("far fa-hourglass-end")]), _c('CopyText', {
            "class": _vm.hoursAlarmCssClass(item.lastFullImport, 25, "bold-red"),
            attrs: {
              "value": item.lastFullImport,
              "formatter": _vm.formatDateTime
            }
          }), item.fullImportStatistics && item.fullImportStatistics.avg ? _c('span', {
            staticClass: "import-statistics"
          }, [_vm._v("(" + _vm._s(item.fullImportStatistics && _vm.formatNumeral(item.fullImportStatistics.avg / 60)) + " m)")]) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Letzter Komplett-Import")])])], 1), item.clientActivity ? _c('div', {
      staticClass: "client-activity-chart"
    }, [_c('ClientActivityChartComponent', {
      attrs: {
        "chartData": item.clientActivity,
        "features": item.shortFeatures
      }
    })], 1) : _c('div', {
      staticClass: "client-activity-chart"
    }, [_c('span', [_vm._v("Keine Aktivitäts-Daten vorhanden")])]), _c('div', {
      staticClass: "users"
    }, [_c(VIcon, {
      staticClass: "users-icon"
    }, [_vm._v("fa-user")]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('span', _vm._g({
            staticClass: "users-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.aktivePatienten)) + " / " + _vm._s(_vm.formatNumeral(item.neuPatienten)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Aktive Patienten (mit Umsatz letztes Jahr) / Neu-Patienten (letzte 3 Monate)")])]), _c(VIcon, {
      staticClass: "users-icon"
    }, [_vm._v("far fa-hospital")]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var on = _ref3.on;
          return [_c('span', _vm._g({
            staticClass: "users-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.anzahlBehandler.za)) + " / " + _vm._s(_vm.formatNumeral(item.anzahlBehandler.pzr)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("ZA / PZR (billable)")])]), _c(VIcon, {
      staticClass: "users-icon"
    }, [_vm._v("far fa-users")]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref4) {
          var on = _ref4.on;
          return [_c('span', _vm._g({
            staticClass: "users-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.anzahlBenutzer)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("rose-User")])])], 1), _c('table', [_c('tr', [_c('th'), _c('th', [_vm._v(_vm._s(_vm.lastYear))]), _c('th', [_vm._v(_vm._s(_vm.year))])]), _c('tr', {
      staticClass: "stripe-color-row"
    }, [_c('td', {
      staticClass: "table-icon"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref5) {
          var on = _ref5.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-dollar-sign")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Honorarumsatz")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref6) {
          var on = _ref6.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.umsatzLetztesJahr)) + " €")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Honorarumsatz")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref7) {
          var on = _ref7.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.umsatzAktuellesJahr)) + " €")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Honorarumsatz")])])], 1)]), _c('tr', [_c('td', {
      staticClass: "table-icon"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref8) {
          var on = _ref8.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("far fa-calendar")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Termine")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref9) {
          var on = _ref9.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.termineLetztesJahr)) + " T")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Termine")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref10) {
          var on = _ref10.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.termineAktuellesJahr)) + " T")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Termine")])])], 1)]), _c('tr', {
      staticClass: "stripe-color-row"
    }, [_c('td', {
      staticClass: "table-icon"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref11) {
          var on = _ref11.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("far fa-calendar-week")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Angebotene Behandlungszeit")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref12) {
          var on = _ref12.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.angeboteneBehandlungszeitAktuellesJahr / 60)) + " h")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Angebotene Behandlungszeit")])])], 1), _c('td', {
      staticClass: "table-content"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref13) {
          var on = _ref13.on;
          return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.formatNumeral(item.angeboteneBehandlungszeitLetztesJahr / 60)) + " h")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Angebotene Behandlungszeit")])])], 1)])]), _c('div', {
      staticClass: "scores-etc"
    }, [item.score ? _c('div', {
      staticClass: "score"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref14) {
          var on = _ref14.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-bug")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Fail-Score")])]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref15) {
          var on = _ref15.on;
          return [_c('span', _vm._g({
            staticClass: "score-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.score.total)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("connectedClients: " + _vm._s(item.score.connectedClients))]), _c('br'), _c('span', [_vm._v("Letzter Full Import: " + _vm._s(item.score.fullImportHoursDiff))]), _c('br'), _c('span', [_vm._v("Letzter Update Import: " + _vm._s(item.score.incrementalImportHoursDiff))]), _c('br'), _vm.enableAdmin ? _c('span', [_vm._v("SSH-Port: " + _vm._s(item.score.sshPort))]) : _vm._e(), _vm.enableAdmin ? _c('br') : _vm._e(), _c('span', [_vm._v("Uptime: " + _vm._s(item.score.uptimeHoursDiff))])])], 1) : _c('div', {
      staticClass: "score"
    }, [_vm._v("Kein Score")]), item.clientActivity && item.clientActivity.metrics && item.clientActivity.metrics.lastmonth ? _c('div', {
      staticClass: "score"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref16) {
          var on = _ref16.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-chart-simple")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Activity-Score")])]), _c('div', {
      staticClass: "score-text"
    }, [_vm._v(_vm._s(_vm.formatNumeral(item.clientActivity.metrics.lastmonth.sum)))])], 1) : _vm._e(), item.heuteScore ? _c('div', {
      staticClass: "score"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref17) {
          var on = _ref17.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-check-square")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Heute-Score")])]), _c('div', {
      staticClass: "score-text"
    }, [_vm._v(_vm._s(_vm.formatNumeral(item.heuteScore.gesamt.avg)))])], 1) : _vm._e(), item.hkpCounts ? _c('div', {
      staticClass: "score"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref18) {
          var on = _ref18.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-file")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("HKPs-Stats")])]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref19) {
          var on = _ref19.on;
          return [_c('span', _vm._g({
            staticClass: "score-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.hkpCounts.genehmigtOhneTermin)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Genehmigt ohne Folgetermin: " + _vm._s(_vm.formatNumeral(item.hkpCounts.genehmigtOhneTermin)))]), _c('br'), _c('span', [_vm._v("Ohne Folgetermin: " + _vm._s(_vm.formatNumeral(item.hkpCounts.ohneTermin)))]), _c('br'), _c('span', [_vm._v("Genehmigt: " + _vm._s(_vm.formatNumeral(item.hkpCounts.genehmigt)))])])], 1) : _vm._e(), item.abrechnungsScore && item.abrechnungsScore.lastQuarter ? _c('div', {
      staticClass: "score"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref20) {
          var on = _ref20.on;
          return [_c(VIcon, _vm._g({}, on), [_vm._v("fa-euro-sign")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Abrechnungs-Score (letztes Quartal)")])]), _c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref21) {
          var on = _ref21.on;
          return [_c('span', _vm._g({
            staticClass: "score-text"
          }, on), [_vm._v(_vm._s(_vm.formatNumeral(item.abrechnungsScore.lastQuarter.score)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Honorar-Umsatz-Bema: " + _vm._s(_vm._f("euro")(item.abrechnungsScore.lastQuarter.honorarumsatzBema)))]), _c('br'), _c('span', [_vm._v("Honorar-Umsatz-Potential-Bema: " + _vm._s(_vm._f("euro")(item.abrechnungsScore.lastQuarter.umsatzpotentialBema)))]), _c('br'), _c('span', [_vm._v("Von: " + _vm._s(_vm._f("tz")(item.abrechnungsScore.lastQuarter.from, 'DD.MM.YYYY', 'CET')))]), _c('br'), _c('span', [_vm._v("Bis: " + _vm._s(_vm._f("tz")(item.abrechnungsScore.lastQuarter.to, 'DD.MM.YYYY', 'CET')))])])], 1) : _vm._e(), _vm.enableAdmin ? _c('div', {
      staticClass: "links"
    }, [_c(VTooltip, {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref22) {
          var on = _ref22.on;
          return [_c('button', _vm._g({
            on: {
              "click": function click($event) {
                return _vm.navigateToClient(item.cid);
              }
            }
          }, on), [_c(VIcon, [_vm._v("fa-folder-user")])], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Gehe zu Kunde")])])], 1) : _vm._e()])])], 1)], 1);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };