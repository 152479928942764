var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('el-table', {
    ref: "tableRef",
    attrs: {
      "data": _vm.data || [],
      "height": (_vm.height || 500) + "px",
      "size": "mini",
      "stripe": true,
      "highlight-current-row": "",
      "default-sort": _vm.defaultSort
    },
    on: {
      "current-change": _vm.selectionChanged
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_vm.data === null ? _c('span', [_vm._v("Lade...")]) : _c('span', [_vm._v("Keine Einträge")])];
      },
      proxy: true
    }])
  }, [_vm._t("columns")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };