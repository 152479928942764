import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/esnext.iterator.map.js";
import { defineComponent } from 'vue';
import { rules } from '../../../../common-ui';
export default defineComponent({
  props: {
    gutschein: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    forceSponsor: {
      type: Boolean,
      "default": function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      rules: rules
    };
  },
  computed: {
    productsCommaString: {
      get: function get() {
        if (this.gutschein.produkte) {
          return this.gutschein.produkte.join(', ');
        }
        return '';
      },
      set: function set(commaString) {
        // eslint-disable-next-line vue/no-mutating-props
        this.gutschein.produkte = commaString.split(',').map(function (s) {
          return s.trim();
        });
      }
    },
    sponsorSubscriptionLink: function sponsorSubscriptionLink() {
      var _a;
      return "https://dashboard.stripe.com/subscriptions/".concat((_a = this.gutschein.meta) === null || _a === void 0 ? void 0 : _a.sponsorsubscription);
    },
    customerSubscriptionLink: function customerSubscriptionLink() {
      var _a;
      return "https://dashboard.stripe.com/subscriptions/".concat((_a = this.gutschein.meta) === null || _a === void 0 ? void 0 : _a.customersubscription);
    }
  },
  methods: {}
});