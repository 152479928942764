import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VBtn, {
    attrs: {
      "color": _vm.color,
      "x-small": ""
    },
    on: {
      "click": _vm.deleteItem
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("fas fa-" + _vm._s(_vm.icon))])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };