import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.licence ? _c('div', {
    staticClass: "wrap"
  }, [_c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Seriennummer")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "autofocus": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.licence.serialnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "serialnumber", $$v);
      },
      expression: "licence.serialnumber"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("charly-Mandant")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.licence.tenant,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "tenant", $$v);
      },
      expression: "licence.tenant"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Status")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.statusItems,
      "label": "Status",
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "value"
    },
    model: {
      value: _vm.licence.status,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "status", $$v);
      },
      expression: "licence.status"
    }
  })], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };