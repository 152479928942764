import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.onboardingStatus ? _c(VChip, {
    attrs: {
      "color": _vm.backgroundColor,
      "text-color": _vm.textColor,
      "x-small": "",
      "label": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.onboardingStatus))])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };