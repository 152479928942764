import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
import { defineComponent, watch } from 'vue';
import { globalSearchStore } from '@/state/globalSearch.state';
export default defineComponent({
  name: 'ClientFilter',
  props: {
    filterGroup: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Client',
      selectedItem: null
    };
  },
  computed: {
    items: function items() {
      return this.filterGroup.filters;
    }
  },
  watch: {
    selectedItem: {
      handler: function handler(newVal) {
        console.log('selectedItem', newVal);
        this.$emit('filter', newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    watch(function () {
      return globalSearchStore.searchInput;
    }, function (newSearchInput) {
      console.log('QQQQQQQQQQQQQQQ newSearchInput', newSearchInput);
      if (!newSearchInput || !newSearchInput.startsWith(_this.filterGroup.name)) {
        _this.selectedItem = null;
      }
    });
  }
});