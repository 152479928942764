import { reactive } from 'vue';
import { registerNotifyHandler } from '@/../../common-ui/src/services/notifyBus';

export const toastStore = reactive({
  showToast: false,
  toastText: null as string | null,
  setToast(toastText: string) {
    this.showToast = true;
    this.toastText = toastText;
  },
  setShowToast(display: boolean) {
    this.showToast = display;
  },
});

registerNotifyHandler({
  notifySuccess: msg => toastStore.setToast(msg),
  notifyError: msg => toastStore.setToast(msg),
});
