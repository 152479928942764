import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    key: _vm.screenWidth,
    staticClass: "sponsoring"
  }, [_c('div', {
    staticClass: "sponsor-header"
  }, [_c('span', [_vm._v("Sponsoren")]), _c(VBtn, {
    attrs: {
      "small": "",
      "outlined": ""
    },
    on: {
      "click": _vm.newSponsor
    }
  }, [_c('span', [_vm._v("Anlegen")]), _c(VIcon, {
    attrs: {
      "right": ""
    }
  }, [_vm._v("add")])], 1), _c('CreateSponsor', {
    ref: "createSponsor",
    on: {
      "created": _vm.triggerUpdate
    }
  })], 1), _c('div', {
    staticClass: "gutschein-header"
  }, [_c('span', [_vm._v("Gutscheine")]), _c(VBtn, {
    attrs: {
      "small": "",
      "outlined": "",
      "disabled": !_vm.selectedSponsor
    },
    on: {
      "click": _vm.newGutschein
    }
  }, [_c('span', [_vm._v("Anlegen")]), _c(VIcon, {
    attrs: {
      "right": ""
    }
  }, [_vm._v("add")])], 1), _c('CreateGutschein', {
    ref: "createGutschein",
    attrs: {
      "forceSponsor": _vm.selectedSponsor ? _vm.selectedSponsor.id : null
    },
    on: {
      "created": _vm.triggerUpdate
    }
  })], 1), _c('div', {
    staticClass: "sponsor-table"
  }, [_c('rose-master-detail', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c(VCard, {
          attrs: {
            "outlined": ""
          }
        }, [_c('rose-master-table', {
          attrs: {
            "data": _vm.sponsoren,
            "idKey": "id",
            "default-sort": {
              prop: "name",
              order: "ascending"
            },
            "height": "350",
            "URL_KEY_NAME": "selectedSponsor"
          },
          on: {
            "selectionChange": _vm.selectionChangeSponsor
          },
          scopedSlots: _vm._u([{
            key: "columns",
            fn: function fn() {
              return [_c('el-table-column', {
                attrs: {
                  "property": "name",
                  "label": "Name",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.name
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "strasse",
                  "label": "Strasse",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.strasse
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "ort",
                  "label": "Ort",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.ort
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "email",
                  "label": "E-Mail",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.email
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "email",
                  "label": "#",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('span', [_vm._v(_vm._s(_vm.gutscheineForSponsor(scope.row.id).length))])];
                  }
                }])
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedSponsor ? _c(VCard, [_c(VCardTitle, [_c('span', [_vm._v("Sponsor Details")])]), _c(VCardText, [_c(VForm, {
          model: {
            value: _vm.sponsorEditValid,
            callback: function callback($$v) {
              _vm.sponsorEditValid = $$v;
            },
            expression: "sponsorEditValid"
          }
        }, [_c('SponsorFields', {
          attrs: {
            "sponsor": _vm.selectedSponsor
          }
        })], 1)], 1), _c(VCardActions, [_c('rose-confirm-button', {
          attrs: {
            "small": "",
            "description": "Sponsor \"".concat(_vm.selectedSponsor.name, "\" wirklich l\xF6schen?")
          },
          on: {
            "confirmed": _vm.deleteSponsor
          }
        }, [_vm._v("Sponsor löschen")]), _c(VSpacer), _c('rose-change-iban-button', {
          attrs: {
            "heading": "Sponsor: " + _vm.selectedSponsor.name,
            "stripeCustomerId": _vm.selectedSponsor.stripecustomer,
            "stripeCustomerName": _vm.selectedSponsor.name,
            "stripeCustomerEmail": _vm.selectedSponsor.email
          }
        }), _c(VBtn, {
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": !_vm.sponsorEditValid
          },
          on: {
            "click": _vm.saveSponsor
          }
        }, [_vm._v("Speichern")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "gutschein-table"
  }, [_c('rose-master-detail', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c(VCard, {
          attrs: {
            "outlined": ""
          }
        }, [_c('rose-master-table', {
          attrs: {
            "data": _vm.sponsorGutscheine,
            "idKey": "id",
            "default-sort": {
              prop: "code",
              order: "ascending"
            },
            "height": "350",
            "URL_KEY_NAME": "selectedGutschein"
          },
          on: {
            "selectionChange": _vm.selectionChangeGutschein
          },
          scopedSlots: _vm._u([{
            key: "columns",
            fn: function fn() {
              return [_c('el-table-column', {
                attrs: {
                  "property": "code",
                  "label": "Code",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.code
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "monatelaufzeit",
                  "label": "Monatelaufzeit",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.monatelaufzeit
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "prozentualerkundenerlass",
                  "label": "% Erlass",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.prozentualerkundenerlass
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "absoluterkundenerlass",
                  "label": "abs. Erlass",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.absoluterkundenerlass
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "produkte",
                  "label": "Produkte",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.produkte,
                        "formatter": _vm.formatJoinArray
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "eingeloestvon",
                  "label": "Eingelöst von",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.eingeloestvon
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "eingeloestam",
                  "label": "Eingelöst am",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.eingeloestam,
                        "formatter": _vm.formatDateTime
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "notiz",
                  "label": "Notiz",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.notiz
                      }
                    })];
                  }
                }])
              }), _c('el-table-column', {
                attrs: {
                  "property": "createdAt",
                  "label": "Angelegt am",
                  "sortable": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(scope) {
                    return [_c('rose-copy-text', {
                      attrs: {
                        "value": scope.row.createdAt,
                        "formatter": _vm.formatDateTime
                      }
                    })];
                  }
                }])
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedGutschein ? _c(VCard, [_c(VCardTitle, [_c('span', [_vm._v("Gutschein Details")])]), _c(VCardText, [_c(VForm, {
          model: {
            value: _vm.gutscheinEditValid,
            callback: function callback($$v) {
              _vm.gutscheinEditValid = $$v;
            },
            expression: "gutscheinEditValid"
          }
        }, [_c('GutscheinFields', {
          attrs: {
            "gutschein": _vm.selectedGutschein
          }
        })], 1)], 1), _c(VCardActions, [_c('rose-confirm-button', {
          attrs: {
            "small": "",
            "description": "Gutschein \"".concat(_vm.selectedGutschein.code, "\" wirklich L\xF6schen?")
          },
          on: {
            "confirmed": _vm.deleteGutschein
          }
        }, [_vm._v("Gutschein löschen")]), _c(VSpacer), _c(VBtn, {
          attrs: {
            "small": "",
            "color": "warning",
            "disabled": !_vm.gutscheinUnused
          },
          on: {
            "click": _vm.assignGutschein
          }
        }, [_vm._v("Gutschein an Abo")]), _c(VBtn, {
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": !_vm.gutscheinEditValid
          },
          on: {
            "click": _vm.saveGutschein
          }
        }, [_vm._v("Speichern")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };