import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.params.value))]), _c(VMenu, {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": "",
            "x-small": ""
          }
        }, on), [_c(VIcon, {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("fas fa-pen")])], 1)];
      }
    }])
  }, [_c(VList, {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.position, function (p, index) {
    return _c(VListItem, {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.setPosition(p);
        }
      }
    }, [_c(VListItemTitle, [_vm._v(_vm._s(p))])], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };