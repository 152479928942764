import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tags"
  }, _vm._l(_vm.tags, function (tag) {
    return _c(VChip, {
      key: tag,
      staticClass: "pl-2 pr-2 mr-1",
      attrs: {
        "x-small": "",
        "label": "",
        "pill": ""
      }
    }, [_c('div', {
      staticClass: "span"
    }, [_vm._v(_vm._s(tag))])]);
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };