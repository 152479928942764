import { defineComponent } from 'vue';
import { stripeApi } from '@/services/data';
import moment from 'moment';
export default defineComponent({
  name: 'ExtendTestPeriodDialogue',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    stripeInfoComplete: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {
      ibanValid: false,
      ibanElement: {}
    };
  },
  computed: {
    subscriptions: function subscriptions() {
      var _a;
      return (_a = this.stripeInfoComplete) === null || _a === void 0 ? void 0 : _a.subscriptions;
    },
    showDialogue: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    saveSubscriptionTrialEnd: function saveSubscriptionTrialEnd(sub) {
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        return stripeApi.changeSubscriptionTrialEnd(sub.id, moment(sub.trialend, 'DD.MM.YYYY').format('YYYY-MM-DD'));
      }, 'Testzeitraum geändert', true);
      this.showDialogue = false;
    },
    closeShowProlongTestperiodDialog: function closeShowProlongTestperiodDialog() {
      this.showDialogue = false;
    }
  }
});