import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VApp, {
    attrs: {
      "id": "application"
    }
  }, [_c(VSnackbar, {
    model: {
      value: _vm.showSnackbar,
      callback: function callback($$v) {
        _vm.showSnackbar = $$v;
      },
      expression: "showSnackbar"
    }
  }, [_vm._v(_vm._s(_vm.toastText))]), _c(VNavigationDrawer, {
    attrs: {
      "fixed": "",
      "permanent": "",
      "app": "",
      "width": 68
    }
  }, [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('LogoComponent', {
    staticStyle: {
      "width": "45px",
      "height": "45px"
    }
  })], 1), _c('div', {
    staticClass: "nav-items"
  }, [_vm._l(_vm.filteredLinks, function (item) {
    return [_c('router-link', {
      key: item.text,
      attrs: {
        "to": item.path
      }
    }, [_c('div', {
      staticClass: "nav-link"
    }, [_c(VIcon, [_vm._v(_vm._s(item.icon))]), _vm.importCount > 0 && item.text === "Import" ? _c(VBadge, {
      attrs: {
        "color": "green",
        "content": _vm.importCount,
        "offset-x": "15",
        "offset-y": "2"
      }
    }, [_vm._v(_vm._s(item.text))]) : _c('span', [_vm._v(_vm._s(item.text))])], 1)])];
  })], 2)]), _c(VAppBar, {
    staticClass: "mainBar",
    attrs: {
      "app": "",
      "fixed": "",
      "flat": "",
      "dense": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "ml-0 pl-3",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticClass: "route-name"
  }, [_vm._v(_vm._s(_vm.routeName))]), _c(_vm.routeActionComponent, {
    tag: "component",
    on: {
      "routeAction": function routeAction($event) {
        return _vm.routeAction();
      }
    }
  }), _vm.routeShowActionButton ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.routeAction();
            }
          }
        }, on), [_c(VIcon, [_vm._v(_vm._s(_vm.routeShowActionButton))])], 1)];
      }
    }], null, false, 306010353)
  }, [_c('span', [_vm._v(_vm._s(_vm.routeShowActionButtonTooltip))])]) : _vm._e(), _vm.routeShowReloadButton ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.reload();
            }
          }
        }, on), [_c(VIcon, [_vm._v("cached")])], 1)];
      }
    }], null, false, 324834896)
  }, [_c('span', [_vm._v("Daten updaten")])]) : _vm._e()], 1), _vm.routeShowSearchBar ? _c(VCol, {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c(VTextField, {
    directives: [{
      name: "model-bind-view-state",
      rawName: "v-model-bind-view-state",
      value: "search",
      expression: "\"search\""
    }],
    staticClass: "search",
    attrs: {
      "type": "text",
      "placeholder": "suchen",
      "value": _vm.globalSearchStore.searchInput,
      "clearable": "",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.search($event);
      }
    }
  })], 1) : _vm._e(), _c(VSpacer), _c('span', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName))]), _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.logout();
            }
          }
        }, on), [_c(VIcon, [_vm._v("exit_to_app")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Logout")])])], 1), _c(VMain, [_c(VContainer, {
    staticClass: "main-content-wrap",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('router-view', {
    ref: "routeRef"
  })], 1)], 1), _c('AdminFooter')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };