import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex justify-center",
    attrs: {
      "title": "Go to users"
    }
  }, [_c(VBtn, {
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": _vm.clicked
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("far fa-users")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };