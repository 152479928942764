import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.index-of.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "mainWrap"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-grow-1"
  }, [_c('h3', [_vm._v("Request")]), _c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c(VAutocomplete, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "300px"
    },
    style: {
      "z-index": 30
    },
    attrs: {
      "items": _vm.cids,
      "dense": ""
    },
    model: {
      value: _vm.activeCid,
      callback: function callback($$v) {
        _vm.activeCid = $$v;
      },
      expression: "activeCid"
    }
  }), _vm.activeCid && !_vm.r4cForCurrentClientIdConnected ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Kein r4c verbunden")]) : _vm._e(), _c('p', {
    staticClass: "ml-4"
  }, [_vm._v("Patienten-ID:")]), _c(VTextField, {
    staticClass: "ml-1",
    staticStyle: {
      "max-width": "100px"
    },
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.patientId,
      callback: function callback($$v) {
        _vm.patientId = $$v;
      },
      expression: "patientId"
    }
  })], 1), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v("Custom")]), _c('div', {
    staticClass: "d-flex align-baseline mt-2"
  }, [_c(VSelect, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "100px"
    },
    attrs: {
      "items": _vm.httpMethods,
      "dense": ""
    },
    model: {
      value: _vm.httpMethod,
      callback: function callback($$v) {
        _vm.httpMethod = $$v;
      },
      expression: "httpMethod"
    }
  }), _c(VAutocomplete, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "300px"
    },
    style: {
      "z-index": 30
    },
    attrs: {
      "items": _vm.charlyPrefixes,
      "placeholder": "prefix",
      "dense": ""
    },
    model: {
      value: _vm.customRequestPrefix,
      callback: function callback($$v) {
        _vm.customRequestPrefix = $$v;
      },
      expression: "customRequestPrefix"
    }
  }), _c(VTextField, {
    staticClass: "mr-2",
    attrs: {
      "placeholder": "path/to/endpoint",
      "dense": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.runCustomRequest.apply(null, arguments);
      }
    },
    model: {
      value: _vm.customRequestUrl,
      callback: function callback($$v) {
        _vm.customRequestUrl = $$v;
      },
      expression: "customRequestUrl"
    }
  }), _c(VBtn, {
    staticClass: "px-3",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.runCustomRequest
    }
  }, [_c('span', [_vm._v("Send")]), _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "size": "18"
    }
  }, [_vm._v("far fa-rocket")])], 1)], 1), _vm.isHttpMethodWithBody(_vm.httpMethod) ? [_c('h5', [_vm._v("Request Body")]), _c('MonacoEditor', {
    staticStyle: {
      "width": "100%",
      "height": "200px",
      "background": "#eee"
    },
    attrs: {
      "language": "json"
    },
    model: {
      value: _vm.customRequestBody,
      callback: function callback($$v) {
        _vm.customRequestBody = $$v;
      },
      expression: "customRequestBody"
    }
  })] : _vm._e(), _vm._l(_vm.exampleRequests, function (requestGroup) {
    return _c('div', {
      staticClass: "mb-4"
    }, [_c('h4', {
      staticClass: "mt-2"
    }, [_vm._v(_vm._s(requestGroup.group) + " Requests")]), _c('h5', {
      staticClass: "mb-2 d-flex"
    }, [_vm._v("Prefix:"), _c('span', [_vm._v(" " + _vm._s(requestGroup.prefixUrl))])]), _c('div', {
      staticClass: "requestList"
    }, [_vm._l(requestGroup.request, function (request) {
      return [_c(VBtn, {
        staticClass: "px-2",
        attrs: {
          "color": "primary",
          "outlined": ""
        },
        on: {
          "click": function click($event) {
            return _vm.clickedExampleRequest(request, requestGroup.prefixUrl);
          }
        }
      }, [_c('span', {
        staticClass: "bold"
      }, [_vm._v(_vm._s(request.method) + " ")]), _vm._l(_vm.tokenizeUrl(request.url), function (token) {
        return _c('span', [token.type === "string" ? _c('span', [_vm._v(_vm._s(token.str))]) : _c('span', {
          staticClass: "interpolationToken"
        }, [_vm._v(_vm._s(token.str))])]);
      }), !_vm.isHttpMethodWithBody(request.method) ? _c(VIcon, {
        staticClass: "ml-2",
        attrs: {
          "size": "18"
        }
      }, [_vm._v("far fa-rocket")]) : _c(VIcon, {
        staticClass: "ml-2",
        attrs: {
          "size": "18"
        }
      }, [_vm._v("far fa-pen-ruler")])], 2)];
    })], 2)]);
  })], 2), _c('div', {
    staticClass: "d-flex flex-column flex-grow-1"
  }, [_c('h3', {
    staticClass: "d-flex"
  }, [_c('span', [_vm._v("Response")]), _c('div', {
    staticClass: "flex-grow-1"
  }), _vm.loading ? _c(VIcon, {
    staticClass: "fa-beat",
    staticStyle: {
      "--fa-animation-duration": "1s"
    },
    attrs: {
      "size": "30"
    }
  }, [_vm._v("far fa-rocket-launch")]) : _vm.responseIsError ? _c(VIcon, {
    attrs: {
      "size": "30",
      "color": "error"
    }
  }, [_vm._v("far fa-explosion")]) : _c(VIcon, {
    attrs: {
      "size": "30"
    }
  }, [_vm._v("far fa-rocket")])], 1), _vm.loading ? _c(VProgressLinear, {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), !_vm.response ? [_c('div', {
    staticClass: "d-flex pa-8 align-center justify-center flex-grow-1 mt-8"
  }, [!_vm.loading ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "size": "40"
    }
  }, [_vm._v("far fa-earth")]) : _vm._e(), !_vm.loading ? _c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "30"
    }
  }, [_vm._v("far fa-ellipsis")]) : _vm._e(), !_vm.loading ? _c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "30"
    }
  }, [_vm._v("far fa-ellipsis")]) : _vm._e(), !_vm.loading ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "size": "30"
    }
  }, [_vm._v("far fa-ellipsis")]) : _vm._e(), !_vm.loading ? _c(VIcon, {
    attrs: {
      "size": "40"
    }
  }, [_vm._v("far fa-planet-ringed")]) : _vm._e()], 1)] : [_c('div', {
    staticClass: "d-flex"
  }, [_c('h4', [_vm._v(_vm._s(_vm.response.status) + " " + _vm._s(_vm.response.statusText))])]), _c(VTabs, {
    attrs: {
      "background-color": "transparent",
      "dense": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, [_vm._v("Body")]), _c(VTab, [_vm._v("Headers")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, [_c('MonacoEditor', {
    staticStyle: {
      "width": "100%",
      "height": "72vh"
    },
    attrs: {
      "language": "json"
    },
    model: {
      value: _vm.responseData,
      callback: function callback($$v) {
        _vm.responseData = $$v;
      },
      expression: "responseData"
    }
  })], 1), _c(VTabItem, [_vm.response.headers ? _c('pre', [_vm._v(_vm._s(_vm.response.headers))]) : _c('pre', [_vm._v("No headers")])])], 1)]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };