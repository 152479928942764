import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VBtn, {
    attrs: {
      "small": "",
      "color": "warning",
      "disabled": !_vm.stripeCustomer
    },
    on: {
      "click": _vm.startChangeIban
    }
  }, [_vm._v("IBAN Ändern"), _c('rose-change-iban', {
    ref: "changeIbanElm",
    attrs: {
      "heading": _vm.heading,
      "stripeCustomer": _vm.stripeCustomer
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };