import { defineComponent } from 'vue';
export default defineComponent({
  name: 'GoToCell',
  data: function data() {
    return {
      params: {},
      clientid: ''
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    setValues: function setValues() {
      this.clientid = this.params.value;
    },
    clicked: function clicked() {
      void this.$router.push('/users/?search=' + this.clientid);
    }
  }
});