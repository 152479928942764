import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { defineComponent } from 'vue';
import BarChart from './BarChart.vue';
import moment from 'moment';
import * as _ from 'lodash';
import { arrayFilterOnlyUnique } from '@/services/helpers';
export default defineComponent({
  components: {
    BarChart: BarChart
  },
  props: ['chartData', 'options'],
  data: function data() {
    return {
      datacollection: null
    };
  },
  watch: {
    chartData: function chartData() {
      this.datacollection = this.getData();
    }
  },
  methods: {
    getData: function getData() {
      var chartData = this.chartData;
      // collect days
      var labels = _.map(chartData, function (entry) {
        var parsed = moment(entry.time, moment.ISO_8601, true);
        if (parsed.isValid()) {
          return parsed.format('dd DD.MM');
        } else {
          console.warn('User activity time is not in valid date format', entry.tag);
        }
      });
      // distinct days
      labels = labels.filter(arrayFilterOnlyUnique);
      // TODO: fixup missing days
      // for (let i = 0; i < labels.length; i++) {
      //   if (i + 1 === labels.length) { break; }
      //   let curDay = labels[i];
      //   let nextDay = labels[i + 1];
      //   console.log(nextDay)
      //   let curDate = moment(curDay, 'dd DD.MM');
      //   let nextDate = moment(nextDay, 'dd DD.MM');
      //   if (nextDate.add(1, 'day').format('dd DD.MM') !==  curDay) {
      //     console.log('add', nextDate.add(1, 'day').format('dd DD.MM'))
      //   }
      // }
      var values = _.map(labels, function (day) {
        // find every entry for that day and sum count
        var count = _.filter(chartData, function (entry) {
          var entryTime = moment(entry.time, moment.ISO_8601, true);
          return entryTime.format('dd DD.MM') === day;
        }).map(function (entry) {
          return entry.count;
        }).reduce(function (acc, val) {
          return acc + +val;
        }, 0);
        return count;
      });
      labels.reverse();
      values.reverse();
      var formatedData = {
        labels: labels,
        datasets: [{
          label: 'Actions per day',
          backgroundColor: '#f87979',
          data: values
        }]
      };
      return formatedData;
    }
  }
});