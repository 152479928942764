import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.array.index-of.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.currentClientHasR4c && !_vm.isSolutioTeam ? _c(VCard, {
    staticClass: "r4c-infos",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("r4c - #online: " + _vm._s(_vm.selectedClient.countR4C))])]), _c(VCardText, [_c(VContainer, {
    staticClass: "pa-0",
    attrs: {
      "grid-list-md": "",
      "text-xs-center": ""
    }
  }, [_c(VLayout, {
    attrs: {
      "row": "",
      "nowrap": ""
    }
  }, [_c(VFlex, [_c(VBtn, {
    attrs: {
      "small": "",
      "color": "success"
    },
    on: {
      "click": _vm.initR4c
    }
  }, [_vm._v("Init r4c")])], 1), _c(VFlex, [_c(VCombobox, {
    ref: "r4cPatIdInput",
    attrs: {
      "items": _vm.lastEwePatids,
      "dense": "",
      "placeholder": "Patientennummer",
      "clearable": "",
      "persistent-hint": "",
      "hint": "Letzte Patientennummern werden beim Init geladen"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.setR4cPat.apply(null, arguments);
      }
    },
    model: {
      value: _vm.r4cPatId,
      callback: function callback($$v) {
        _vm.r4cPatId = $$v;
      },
      expression: "r4cPatId"
    }
  })], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "small": "",
      "color": "info"
    },
    on: {
      "click": _vm.getAndSetR4cPat
    }
  }, [_vm._v("Set Patient")]), _c(VBtn, {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.copyPatSql(),
      expression: "copyPatSql()"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "info"
    }
  }, [_vm._v("SQL")])], 1)], 1), _c('iframe', {
    key: _vm.selectedClient.cid,
    ref: "r4cFrame",
    staticClass: "r4cFrame",
    attrs: {
      "src": _vm.r4cIframeUrl,
      "height": "28"
    }
  }), _c('div', {
    staticClass: "actions-buttons"
  }, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerFlushRouteCache
    }
  }, [_vm._v("Flush Route Caches")]), _c('rose-confirm-button', {
    attrs: {
      "block": "",
      "x-small": "",
      "actionText": "Flush AVA Caches"
    },
    on: {
      "confirmed": _vm.triggerFlushAVACache
    }
  }, [_vm._v("Flush AVA Cache")]), _c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": _vm.triggerReloadR4cApps
    }
  }, [_vm._v("Reload all " + _vm._s(this.selectedClient.countR4C) + " r4c")]), _vm.failedUploadsStats ? _c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": _vm.triggerRetryUpload
    }
  }, [_vm._v("Retry " + _vm._s(_vm.failedUploadsStats.failedCount) + " failed uploads")]) : _vm._e(), _c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerPraxisDataUpdate
    }
  }, [_vm._v("Trigger Praxisdata update")])], 1)], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };