import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c(VAutocomplete, {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "300px"
    },
    style: {
      "z-index": 30
    },
    attrs: {
      "items": _vm.cids,
      "dense": ""
    },
    model: {
      value: _vm.activeCid,
      callback: function callback($$v) {
        _vm.activeCid = $$v;
      },
      expression: "activeCid"
    }
  }), _vm.activeCid && !_vm.r4cForCurrentClientIdConnected ? _c('h3', {
    staticClass: "warning pa-2"
  }, [_vm._v("Kein r4c verbunden")]) : _vm._e()], 1), _c('div', {
    staticClass: "mainWrap"
  }, [_vm.activeCid && _vm.activeCidR4chost ? _c('div', {
    staticClass: "fileTreeWrap"
  }, [_c('AsyncFileTree', {
    key: _vm.activeCid,
    attrs: {
      "r4chost": _vm.activeCidR4chost,
      "cid": _vm.activeCid,
      "path": "",
      "expand": ""
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "previewFileName"
  }, [_vm._v(_vm._s(_vm.previewFile))]), _vm.previewFileContent ? _c('MonacoEditor', {
    staticStyle: {
      "width": "50vw",
      "height": "73vh"
    },
    model: {
      value: _vm.previewFileContent,
      callback: function callback($$v) {
        _vm.previewFileContent = $$v;
      },
      expression: "previewFileContent"
    }
  }) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };