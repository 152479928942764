import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "charlyUplaods"
  }, [_c('h3', [_vm._v("Failed charly Uploads (" + _vm._s(_vm.failedUploads.length) + ")")]), _c('div', {
    staticClass: "tableWrap"
  }, [_vm.failedUploads.length > 0 ? _c('table', {
    staticClass: "resultTable"
  }, [_c('tr', [_c('td'), _vm._l(_vm.getColumns(_vm.failedUploads), function (column) {
    return _c('th', {
      key: column
    }, [_vm._v(_vm._s(column))]);
  })], 2), _vm._l(_vm.failedUploads, function (fl) {
    return _c('tr', {
      key: fl.id
    }, [_c('td', {
      on: {
        "click": function click($event) {
          return _vm.retry(fl);
        }
      }
    }, [_c(VBtn, {
      attrs: {
        "small": "",
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.log(fl);
        }
      }
    }, [_vm._v("log")]), _c(VBtn, {
      attrs: {
        "small": "",
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.retry(fl);
        }
      }
    }, [_vm._v("retry")])], 1), _vm._l(_vm.getColumns(_vm.failedUploads), function (column) {
      return _c('td', {
        key: column
      }, [_vm._v(_vm._s(_vm.toStringWithTrucate(fl[column])))]);
    })], 2);
  })], 2) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };