import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Neuen Sponsor anlegen")]), _c(VCardText, [_c(VForm, {
    model: {
      value: _vm.sponsorFieldsValid,
      callback: function callback($$v) {
        _vm.sponsorFieldsValid = $$v;
      },
      expression: "sponsorFieldsValid"
    }
  }, [_c('SponsorFields', {
    attrs: {
      "sponsor": _vm.sponsor,
      "createStripe": true
    }
  }), _c('h3', {
    staticClass: "mt-3"
  }, [_vm._v("Zahlungsdaten")]), _c('rose-set-iban', {
    ref: "iban",
    staticClass: "mt-2",
    on: {
      "paymentMethod": function paymentMethod($event) {
        _vm.paymentMethod = $event;
      },
      "ibanValid": function ibanValid($event) {
        _vm.ibanValid = $event;
      }
    }
  })], 1)], 1), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showDialog = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "disabled": !_vm.sponsorFieldsValid || !_vm.ibanValid,
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Anlegen")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };