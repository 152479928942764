import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VMenu, {
    ref: "menu1",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "label": "Datum",
            "persistent-hint": "",
            "prepend-icon": "fa-calendar",
            "readonly": ""
          },
          on: {
            "blur": function blur($event) {
              _vm.date = _vm.parseDate(_vm.dateFormatted);
            }
          },
          model: {
            value: _vm.dateFormatted,
            callback: function callback($$v) {
              _vm.dateFormatted = $$v;
            },
            expression: "dateFormatted"
          }
        }, 'v-text-field', _vm.$attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu1,
      callback: function callback($$v) {
        _vm.menu1 = $$v;
      },
      expression: "menu1"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "no-title": "",
      "locale": "de-de"
    },
    on: {
      "input": function input($event) {
        _vm.menu1 = false;
      }
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };