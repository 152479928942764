import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.importPaused === false ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.pauseImport
          }
        }, on), [_c(VIcon, [_vm._v("pause_circle_outline")])], 1)];
      }
    }], null, false, 1515276320)
  }, [_c('span', [_vm._v("Update-Import pausieren")])]) : _vm._e(), _vm.importPaused === true ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.resumeImport
          }
        }, on), [_c(VIcon, [_vm._v("play_circle_filled")])], 1)];
      }
    }], null, false, 3516501395)
  }, [_c('span', [_vm._v("Update-Import fortsetzen")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };