import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "link-cell-comp"
  }, [_vm.params.linkIcon ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "x-small": "",
      "href": _vm.params.value,
      "target": "_blank"
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(_vm._s(_vm.params.linkIcon))])], 1) : _c('a', {
    attrs: {
      "href": _vm.params.value
    }
  }, [_vm._v(_vm._s(_vm.params.linkText))])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };