var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "version"
  }, [_c('span', [_vm._v("root: " + _vm._s(_vm.versions.root) + ",")]), _c('span', [_vm._v("build: " + _vm._s(_vm.versions.build) + ",")]), _c('span', [_vm._v("commitSha: " + _vm._s(_vm.versions.commitSha))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };