import router from '@/router';
import { login } from '@/services/auth';
import { readUserObject, clearUserObject, saveUserObject } from '@/services/localStorage';
import { ADMIN_LEVEL, RoseLoginError } from '../../../types';

import { reactive } from 'vue';
import { formatApiErrorForUser } from '@rose/common-ui';
import { metricsApi } from '@/services/data';

export const loginStore = reactive({
  pending: false,
  loginErrorString: null as string | null,
  loginError: null as RoseLoginError | null,
  user: readUserObject(),
  get isLoggedIn() {
    return !!this.user;
  },
  get isSolutioTeam() {
    return this.user?.adminLevel === ADMIN_LEVEL.SOLUTIO_TEAM;
  },
  loginStart() {
    this.pending = true;
    this.loginErrorString = null;
  },
  loginSuccess(user: any) {
    saveUserObject(user);
    this.pending = false;
    this.user = user;
  },
  logout() {
    clearUserObject();
    this.user = null;
  },
  login_Error(err: any) {
    this.pending = false;
    this.loginErrorString = formatApiErrorForUser(err);
    if (RoseLoginError.isRoseLoginError(err?.response?.data)) {
      this.loginError = err?.response?.data;
    }
  },
  async login(creds: { email: string; password: string; twoFaCode?: string | undefined }) {
    this.loginStart();
    try {
      const user = await login(creds);
      this.loginSuccess(user);
      void router.push('/');
    } catch (err) {
      console.error(err);

      if (err.response && err.response.status) {
        err.message = err.response.status === 401 ? 'Sie sind nicht berechtigt, darauf zuzugreifen' : err.message;
      } else {
        err.message = 'Server nicht erreichbar';
      }
      this.login_Error(err);
    }
  },
  async loginOtp(otp: string) {
    try {
      const { token } = await metricsApi.auth.loginOtp(otp);
      const user = await metricsApi.auth.getUserFromToken(token);
      this.loginSuccess(user);
      void router.push('/');
    } catch (e) {
      console.error(e);
      this.login_Error(e);
    }
  },
  logoutAction() {
    this.logout();
    void router.push('/login');
  },
});
