var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_vm.loading ? _c('p', [_vm._v("Lädt..")]) : _vm._e(), _c('h3', [_vm._v("Kunden " + _vm._s(_vm.clientSettings.length))]), _c('h3', {
    staticClass: "mt-4"
  }, [_c('span', [_vm._v("Fragebögen " + _vm._s(_vm.questionnaires.length) + ": ")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("recall " + _vm._s(_vm.questionnairesWithRecall.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithRecall.length / _vm.questionnaires.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("skip review " + _vm._s(_vm.questionnairesWithSkipReview.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithSkipReview.length / _vm.questionnaires.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("prefill patient details " + _vm._s(_vm.questionnairesWithPrefillPatientDetails.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithPrefillPatientDetails.length / _vm.questionnaires.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("prefill questions " + _vm._s(_vm.questionnairesWithPrefillQuestions.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithPrefillQuestions.length / _vm.questionnaires.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("external access " + _vm._s(_vm.questionnairesWithExternalAccess.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithExternalAccess.length / _vm.questionnaires.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("en lang " + _vm._s(_vm.questionnairesWithEnLang.length) + " (" + _vm._s(_vm.percent(_vm.questionnairesWithEnLang.length / _vm.questionnaires.length)) + ")")])]), _c('h3', {
    staticClass: "mt-4"
  }, [_c('span', [_vm._v("Elemente " + _vm._s(_vm.elements.length) + ": ")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("questions " + _vm._s(_vm.elementsQuestions.length) + " (" + _vm._s(_vm.percent(_vm.elementsQuestions.length / _vm.elements.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("patient info " + _vm._s(_vm.elementsPatientInfoForm.length) + " (" + _vm._s(_vm.percent(_vm.elementsPatientInfoForm.length / _vm.elements.length)) + ")")]), _c('div', {
    staticClass: "propterty"
  }, [_vm._v("rich text " + _vm._s(_vm.elementsRichText.length) + " (" + _vm._s(_vm.percent(_vm.elementsRichText.length / _vm.elements.length)) + ")")])]), _c('h3', {
    staticClass: "mt-4"
  }, [_c('span', [_vm._v("Questions " + _vm._s(_vm.elementsQuestions.length) + ": ")]), _vm._l(_vm.questionsTypeCounts, function (typeCount) {
    return [_c('div', {
      staticClass: "propterty"
    }, [_vm._v(_vm._s(typeCount.type) + " " + _vm._s(typeCount.count) + " (" + _vm._s(_vm.percent(typeCount.count / _vm.elementsQuestions.length)) + ")")])];
  })], 2), _c('div', {
    staticClass: "d-flex mt-4"
  }, [_c('div', {
    staticClass: "flex d-flex column"
  }, [_c('h3', [_vm._v("Display Titles (excl. Anamnese)")]), _c('div', {
    staticClass: "wordCloud"
  }, [_c('vue-word-cloud', {
    attrs: {
      "words": _vm.questionnaireDisplayTitles,
      "animation-duration": 300
    }
  })], 1)]), _c('div', {
    staticClass: "flex d-flex column"
  }, [_c('h3', [_vm._v("Titles (excl. Anamnese)")]), _c('div', {
    staticClass: "wordCloud"
  }, [_c('vue-word-cloud', {
    attrs: {
      "words": _vm.questionnaireTitles,
      "animation-duration": 300
    }
  })], 1)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };