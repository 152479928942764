import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.licence ? _c('div', {
    staticClass: "wrap"
  }, [_c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Seriennummer")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "autofocus": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.licence.serialnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "serialnumber", $$v);
      },
      expression: "licence.serialnumber"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Praxis")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.licence.praxis,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "praxis", $$v);
      },
      expression: "licence.praxis"
    }
  })], 1)], 1), _c(VLayout, {
    attrs: {
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "mr-3": "",
      "xs3": ""
    }
  }, [_vm._v("Stripe-Sub")]), _c(VFlex, {
    attrs: {
      "xs8": ""
    }
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "required": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.licence.subscription,
      callback: function callback($$v) {
        _vm.$set(_vm.licence, "subscription", $$v);
      },
      expression: "licence.subscription"
    }
  })], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };