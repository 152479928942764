import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {},
      icon: null,
      color: null
    };
  },
  mounted: function mounted() {
    this.icon = this.params.context.icon;
    this.color = this.params.context.color;
  },
  methods: {
    deleteItem: function deleteItem() {
      this.params.context.clicked({
        row: this.params.data,
        column: this.params.value
      });
    }
  }
});