import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VContainer, {
    attrs: {
      "grid-list-md": "",
      "text-xs-center": "",
      "pa-0": "",
      "mt-1": ""
    }
  }, [_c(VLayout, {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.setSSHPortTo22();
      }
    }
  }, [_vm._v("SSH to 22")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.setSSHPortTo80();
      }
    }
  }, [_vm._v("SSH to 80")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.editSettings.apply(null, arguments);
      }
    }
  }, [_vm._v("Edit Settings")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.sendRestartAutosshCommand();
      }
    }
  }, [_vm._v("Restart autossh")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.sendUpdateBotCommand();
      }
    }
  }, [_vm._v("Update")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "x-small": "",
      "block": "",
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.sendRestartBotServiceCommand();
      }
    }
  }, [_vm._v("Restart service")])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "block": "",
      "actionText": "Reboot",
      "description": "Wirklich rebooten?"
    },
    on: {
      "confirmed": function confirmed($event) {
        return _vm.reboot();
      }
    }
  }, [_vm._v("Reboot")])], 1)], 1), _c('JsonEditorComponent', {
    ref: "jsonEditorComponent",
    attrs: {
      "value": _vm.clientSettings,
      "title": "Einstellungen bearbeiten"
    },
    on: {
      "saveJsonChange": _vm.saveJsonChangeAction
    }
  }), _c('ResponseDialogComponent', {
    attrs: {
      "data": _vm.responseDialogData,
      "rawResponse": _vm.rawResponse,
      "eventTrigger": _vm.eventTrigger,
      "status": _vm.responseDialogStatus,
      "path": _vm.path
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };