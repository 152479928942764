import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-iso-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.string.substr.js";
import { defineComponent } from 'vue';
import moment from 'moment';
function dateAndOptTimeToMoment(date, time) {
  if (time) {
    return moment("".concat(date, " ").concat(time), 'YYYY-MM-DD HH:mm');
  } else {
    return moment(date, 'YYYY-MM-DD');
  }
}
export default defineComponent({
  props: ['editEntry'],
  data: function data() {
    return {
      formValid: null,
      vonDateMenu: false,
      bisDateMenu: false,
      vonTimeMenu: false,
      bisTimeMenu: false,
      vonTime: null,
      bisTime: null,
      withEndTime: false,
      problemTypes: ['Datenbank', 'Server', 'Import'],
      selectedProblemType: null,
      vonDate: new Date().toISOString().substr(0, 10),
      bisDate: new Date().toISOString().substr(0, 10),
      problemText: null,
      solutionText: null,
      requiredRule: function requiredRule(v) {
        return (isNaN(v) ? !!v : true) || 'Field is required';
      }
    };
  },
  computed: {
    vonDateFormatted: function vonDateFormatted() {
      return this.vonDate ? moment(this.vonDate, moment.ISO_8601, true).format('DD.MM.YYYY') : '';
    },
    bisDateFormatted: function bisDateFormatted() {
      return this.bisDate ? moment(this.bisDate, moment.ISO_8601, true).format('DD.MM.YYYY') : '';
    }
  },
  mounted: function mounted() {
    // check for edit mode
    if (this.editEntry) {
      var von = moment(this.editEntry.start, moment.ISO_8601, true);
      this.vonDate = von.format('YYYY-MM-DD');
      this.vonTime = von.format('HH:mm');
      if (this.editEntry.end) {
        var bis = moment(this.editEntry.end, moment.ISO_8601, true);
        this.bisDate = bis.format('YYYY-MM-DD');
        this.bisTime = bis.format('HH:mm');
        this.withEndTime = true;
      }
      this.problemText = this.editEntry.message;
      this.solutionText = this.editEntry.solutionmessage;
      this.selectedProblemType = this.editEntry.type;
    }
  },
  methods: {
    submit: function submit() {
      var vonDateTime = dateAndOptTimeToMoment(this.vonDate, this.vonTime);
      var bisDateTime = null;
      if (this.withEndTime) {
        bisDateTime = dateAndOptTimeToMoment(this.bisDate, this.bisTime);
      }
      this.$emit('newEventCreated', {
        start: vonDateTime,
        end: bisDateTime,
        type: this.selectedProblemType,
        message: this.problemText,
        solutionmessage: this.solutionText,
        id: this.editEntry ? this.editEntry.id : undefined
      });
    }
  }
});