import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('MasterDetailComponent', {
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c(VBtnToggle, {
          staticClass: "filter-select",
          model: {
            value: _vm.filterSelect,
            callback: function callback($$v) {
              _vm.filterSelect = $$v;
            },
            expression: "filterSelect"
          }
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "value": "new"
          }
        }, [_vm._v("NEW")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "pending"
          }
        }, [_vm._v("PENDING")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "accepted"
          }
        }, [_vm._v("ACCEPTED")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "activated"
          }
        }, [_vm._v("ACTIVATED")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ignored"
          }
        }, [_vm._v("IGNORED")])], 1)], 1), _c(VCard, [_c('ag-grid-vue', {
          staticClass: "obgrid ag-theme-balham",
          attrs: {
            "rowData": _vm.filteredEntries,
            "columnDefs": _vm.columnDefs,
            "gridOptions": _vm.gridOptions,
            "getRowId": _vm.getRowId,
            "rowSelection": "single"
          },
          on: {
            "grid-ready": _vm.onGridReady,
            "row-selected": _vm.selectionChange
          }
        })], 1), _c('div', {
          staticClass: "subscription-count-section pt-1 d-flex justify-end"
        }, [_c('div', {
          staticClass: "span subscription-count"
        }, [_vm._v(_vm._s(_vm.filteredEntriesCount) + " / " + _vm._s(_vm.entriesCount))])])];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedEntries && _vm.selectedEntries.length > 1 ? _c('div', {
          staticClass: "detail-wrapper multi-select"
        }, [_c(VCard, {
          attrs: {
            "max-width": "740",
            "flat": ""
          }
        }, [_c(VCardTitle, {
          staticClass: "pl-4"
        }, [_c('span', [_vm._v("Multiple Aufträge bearbeiten")])]), _c(VCardSubtitle, [_c('span', [_vm._v(_vm._s(_vm.selectedEntries.length) + " Aufträge ausgewählt")])]), _c(VCardText, [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('label', {
          staticClass: "overline"
        }, [_vm._v("Prozess-Status")]), _c('div', {
          staticClass: "d-flex justify-space-between flex-grow-1"
        }, [_c(VBtnToggle, {
          attrs: {
            "small": ""
          },
          model: {
            value: _vm.multiEdit.prozessstatus,
            callback: function callback($$v) {
              _vm.$set(_vm.multiEdit, "prozessstatus", $$v);
            },
            expression: "multiEdit.prozessstatus"
          }
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "value": "unbearbeitet",
            "text": ""
          }
        }, [_vm._v("Unbearbeitet")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "versandt",
            "color": "blue",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Versandt")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "angekommen",
            "color": "orange darken-2",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Angekommen")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "verbindungok",
            "color": "green",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Verbindung OK")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ü-mail",
            "color": "purple",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Ü-Mail")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ü-termin",
            "color": "purple",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Ü-Termin")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ü-ok",
            "color": "purple",
            "dark": "",
            "text": ""
          }
        }, [_vm._v("Ü-Ok")])], 1), _c(VBtn, {
          attrs: {
            "fab": "",
            "x-small": "",
            "outlined": "",
            "disabled": !_vm.multiEdit.prozessstatus
          },
          on: {
            "click": function click($event) {
              _vm.multiEdit.prozessstatus = null;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-rotate-left")])], 1)], 1)]), _c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('label', {
          staticClass: "overline"
        }, [_vm._v("Status")]), _c('div', {
          staticClass: "d-flex justify-space-between flex-grow-1"
        }, [_c(VBtnToggle, {
          attrs: {
            "small": ""
          },
          model: {
            value: _vm.multiEdit.status,
            callback: function callback($$v) {
              _vm.$set(_vm.multiEdit, "status", $$v);
            },
            expression: "multiEdit.status"
          }
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "value": "NEW",
            "text": ""
          }
        }, [_vm._v("NEW")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "PENDING",
            "text": ""
          }
        }, [_vm._v("PENDING")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ACCEPTED",
            "text": ""
          }
        }, [_vm._v("ACCEPTED")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "ACTIVATED",
            "text": ""
          }
        }, [_vm._v("ACTIVATED")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "IGNORE",
            "text": ""
          }
        }, [_vm._v("IGNORE")])], 1), _c(VBtn, {
          attrs: {
            "fab": "",
            "x-small": "",
            "outlined": "",
            "disabled": !_vm.multiEdit.status
          },
          on: {
            "click": function click($event) {
              _vm.multiEdit.status = null;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-rotate-left")])], 1)], 1)]), _c('div', {
          staticClass: "d-flex justify-space-between flex-grow-1 align-center mt-6"
        }, [_c('div', {
          staticClass: "d-flex multi-abrechnung align-center"
        }, [_c(VTextField, {
          attrs: {
            "label": "Abrechnung ab",
            "persistent-hint": "",
            "hint": "Datum, ab dem die Abrechnung starten soll",
            "type": "date",
            "hide-details": !_vm.activationDate,
            "dense": "",
            "flat": ""
          },
          model: {
            value: _vm.multiEdit.abrechnungsstart,
            callback: function callback($$v) {
              _vm.$set(_vm.multiEdit, "abrechnungsstart", $$v);
            },
            expression: "multiEdit.abrechnungsstart"
          }
        }), _c(VBtn, {
          staticClass: "ml-4",
          attrs: {
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.multiEdit.abrechnungsstart = _vm.nextMonth;
            }
          }
        }, [_vm._v("Nächster Monat")])], 1), _c(VBtn, {
          attrs: {
            "fab": "",
            "x-small": "",
            "outlined": "",
            "disabled": !_vm.multiEdit.abrechnungsstart
          },
          on: {
            "click": function click($event) {
              _vm.multiEdit.abrechnungsstart = undefined;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-rotate-left")])], 1)], 1)]), _c(VCardActions, {
          staticClass: "pb-4 pl-4"
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.patchEntries
          }
        }, [_vm._v("Speichern")]), _c(VBtn, {
          attrs: {
            "small": "",
            "depressed": ""
          },
          on: {
            "click": _vm.deselectAll
          }
        }, [_vm._v("Auswahl aufheben")])], 1)], 1)], 1) : _vm.selectedEntry ? _c('div', {
          staticClass: "detail-wrapper single-select"
        }, [_c(VCard, {
          staticClass: "user-wrapper"
        }, [_c(VCardTitle, [_c('span', [_vm._v("Onboarding Auftrag")])]), _c(VCardText, [_vm._l(_vm.columns, function (group) {
          return _c('div', {
            key: group.key,
            staticClass: "groups"
          }, [_c('h3', {
            staticClass: "group"
          }, [_vm._v(_vm._s(group.label))]), _c('div', {
            staticClass: "fields"
          }, _vm._l(group.children, function (field) {
            return _c('div', {
              key: field.key,
              staticClass: "field-wrapper"
            }, [_c('div', {
              staticClass: "field"
            }, [_c('div', {
              staticClass: "field-label",
              "class": {
                wichtig: field.wichtig
              }
            }, [_vm._v(_vm._s(field.label))]), _c(VBtn, {
              directives: [{
                name: "clipboard",
                rawName: "v-clipboard",
                value: _vm.selectedEntry[field.key],
                expression: "selectedEntry[field.key]"
              }],
              attrs: {
                "fab": "",
                "x-small": "",
                "color": "info"
              }
            }, [_c(VIcon, {
              attrs: {
                "size": "18"
              }
            }, [_vm._v("mdi-content-copy")])], 1), field.type === "chooser" ? _c('div', {
              staticClass: "field-edit"
            }, [_c(VBtnToggle, {
              attrs: {
                "mandatory": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.patchEntry(_vm.selectedEntry.id, field.key, _vm.selectedEntry[field.key]);
                }
              },
              model: {
                value: _vm.selectedEntry[field.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.selectedEntry, field.key, $$v);
                },
                expression: "selectedEntry[field.key]"
              }
            }, _vm._l(field.choices, function (choice) {
              return _c(VBtn, {
                key: choice,
                attrs: {
                  "value": choice,
                  "small": ""
                }
              }, [_vm._v(_vm._s(choice))]);
            }), 1)], 1) : field.type === "date" ? _c('div', {
              staticClass: "field-edit"
            }, [_c(VTextField, {
              "class": {
                "wichtig-input": field.wichtig
              },
              attrs: {
                "type": "date",
                "persistent-hint": "",
                "hint": field.hint,
                "hide-details": !field.hint
              },
              on: {
                "blur": function blur($event) {
                  return _vm.patchEntry(_vm.selectedEntry.id, field.key, _vm.selectedEntry[field.key]);
                }
              },
              model: {
                value: _vm.selectedEntry[field.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.selectedEntry, field.key, $$v);
                },
                expression: "selectedEntry[field.key]"
              }
            })], 1) : field.type === "tags" ? _c('div', {
              staticClass: "field-edit"
            }, [_c(VCombobox, {
              attrs: {
                "clearable": "",
                "hide-selected": "",
                "chips": "",
                "multiple": "",
                "small-chips": "",
                "deletable-chips": "",
                "dense": "",
                "items": _vm.tags,
                "hide-details": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.patchEntry(_vm.selectedEntry.id, field.key, _vm.selectedEntry[field.key]);
                }
              },
              model: {
                value: _vm.selectedEntry[field.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.selectedEntry, field.key, $$v);
                },
                expression: "selectedEntry[field.key]"
              }
            })], 1) : field.type === "group" ? _c('div', {
              staticClass: "field-edit"
            }, [_c('GroupAutocomplete', {
              attrs: {
                "available-groups": _vm.availableGroups
              },
              on: {
                "change": function change($event) {
                  return _vm.patchEntry(_vm.selectedEntry.id, field.key, _vm.selectedEntry[field.key]);
                },
                "load-groups": _vm.loadGroups
              },
              model: {
                value: _vm.selectedEntry[field.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.selectedEntry, field.key, $$v);
                },
                expression: "selectedEntry[field.key]"
              }
            })], 1) : _c('div', {
              staticClass: "field-edit"
            }, [_c(VTextField, {
              "class": {
                "wichtig-input": field.wichtig
              },
              attrs: {
                "persistent-hint": "",
                "hint": field.hint,
                "hide-details": !field.hint
              },
              on: {
                "blur": function blur($event) {
                  return _vm.patchEntry(_vm.selectedEntry.id, field.key, _vm.selectedEntry[field.key]);
                }
              },
              model: {
                value: _vm.selectedEntry[field.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.selectedEntry, field.key, $$v);
                },
                expression: "selectedEntry[field.key]"
              }
            })], 1)], 1)]);
          }), 0)]);
        }), _c('h3', {
          staticClass: "group"
        }, [_vm._v("Kunde akzeptieren / aktivieren")]), _c(VCard, {
          staticClass: "mt-0",
          attrs: {
            "color": "grey lighten-4",
            "flat": ""
          }
        }, [_c(VCardText, [_c(VRow, [_c(VCol, {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VAutocomplete, {
          staticClass: "mr-2",
          attrs: {
            "label": "1. Kunde akzeptieren",
            "solo": "",
            "placeholder": "Bestehenden Kunden auswählen",
            "items": _vm.unacceptedProfiles,
            "item-text": "cid",
            "item-value": "cid",
            "clearable": "",
            "dense": "",
            "flat": "",
            "hide-details": "",
            "disabled": !!_vm.selectedEntry.cid
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "profile-selection"
              }, [_c('div', {
                staticClass: "profile-name"
              }, [_vm._v(_vm._s(data.item.praxisName))]), _c('div', {
                staticClass: "profile-id"
              }, [_vm._v(_vm._s(data.item.cid))])])];
            }
          }]),
          model: {
            value: _vm.linkedClientId,
            callback: function callback($$v) {
              _vm.linkedClientId = $$v;
            },
            expression: "linkedClientId"
          }
        }), _c(VBtn, {
          staticClass: "mr-2",
          attrs: {
            "disabled": !_vm.linkedClientId || !!_vm.selectedEntry.cid,
            "dense": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.acceptEntry(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Kunde akzeptieren")])], 1)]), _c(VCol, {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VMenu, {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            "transition": "scale-transition",
            "offset-y": "",
            "min-width": "auto"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c(VTextField, _vm._g(_vm._b({
                attrs: {
                  "solo": "",
                  "hide-details": "",
                  "label": "2. Kunde aktivieren",
                  "prepend-icon": "mdi-calendar",
                  "readonly": "",
                  "disabled": !_vm.linkedClientId || _vm.selectedEntry.status !== "ACCEPTED",
                  "dense": "",
                  "flat": ""
                },
                model: {
                  value: _vm.activationDate,
                  callback: function callback($$v) {
                    _vm.activationDate = $$v;
                  },
                  expression: "activationDate"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.menu,
            callback: function callback($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c(VDatePicker, {
          on: {
            "input": function input($event) {
              _vm.menu = false;
            }
          },
          model: {
            value: _vm.activationDate,
            callback: function callback($$v) {
              _vm.activationDate = $$v;
            },
            expression: "activationDate"
          }
        })], 1), _c(VBtn, {
          staticClass: "ml-2",
          attrs: {
            "disabled": !_vm.linkedClientId || _vm.selectedEntry.status !== "ACCEPTED",
            "dense": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.activateEntry(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Kunde aktivieren")])], 1)])], 1)], 1)], 1), _c('h3', {
          staticClass: "mt-5"
        }, [_vm._v("Weitere Einstellungen")]), _c(VLayout, {
          attrs: {
            "align-center": "",
            "wrap": ""
          }
        }, [_vm.addSuffix ? _c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("Prefix")]) : _c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("cid")]), _c(VFlex, {
          attrs: {
            "xs9": "",
            "md3": ""
          }
        }, [_c(VLayout, {
          attrs: {
            "align-center": "",
            "wrap": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "xs9": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "persistent-hint": "",
            "hint": "Hier ggf. cid eines bestehenden Kunden eintragen (dann auch Haken setzen bei Bestehender Kunde)",
            "disabled": !!_vm.selectedEntry.cid
          },
          model: {
            value: _vm.clientIdPrefix,
            callback: function callback($$v) {
              _vm.clientIdPrefix = $$v;
            },
            expression: "clientIdPrefix"
          }
        })], 1), _c(VFlex, {
          attrs: {
            "xs3": ""
          }
        }, [_c(VSwitch, {
          staticClass: "ml-2",
          attrs: {
            "disabled": !!_vm.selectedEntry.cid,
            "label": "Add Suffix"
          },
          model: {
            value: _vm.addSuffix,
            callback: function callback($$v) {
              _vm.addSuffix = $$v;
            },
            expression: "addSuffix"
          }
        })], 1)], 1)], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": "",
            "wrap": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("Bestehender Kunde")]), _c(VFlex, {
          attrs: {
            "xs9": "",
            "md3": ""
          }
        }, [_c(VSwitch, {
          attrs: {
            "disabled": !!_vm.selectedEntry.cid
          },
          model: {
            value: _vm.existingCustomer,
            callback: function callback($$v) {
              _vm.existingCustomer = $$v;
            },
            expression: "existingCustomer"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "align-center": "",
            "wrap": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("Stripe-Kunden von Kontoinhaber-E-Mail übernehmen")]), _c(VFlex, {
          attrs: {
            "xs9": "",
            "md3": ""
          }
        }, [_c(VSwitch, {
          attrs: {
            "disabled": _vm.selectedEntry.stripe
          },
          model: {
            value: _vm.reuseExistingStripeCustomer,
            callback: function callback($$v) {
              _vm.reuseExistingStripeCustomer = $$v;
            },
            expression: "reuseExistingStripeCustomer"
          }
        })], 1), _vm.selectedEntry.stripe ? _c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("Account existiert: " + _vm._s(_vm.selectedEntry.stripe.id) + " aus " + _vm._s(_vm.selectedEntry.stripe.match))]) : _vm._e()], 1), _c(VLayout, {
          attrs: {
            "align-center": "",
            "wrap": ""
          }
        }, [_c(VFlex, {
          attrs: {
            "mr-3": "",
            "xs3": "",
            "md2": ""
          }
        }, [_vm._v("DB Host überschreiben")]), _c(VFlex, {
          attrs: {
            "xs9": "",
            "md3": ""
          }
        }, [_c(VTextField, {
          attrs: {
            "placeholder": "default",
            "disabled": _vm.editing || _vm.selectedEntry.status !== "NEW"
          },
          model: {
            value: _vm.dbHost,
            callback: function callback($$v) {
              _vm.dbHost = $$v;
            },
            expression: "dbHost"
          }
        })], 1)], 1), _c(VLayout, {
          attrs: {
            "wrap": ""
          }
        }, [_c('rose-confirm-button', {
          staticClass: "ma-1",
          on: {
            "confirmed": function confirmed($event) {
              return _vm.deleteEntry(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Löschen?")]), _c('div', {
          staticClass: "spacer"
        }), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": _vm.editing || _vm.selectedEntry.status !== "NEW"
          },
          on: {
            "click": function click($event) {
              return _vm.acceptEntry(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Kunde akzeptieren")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "warning",
            "disabled": _vm.editing || _vm.selectedEntry.status !== "ACCEPTED" || _vm.selectedEntry.stripe
          },
          on: {
            "click": function click($event) {
              return _vm.createStripeAccount(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Stripe-Account anlegen")]), _c(VBtn, {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "error",
            "disabled": _vm.editing || _vm.selectedEntry.status !== "ACCEPTED"
          },
          on: {
            "click": function click($event) {
              return _vm.activateEntry(_vm.selectedEntry);
            }
          }
        }, [_vm._v("Kunde aktivieren")])], 1)], 2)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };