import { isDev } from './config';

const ETAG_HEADER_NAME = 'etag';

export interface IEtagServiceCheckInfo {
  outofdate: boolean;
  oldetag?: string | null;
  newetag?: string | null;
}

/**
 * Usage:
 * let service = new ETagService();
 * service.startCheckForNewUiVersionInterval(60, () => { ... });
 */
export class ETagService {
  private intervalId = 0;
  private startupEtag: string | null = '';

  private async init() {
    this.startupEtag = await this.getCurrentRootEtag();
  }

  public async getCurrentRootEtag() {
    const response = await fetch('/versions.json');
    const versions = await response.json();
    return versions.build;
    // old version: use actual etag
    // let etag = response.headers.get(ETAG_HEADER_NAME) || '';
    // // strip compression/ssl information
    // // following are all possible and the same
    // // c05e8354a5c5cefe79a5270361b00004-ssl-df
    // // c05e8354a5c5cefe79a5270361b00004-ssl
    // // c05e8354a5c5cefe79a5270361b00004
    // etag = etag.split('-')[0];
    // return etag;
  }

  public async startCheckForNewUiVersionInterval(
    checkIntervalInSec = 60,
    changedCallBack?: (info: IEtagServiceCheckInfo) => void,
  ) {
    try {
      await this.init();
    } catch (e: any) {
      console.warn('%c etagService etag not available ', 'background: darkorange; color: white', e.message);
      throw e;
    }

    if (isDev) {
      console.info('Not checking etag in dev.');
      return;
    }
    if (checkIntervalInSec > 0) {
      this.intervalId = window.setInterval(async () => {
        const outOfDate = await this.checkIfOutOfDate();
        if (outOfDate.outofdate) {
          console.warn('%c etag has changed ', 'background: darkorange; color: white', outOfDate);

          clearInterval(this.intervalId);
          if (changedCallBack) {
            changedCallBack(outOfDate);
          }
        }
      }, checkIntervalInSec * 1000);
    }
  }

  /**
   * this method fetches the current etag and compares it with the etag retrieved from the app startup
   * only returns true if a new etag can be confirmed
   */
  public async checkIfOutOfDate(): Promise<IEtagServiceCheckInfo> {
    // return { outofdate: true };
    const startupEtag = this.startupEtag;
    if (!startupEtag) {
      return { outofdate: false, oldetag: startupEtag, newetag: null };
    }

    try {
      const current = await this.getCurrentRootEtag();
      if (current !== startupEtag) {
        return { outofdate: true, oldetag: startupEtag, newetag: current };
      } else {
        return { outofdate: false, oldetag: startupEtag, newetag: current };
      }
    } catch (e) {
      return { outofdate: false, oldetag: startupEtag, newetag: null };
    }
  }
}
