import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.isSolutioTeam ? _c(VCard, {
    staticClass: "helpdesk-infos",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "d-flex align-baseline"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("Doctolib")]), _c(VSwitch, {
    staticClass: "ml-2",
    attrs: {
      "label": _vm.doctolibActiveLabel,
      "color": "green",
      "mandatory": "",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.doctolibActive,
      callback: function callback($$v) {
        _vm.doctolibActive = $$v;
      },
      expression: "doctolibActive"
    }
  })], 1), _c(VCardText, [_c(VOverlay, {
    attrs: {
      "value": _vm.isLoading,
      "absolute": ""
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1), _vm.hasError ? _c('div', {
    staticClass: "fetch-error"
  }, [_vm.hasError ? _c(VAlert, {
    staticClass: "mb-0",
    attrs: {
      "prominent": "",
      "text": "",
      "type": "error",
      "icon": "fas fa-exclamation-triangle",
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Fehler beim Laden der Daten")]), _vm.errorText ? _c('span', {
    staticClass: "pl-1"
  }, [_vm._v(_vm._s(_vm.errorText))]) : _vm._e()]) : _vm._e()], 1) : _c('div', {
    staticClass: "doctosync-data"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between mb-4"
  }, [_c('div', {
    staticClass: "last-sync d-flex flex-column"
  }, [_c('span', {
    staticClass: "caption text-no-wrap"
  }, [_vm._v("Letzte Synchronisation")]), _vm.meta.lastsync ? _c(VChip, {
    attrs: {
      "small": "",
      "label": ""
    }
  }, [_vm._v(_vm._s(_vm._f("tz")(_vm.meta.lastsync, 'DD.MM.YYYY HH:mm:ss')))]) : _vm._e()], 1), _c('div', {
    staticClass: "last-id d-flex flex-column"
  }, [_c('span', {
    staticClass: "caption text-no-wrap"
  }, [_vm._v("Letzte ID")]), _vm.meta.lastid ? _c(VChip, {
    attrs: {
      "small": "",
      "label": ""
    }
  }, [_vm._v(_vm._s(_vm.meta.lastid))]) : _vm._e()], 1), _vm.meta.error ? _c('div', {
    staticClass: "error-col d-flex flex-column"
  }, [_c('span', {
    staticClass: "caption text-no-wrap"
  }, [_vm._v("Fehler")]), _vm.meta.errormsg ? _c(VChip, {
    attrs: {
      "small": "",
      "label": "",
      "color": "error"
    }
  }, [_vm._v(_vm._s(_vm.meta.errormsg))]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c(VTextField, {
    staticClass: "mr-4",
    attrs: {
      "label": "Nutzerkennung",
      "disabled": !_vm.doctolibActive
    },
    model: {
      value: _vm.doctolibKey,
      callback: function callback($$v) {
        _vm.doctolibKey = $$v;
      },
      expression: "doctolibKey"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Secret Key",
      "disabled": !_vm.doctolibActive
    },
    model: {
      value: _vm.doctolibSecretKey,
      callback: function callback($$v) {
        _vm.doctolibSecretKey = $$v;
      },
      expression: "doctolibSecretKey"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c(VBtn, {
    staticClass: "flex-grow-1",
    attrs: {
      "disabled": _vm.isLoading,
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Speichern")]), _c('div', {
    staticClass: "d-flex flex-column flex-grow-1 mx-4"
  }, [_c(VBtn, {
    attrs: {
      "disabled": _vm.isLoading,
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.reset(false);
      }
    }
  }, [_vm._v("Reset (soft)")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v("Resetet die Sync-ID auf -1 und löscht die Fehlermeldung")])], 1), _c('div', {
    staticClass: "d-flex flex-column flex-grow-1"
  }, [_c(VBtn, {
    staticClass: "flex-grow-1",
    attrs: {
      "disabled": _vm.isLoading,
      "color": "error",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.reset(true);
      }
    }
  }, [_vm._v("Reset (hard)")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v("Löscht alle Daten und setzt die Sync-ID auf -1")])], 1)], 1)])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };