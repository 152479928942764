import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "target",
    staticClass: "activity-charts",
    on: {
      "click": _vm.log
    }
  }, [_c('div', {
    staticClass: "toggle"
  }, [_c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": ""
    },
    model: {
      value: _vm.chartDataType,
      callback: function callback($$v) {
        _vm.chartDataType = $$v;
      },
      expression: "chartDataType"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": "metrics",
      "x-small": "",
      "disabled": !_vm.hasMetrics
    }
  }, [_vm._v("metrics")]), _c(VBtn, {
    attrs: {
      "value": "r4c",
      "x-small": "",
      "disabled": !_vm.hasR4c
    }
  }, [_vm._v("r4c")])], 1)], 1), _vm.chartDataType === "metrics" ? _c('div', {
    staticClass: "metrics"
  }, [_vm.targetIsVisible && _vm.hasMetricsChartData ? _c('div', {
    staticClass: "charts"
  }, [_c('highcharts', {
    staticClass: "month-chart",
    attrs: {
      "options": _vm.metricsMonthChartOptionsNew
    }
  }), _c('highcharts', {
    staticClass: "year-chart",
    attrs: {
      "options": _vm.metricsYearChartOptionsNew
    }
  })], 1) : _vm.targetIsVisible && !_vm.hasMetricsChartData ? _c('div', {
    staticClass: "chart-placeholder no-data"
  }, [_c('span', [_vm._v("Keine Daten")])]) : _c('div', {
    staticClass: "chart-placeholder load-chart"
  }, [_c(VIcon, {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("fa-chart-simple")])], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };