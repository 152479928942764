import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap fill-height"
  }, [_c('div', {
    staticClass: "status-cards"
  }, [_c('span', [_vm._v("Last update: " + _vm._s(_vm.lastUpdate))]), _c('div', {
    staticClass: "grid-layout mb-2"
  }, [_c('StatusCard', {
    attrs: {
      "name": "metrics",
      "status": _vm.metricsStatus,
      "restartAction": _vm.restartMetrics,
      "cleanCacheAction": _vm.cleanCacheMetrics
    }
  }), _c('StatusCard', {
    attrs: {
      "name": "collector",
      "status": _vm.collectorStatus,
      "restartAction": _vm.restartCollector,
      "cleanCacheAction": _vm.cleanCacheCollector
    }
  }), _c('StatusCard', {
    attrs: {
      "name": "saver",
      "status": _vm.saverStatus
    }
  }), _vm._l(_vm.r4csStatus, function (r4cStatus) {
    return _c('StatusCard', {
      key: r4cStatus.host,
      attrs: {
        "name": r4cStatus.name,
        "host": r4cStatus.host,
        "status": r4cStatus,
        "restartAction": _vm.restartR4c,
        "cleanCacheAction": _vm.cleanCacheR4c
      }
    });
  }), _c(VCard, [_c(VCardTitle, {
    staticClass: "status-info"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("SSH")]), _c('div', {
    staticClass: "statusIcons"
  }, [_c(VIcon, {
    staticClass: "icon",
    attrs: {
      "x-large": "",
      "color": "red"
    }
  }, [_vm._v("warning")])], 1)])], 1)], 2)]), _vm._l(_vm.r4csFactoringBackendStatus, function (r4cStatus) {
    return _c(VExpansionPanels, {
      key: r4cStatus.host,
      staticClass: "mb-2",
      attrs: {
        "focusable": ""
      }
    }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_vm._v("Factoring-Backends: " + _vm._s(r4cStatus.host))]), _c(VExpansionPanelContent, {
      staticClass: "factoring-status"
    }, [r4cStatus.factoringBackendStatus ? _c('div', {
      staticClass: "btn-list"
    }, [_vm._l(r4cStatus.factoringBackendStatus, function (value, name) {
      return _c('div', {
        staticClass: "btn-item"
      }, [_c(VSwitch, {
        on: {
          "change": function change($event) {
            return _vm.switchOnchange(r4cStatus.host, name, value.enabled);
          }
        },
        model: {
          value: value.enabled,
          callback: function callback($$v) {
            _vm.$set(value, "enabled", $$v);
          },
          expression: "value.enabled"
        }
      }), _c('span', [_vm._v(_vm._s(name))])], 1);
    }), _c('div', {
      staticClass: "btn-item prefetcher"
    }, [_c(VSwitch, {
      on: {
        "change": function change($event) {
          return _vm.switchPrefetcher(r4cStatus.host, r4cStatus.prefetcherEnabled);
        }
      },
      model: {
        value: r4cStatus.prefetcherEnabled,
        callback: function callback($$v) {
          _vm.$set(r4cStatus, "prefetcherEnabled", $$v);
        },
        expression: "r4cStatus.prefetcherEnabled"
      }
    }), _c('span', [_vm._v("prefetcher")])], 1)], 2) : _vm._e()]), !r4cStatus.factoringBackendStatus ? _c(VIcon, {
      staticClass: "icon",
      attrs: {
        "x-large": "",
        "color": "red"
      }
    }, [_vm._v("warning")]) : _vm._e()], 1)], 1);
  }), _c('div', {
    staticClass: "r4c-stats"
  }, [_c(VExpansionPanels, {
    attrs: {
      "focusable": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_vm._v("r4c-Stats")]), _c(VExpansionPanelContent, [_c('div', {
    staticClass: "r4c-stats-filter-bar"
  }, [_c(VSwitch, {
    attrs: {
      "label": "Nur letzten vollen Monat anzeigen"
    },
    on: {
      "change": function change($event) {
        return _vm.switchR4cStatsMonthView();
      }
    },
    model: {
      value: _vm.r4cStatsShowOnlyLastMonth,
      callback: function callback($$v) {
        _vm.r4cStatsShowOnlyLastMonth = $$v;
      },
      expression: "r4cStatsShowOnlyLastMonth"
    }
  }), _c(VTextField, {
    staticClass: "search",
    attrs: {
      "type": "text",
      "placeholder": "suchen",
      "clearable": "",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.filterR4cStats();
      }
    },
    model: {
      value: _vm.searchR4cStatsInput,
      callback: function callback($$v) {
        _vm.searchR4cStatsInput = $$v;
      },
      expression: "searchR4cStatsInput"
    }
  })], 1), _c('rose-master-table', {
    ref: "table",
    attrs: {
      "data": _vm.r4cClickStats,
      "idKey": "cid",
      "default-sort": {
        prop: "cid",
        order: "ascending"
      },
      "height": "600"
    },
    scopedSlots: _vm._u([{
      key: "columns",
      fn: function fn() {
        return [_c('el-table-column', {
          attrs: {
            "label": "Kunde",
            "property": "cid",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.cid
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Monat",
            "property": "monat",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.monat
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#clicks",
            "property": "clicks",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.clicks,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#clicks-Diff",
            "property": "clicksDiff",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.clicksDiff,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#ewe",
            "property": "ewecount",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.ewecount,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#ewe-Diff",
            "property": "ewecountDiff",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.ewecountDiff,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Neueste EWE",
            "property": "lastewe",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.lastewe,
                  "formatter": _vm.formatDateTime
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#ava",
            "property": "avacount",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.avacount,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#ava-Diff",
            "property": "avacountDiff",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.avacountDiff,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#docs",
            "property": "signeddocumentcount",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.signeddocumentcount,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "#docs-Diff",
            "property": "signeddocumentcountDiff",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.signeddocumentcountDiff,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "factoring-stats"
  }, [_c(VExpansionPanels, {
    attrs: {
      "focusable": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_vm._v("Factoring-Stats")]), _c(VExpansionPanelContent, [_c('rose-master-table', {
    ref: "table",
    attrs: {
      "data": _vm.eweStats,
      "idKey": "cid",
      "default-sort": {
        prop: "cid",
        order: "ascending"
      },
      "height": "300"
    },
    scopedSlots: _vm._u([{
      key: "columns",
      fn: function fn() {
        return [_c('el-table-column', {
          attrs: {
            "label": "Kunde",
            "property": "cid",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.cid
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Formular",
            "property": "formular",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.formular
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Anzahl",
            "property": "anzahl",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.anzahl,
                  "formatter": _vm.formatNumeral
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Letzte EWE",
            "property": "latest",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.latest,
                  "formatter": _vm.formatDateTime
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "status-container"
  }, [_c('div', {
    staticClass: "status-new"
  }, [_c(VExpansionPanels, {
    attrs: {
      "focusable": ""
    },
    model: {
      value: _vm.createNewStatusShown,
      callback: function callback($$v) {
        _vm.createNewStatusShown = $$v;
      },
      expression: "createNewStatusShown"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_vm._v("Neues Status-Ereignis erstellen")]), _c(VExpansionPanelContent, [_c('NewStatusComponent', {
    on: {
      "newEventCreated": function newEventCreated($event) {
        return _vm.newEventCreated($event);
      }
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "status-table"
  }, [_c(VCard, [_c('rose-master-table', {
    ref: "table",
    attrs: {
      "data": _vm.events,
      "idKey": "id",
      "default-sort": {
        prop: "createdAt",
        order: "ascending"
      },
      "height": "300"
    },
    on: {
      "selectionChange": _vm.selectionChange
    },
    scopedSlots: _vm._u([{
      key: "columns",
      fn: function fn() {
        return [_c('el-table-column', {
          attrs: {
            "label": "Aktiv",
            "property": "active",
            "width": "50px"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [scope.row.active ? _c(VIcon, {
                staticClass: "icon",
                attrs: {
                  "color": "red"
                }
              }, [_vm._v("warning")]) : _vm._e()];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Message",
            "min-width": "120px",
            "property": "message",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.message
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Von",
            "sortable": "",
            "property": "start"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.start,
                  "formatter": _vm.formatDateTime
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Bis",
            "sortable": "",
            "property": "end"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.end,
                  "formatter": _vm.formatDateTime
                }
              })];
            }
          }])
        }), _c('el-table-column', {
          attrs: {
            "label": "Typ",
            "property": "type",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c('CopyText', {
                attrs: {
                  "value": scope.row.type
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "status-actions"
  }, [_c(VBtn, {
    staticClass: "loschen-btn",
    attrs: {
      "small": "",
      "color": "error",
      "disabled": !_vm.selectedTableEntry
    },
    on: {
      "click": _vm.deleteSelectedEntry
    }
  }, [_vm._v("Löschen")]), _c(VBtn, {
    staticClass: "loschen-btn",
    attrs: {
      "small": "",
      "color": "primary",
      "disabled": !_vm.selectedTableEntry
    },
    on: {
      "click": _vm.editSelectedEntry
    }
  }, [_vm._v("Bearbeiten")]), _c(VBtn, {
    attrs: {
      "small": "",
      "color": "primary",
      "disabled": !_vm.selectedTableEntry
    },
    on: {
      "click": _vm.resolveSelectedEntry
    }
  }, [_vm._v("Erledigt")])], 1), _vm.editEntry ? _c('div', {
    staticClass: "edit-entry mt-4"
  }, [_c('h3', [_vm._v("Bearbeiten")]), _c('NewStatusComponent', {
    attrs: {
      "editEntry": _vm.editEntry
    },
    on: {
      "newEventCreated": function newEventCreated($event) {
        return _vm.editEntrySubmit($event);
      }
    }
  })], 1) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };